import { Box, CircularProgress, MenuItem, Select } from '@mui/material';
import { Table } from 'components/Table';
import { gridClasses } from '@mui/x-data-grid-pro';
import { useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetOrganizationsMembershipsAdminQuery } from 'store/api/admin.api';
import { OrganizationRole } from 'types/organizations';
import { getAdminOrganizationMembersColumns } from 'utils/admin-organization-members-columns';
import { routes } from 'nav';
import {
  useDeleteMembershipMutation,
  useGetMembershipRolesQuery,
  useUpdateMebershipMutation,
} from 'store/api/organizations.api';
import { useConfirmation } from 'hooks/use-confirmation';
import { AddMembershipAdmin } from '../components/AddMembershipAdmin';
import { useUrlPagination } from 'hooks/use-url-pagination';

export const Members = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();

  const confirm = useConfirmation();

  const { pageSize, page, setPage, setPageSize, availablePageSizes } =
    useUrlPagination({
      availablePageSizes: [4, 10, 25, 50],
    });

  const [profileType, setProfileType] = useState<
    'user' | 'patient_profile' | 'all'
  >('all');

  const { data, isLoading } = useGetOrganizationsMembershipsAdminQuery({
    id: Number(id),
    type: profileType === 'all' ? undefined : profileType,
    limit: pageSize,
    offset: page * pageSize,
  });

  const { isLoading: areRolesLoading, data: roles } =
    useGetMembershipRolesQuery({
      organizationId: Number(id),
    });

  const [update, { isLoading: isUpdating }] = useUpdateMebershipMutation();
  const [deleteMembership, { isLoading: isDeleting }] =
    useDeleteMembershipMutation();

  const onRoleChange = (role: string, membershipId: number) => {
    update({ organizationId: Number(id), membershipId, role });
  };

  const onProfileClick = (id: number) => {
    navigate(routes.admin.lookupResults.href(id));
  };

  const onDeleteClick = (membershipId: number, name: string) => {
    confirm(
      {
        variant: 'danger',
        title: 'Delete Membership',
        message: `Are you sure you want to delete ${name}'s membership?`,
      },
      () => {
        deleteMembership({ organizationId: Number(id), membershipId });
      },
    );
  };

  const columns = useMemo(() => {
    return getAdminOrganizationMembersColumns(
      onRoleChange,
      onProfileClick,
      onDeleteClick,
      roles?.results,
    );
  }, [roles]);

  if (isLoading) {
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 4,
      }}
    >
      <CircularProgress size={64} />
    </Box>;
  }

  return (
    <Box px={2} pt={2} display="flex" flexDirection="column" gap={2}>
      <Box display="flex" justifyContent="space-between" alignItems="end">
        <Box display="flex" flexDirection="column" gap={1}>
          <label>Profile Type</label>
          <Select
            sx={{ maxWidth: 200 }}
            value={profileType}
            onChange={(e) =>
              setProfileType(
                e.target.value as 'user' | 'patient_profile' | 'all',
              )
            }
          >
            <MenuItem value="all">All</MenuItem>
            <MenuItem value="user">User</MenuItem>
            <MenuItem value="patient_profile">Patient Profile</MenuItem>
          </Select>
        </Box>
        <AddMembershipAdmin organizationId={Number(id)} />
      </Box>
      <Table
        sx={{
          [`.${gridClasses.cell}`]: {
            py: 0,
          },
          [`.${gridClasses.actionsCell}`]: {
            p: 0,
            height: '100%',
          },
        }}
        rowHeight={52}
        pagination
        columns={columns}
        rows={data?.results ?? []}
        rowCount={data?.totalRows ?? 0}
        paginationMode="server"
        onPaginationModelChange={({ page, pageSize }) => {
          setPageSize(pageSize);
          setPage(page + 1);
        }}
        paginationModel={{
          pageSize,
          page: page - 1,
        }}
        pageSizeOptions={availablePageSizes}
        loading={areRolesLoading || isUpdating || isDeleting}
      />
    </Box>
  );
};
