import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useContext, useState } from 'react';
import { FieldErrorsImpl, SubmitErrorHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAppDispatch, useAppSelector } from 'store';
import {
  useGetAlerterInfoQuery,
  useUpdateAlerterMutation,
  useUpdateAlerterChannelSetMutation,
  useUpdateAlerterTriggerMutation,
  useUpdateRuleInAlerterTriggerMutation,
} from 'store/api/alerters.api';
import {
  setSettingsAsModified,
  setSettingsAsUntouched,
  updateSettingsState,
} from 'store/reducers/alerter/alerter.slice';
import { AlerterEventsWidgets, ChannelSet, CooldownType } from 'types/alerters';
import * as yup from 'yup';
import { SettingsContext } from '../AlerterDetails';
import { transformChannelSet } from 'utils/data-transformation';
import { useNavigationBlocker } from 'hooks/use-navigation-blocker';
import { fileToBase64 } from 'utils/file-to-base64';
import { unsupressedTriggersIdsSelector } from 'store/selectors';
import { isFileTypeImage } from 'utils/is-file-type-image';
import { caregiverRole } from 'services/caregiver-role';

export interface SettingsForm {
  title: string;
  customMessage?: string;
  messageImage?: File;
  messageImageUrl?: string;
  channels: ChannelSet[];
  messageType: 'custom' | 'generated';
  targetWidget: AlerterEventsWidgets | '';
  targetWidgetType: 'default' | 'custom';
  defaultTargetWidget?: AlerterEventsWidgets;
  cooldownType: CooldownType;
  cooldownTime: number | null;
  unsuppressedRules: number[];
  requiresAcknowledgement: boolean;
  forcedRequiresAcknowledgement: boolean;
}

const getErrorBody = (condition: boolean, message: string) => {
  if (condition === true) {
    return {
      type: 'required',
      message,
    };
  }

  return undefined;
};

const schema = yup.object({
  title: yup.string().required('Title is required.'),
  customMessage: yup.string(),
  channels: yup
    .array()
    .typeError('At least one channel must be selected.')
    .min(1, 'At least one channel must be selected.'),
  messageImage: yup
    .mixed()
    .optional()
    .test(
      'is-valid-type',
      'Not a valid image type',
      (value) =>
        typeof value === 'undefined' ||
        (!!value &&
          isFileTypeImage(
            value && (value as File).name.toLowerCase(),
            'image',
          )),
    ),
  unsuppressedRules: yup
    .array()
    .of(yup.number())
    .min(1, 'At least one rule must be selected.'),
});

export const useSettings = () => {
  const [errors, setErrors] = useState<FieldErrorsImpl<SettingsForm>>({});

  const dispatch = useAppDispatch();
  const { alertId } = useParams();
  const metricRules = useAppSelector((state) => state.alerter.trigger.rules);
  const defaultUnsupressedRules = useAppSelector(
    unsupressedTriggersIdsSelector,
  );

  const { data: alertInfo } = useGetAlerterInfoQuery(Number(alertId));
  const { setSubmit } = useContext(SettingsContext);

  const defaultSettings = useAppSelector((state) => state.alerter.settings);
  const triggerId = useAppSelector((state) => state.alerter.trigger.id);
  const { isLoading, isFetching } = useGetAlerterInfoQuery(Number(alertId));
  const [update] = useUpdateAlerterMutation();
  const [updateTrigger] = useUpdateAlerterTriggerMutation();
  const [updateChannels] = useUpdateAlerterChannelSetMutation();
  const [updateMetricRule] = useUpdateRuleInAlerterTriggerMutation();

  const methods = useForm<SettingsForm>({
    resolver: yupResolver(schema),
    defaultValues: {
      channels: defaultSettings.channels,
      customMessage: defaultSettings.customMessage ?? '',
      messageType: defaultSettings.hasCustomMessage ? 'custom' : 'generated',
      title: defaultSettings.title ?? '',
      defaultTargetWidget: defaultSettings.defaultTargetWidget,
      targetWidget: defaultSettings.targetWidget,
      targetWidgetType: defaultSettings.hasCustomTargetWidget
        ? 'custom'
        : 'default',
      cooldownTime: defaultSettings.cooldownTime,
      cooldownType: defaultSettings.cooldownType ?? 'none',
      messageImageUrl: defaultSettings.messageImageUrl || '',
      requiresAcknowledgement: defaultSettings.requiresAcknowledgement,
    },
    mode: 'onChange',
  });

  const discardChanges = () => {
    dispatch(setSettingsAsUntouched());
    methods.reset({
      channels: defaultSettings.channels,
      messageType: defaultSettings.hasCustomMessage ? 'custom' : 'generated',
      customMessage: defaultSettings.customMessage ?? '',
      title: defaultSettings.title ?? '',
      targetWidget: defaultSettings.targetWidget,
      targetWidgetType: defaultSettings.hasCustomTargetWidget
        ? 'custom'
        : 'default',
      cooldownTime: defaultSettings.cooldownTime,
      cooldownType: defaultSettings.cooldownType ?? 'none',
      requiresAcknowledgement: defaultSettings.requiresAcknowledgement,
      forcedRequiresAcknowledgement:
        defaultSettings.forcedRequiresAcknowledgement,
      messageImageUrl: defaultSettings.messageImageUrl || '',
      messageImage: undefined,
    });
  };

  useNavigationBlocker(methods.formState.isDirty, {
    title: 'You have unsaved changes.',
    message: 'Do you want to discard them?',
    onDiscard: discardChanges,
  });

  const onSubmit = async (data: SettingsForm) => {
    if (
      data.messageType === 'custom' &&
      (!data.customMessage || data.customMessage.length === 0)
    ) {
      onError({
        customMessage: {
          type: 'required',
          message: 'Notification message is required.',
        },
      });
      return;
    }

    if (
      data.targetWidget === '' &&
      alertInfo &&
      alertInfo.result.available_widget_targets.length > 0
    ) {
      methods.setError('targetWidget', {
        type: 'required',
        message: 'Target widget is required.',
      });
      return;
    }

    const base64Image = await fileToBase64(data.messageImage);

    onError({});

    update({
      title: data.title,
      customMessage: data.customMessage,
      hasCustomMessage: data.messageType === 'custom',
      customWidgetTargets:
        data.targetWidgetType === 'custom'
          ? [data.targetWidget as AlerterEventsWidgets]
          : null,
      id: Number(alertId),
      messageImage:
        defaultSettings.messageImageUrl === data.messageImageUrl
          ? undefined
          : base64Image,
      requiresAcknowledgement: data.requiresAcknowledgement,
      messageImageUrl:
        defaultSettings.messageImageUrl === data.messageImageUrl
          ? undefined
          : data.messageImageUrl || null,
    })
      .unwrap()
      .then(({ result }) => {
        if (data) {
          toast('Alert settings were successfully updated.', {
            type: 'success',
          });
          dispatch(setSettingsAsUntouched());
          dispatch(
            updateSettingsState({
              channels: transformChannelSet(
                result.alerter_channel_set.channels,
              ),
              customMessage: result.custom_message_template || '',
              messageType: result.use_custom_message_template
                ? 'custom'
                : 'generated',
              title: result.title,
              targetWidget: result.custom_widget_targets
                ? result.custom_widget_targets[0]
                : (result.generated_widget_targets?.[0] ?? ''),
              targetWidgetType: result.custom_widget_targets
                ? 'custom'
                : 'default',
              cooldownTime: data.cooldownTime,
              cooldownType: data.cooldownType,
              unsuppressedRules: [],
              requiresAcknowledgement: result.requires_acknowledgement,
              forcedRequiresAcknowledgement:
                result.forced_requires_acknowledgement,
            }),
          );
        }
      })
      .catch(() => {
        toast('An error occured.', { type: 'error' });
      });

    if (
      defaultSettings.channelSetId &&
      data.channels.sort().join(',') !==
        [...defaultSettings.channels].sort().join(',')
    ) {
      updateChannels({
        channels: data.channels,
        id: defaultSettings.channelSetId,
      });
    }

    if (
      defaultSettings.cooldownType !== data.cooldownType ||
      defaultSettings.cooldownTime !== data.cooldownTime
    ) {
      updateTrigger({
        cooldownTime: data.cooldownType === 'time' ? data.cooldownTime : null,
        cooldownType: data.cooldownType,
        id: triggerId,
      });
    }

    if (
      data.unsuppressedRules.sort().toString() !==
      defaultUnsupressedRules.sort().toString()
    ) {
      const allRulesIds = metricRules.map(({ id }) => id);

      const rulesToSuppress = allRulesIds.filter(
        (id) =>
          !data.unsuppressedRules.includes(id) &&
          defaultUnsupressedRules.includes(id),
      );

      const rulesToUnsuppress = allRulesIds.filter(
        (id) =>
          data.unsuppressedRules.includes(id) &&
          !defaultUnsupressedRules.includes(id),
      );

      rulesToSuppress.forEach((id) => {
        updateMetricRule({
          metricRuleId: id,
          metricRule: {
            suppressMessage: true,
          },
        });
      });

      rulesToUnsuppress.forEach((id) => {
        updateMetricRule({
          metricRuleId: id,
          metricRule: {
            suppressMessage: false,
          },
        });
      });
    }

    methods.reset({}, { keepValues: true });
  };

  const onError: SubmitErrorHandler<SettingsForm> = (errors) => {
    const { customMessage, messageType } = methods.getValues();
    setErrors({
      ...errors,
      customMessage:
        messageType === 'custom' && !customMessage?.length
          ? {
              type: 'required',
              message: 'Notification message is required.',
            }
          : undefined,
    });
  };

  useEffect(() => {
    setSubmit(() => {
      const { channels, title, customMessage, messageType } =
        methods.getValues();
      const errors = {
        channels: getErrorBody(
          channels.length === 0,
          'At least one channel should be selected.',
        ),
        title: getErrorBody(title.length === 0, 'Title is required'),
        customMessage: getErrorBody(
          messageType === 'custom' && customMessage?.length === 0,
          'Notification message is required.',
        ),
      };

      onError(errors);
      if (
        Object.keys(errors).filter((key) => errors[key as keyof typeof errors])
          .length > 0
      ) {
        return;
      }

      onSubmit(methods.getValues());
      return 0;
    });
  }, []);

  useEffect(() => {
    if (methods.formState.isDirty) {
      dispatch(setSettingsAsModified());
    }
  }, [methods.formState.isDirty]);

  useEffect(() => {
    if (defaultSettings.id === Number(alertId)) {
      methods.reset({
        channels: defaultSettings.channels,
        messageType: defaultSettings.hasCustomMessage ? 'custom' : 'generated',
        customMessage: defaultSettings.customMessage ?? '',
        title: defaultSettings.title ?? '',
        targetWidget: defaultSettings.targetWidget,
        targetWidgetType: defaultSettings.hasCustomTargetWidget
          ? 'custom'
          : 'default',
        cooldownTime: defaultSettings.cooldownTime,
        cooldownType: defaultSettings.cooldownType ?? 'none',
      });
    }
  }, [defaultSettings.id]);

  return {
    methods,
    isLoading:
      isLoading || isFetching || defaultSettings.id !== Number(alertId),
    handleSubmit: methods.handleSubmit(onSubmit, onError),
    errors,
    discardChanges,
    readOnly: caregiverRole.value === 'viewer',
  };
};
