import { GridColDef } from '@mui/x-data-grid-pro';
import { commonColumnProperties } from './records-columns/columns';
import { Organization } from 'types/organizations';
import { Button } from '@mui/material';
import { Icon } from '@iconify/react';

export const ADMIN_ORGANIZATIONS_COLUMNS: GridColDef<Organization>[] = [
  {
    ...commonColumnProperties,
    field: 'name',
    headerName: 'Organization Name',
    minWidth: 100,
  },
  {
    ...commonColumnProperties,
    field: 'requiresMfa',
    headerName: 'Requires MFA',
    minWidth: 150,
    renderCell: ({ row }) => (row.requiresMfa ? 'Yes' : 'No'),
  },
  {
    ...commonColumnProperties,
    field: 'canConfigureDevices',
    headerName: 'Can Configure Devices',
    minWidth: 200,
    renderCell: ({ row }) => (row.canConfigureDevices ? 'Yes' : 'No'),
  },
];
