import { Box, Modal } from '@mui/material';
import { Dayjs } from 'dayjs';
import { StyledDatePicker } from './StyledPicker';
import CalendarControls from './CalendarControls';

export const FocusedInputIndex = {
  START: 0,
  END: 1,
} as const;
export type FocusedInputIndex =
  (typeof FocusedInputIndex)[keyof typeof FocusedInputIndex];

interface Props {
  value: Dayjs | null;
  onChange: (newDate: Dayjs | null) => void;
  onResetClick?: () => void;
  onOkClick?: () => void;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
  open: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
  disableWeekends?: boolean;
}

const CalendarModal = ({
  open,
  onChange,
  maxDate,
  minDate,
  onResetClick,
  onOkClick,
  value,
  disableFuture,
  disablePast,
  disableWeekends,
}: Props) => {
  return (
    <Modal
      open={open}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box>
        <CalendarControls
          onOkClick={onOkClick}
          onResetClick={onResetClick}
          inputProps={{ value: (value as Dayjs | null)?.format('MM/DD/YYYY') }}
        >
          <StyledDatePicker
            value={value}
            displayStaticWrapperAs="desktop"
            shouldDisableDate={
              disableWeekends
                ? (day) =>
                    (day as Dayjs).day() === 0 || (day as Dayjs).day() === 6
                : undefined
            }
            disableFuture={disableFuture}
            disablePast={disablePast}
            onChange={(v) => onChange(v as Dayjs)}
            minDate={minDate ?? undefined}
            maxDate={maxDate ?? undefined}
          />
        </CalendarControls>
      </Box>
    </Modal>
  );
};

export default CalendarModal;
