import {
  Box,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Typography,
} from '@mui/material';
import NavigationButtons from '../NavigationButtons';
import { useContext, useState } from 'react';
import { ProfileConfiguratorContext } from '../ProfileConfigurator';
import { useGetOrganizationsAdminQuery } from 'store/api/admin.api';

export const Organization = () => {
  const {
    handleNext,
    handlePrevious,
    setProfileConfiguration,
    profileConfiguration,
  } = useContext(ProfileConfiguratorContext);
  const [organizationId, setOrganizationId] = useState<number>(
    profileConfiguration.organizationId,
  );

  const { data, isLoading } = useGetOrganizationsAdminQuery({});

  const handlePreviousStep = () => {
    setProfileConfiguration((profileConfiguration) => ({
      ...profileConfiguration,
      organizationId,
    }));
    handlePrevious();
  };

  const handleNextStep = () => {
    setProfileConfiguration((profileConfiguration) => ({
      ...profileConfiguration,
      organizationId,
    }));
    handleNext();
  };

  return (
    <Box height="100%" display="flex" flexDirection="column" gap={2}>
      <Box
        flexGrow={1}
        overflow="auto"
        pr={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
        pt={{ xxs: 1.5, xs: 2, sm: 3, newMd: 4 }}
      >
        <Typography color="primary" fontWeight={600} mb={1}>
          Organization
        </Typography>
        <Box
          display="flex"
          flexDirection="column"
          gap={1}
          pt={1}
          p={{ xxs: 1, newMd: 2 }}
        >
          <Typography>
            Select an organization from the list below (optional)
          </Typography>
          {isLoading ? (
            <Skeleton
              variant="rounded"
              sx={{
                width: '100%',
                maxWidth: 400,
                height: ({ spacing }) => spacing(5),
              }}
            ></Skeleton>
          ) : (
            <Select
              size="small"
              sx={{
                width: '100%',
                maxWidth: 400,
                fontStyle: organizationId === 0 ? 'italic' : undefined,
              }}
              value={organizationId}
              onChange={(e) => setOrganizationId(Number(e.target.value))}
            >
              <MenuItem sx={{ fontStyle: 'italic' }} value={0}>
                None
              </MenuItem>
              {data?.results.map((organization) => (
                <MenuItem key={organization.id} value={organization.id}>
                  {organization.name}
                </MenuItem>
              ))}
            </Select>
          )}
        </Box>
      </Box>
      <NavigationButtons
        onNextClick={handleNextStep}
        onPreviousClick={handlePreviousStep}
      />
    </Box>
  );
};
