import { Day } from 'utils/weekdays';
import { RawSerializedSchedule } from './scheduler';

export type PatternType = 'all_users' | 'all_patients';
export type RecipientType = 'User' | 'PatientProfile';

export interface AlerterRecipient {
  id: number;
  recipientType?: RecipientType;
  name: string;
  recipientId: number;
  patternType: PatternType;
  channelSetId?: number;
}

export interface AlerterRecipientSet {
  id: number;
  recipientSetId?: number;
  recipientManagerId?: number;
  priority: number;
  recipients: AlerterRecipient[];
}

export interface CreateRecipientManagerReq {
  name: string;
  patient_profile_id: number;
  attempt_count: number;
  attempt_spacing: number;
  is_reminder?: boolean;
  recipient_sets: {
    priority: number;
    recipients: {
      pattern_type?: PatternType;
      recipient_type?: RecipientType;
      recipient_id?: number;
    }[];
  }[];
  alerter_scheduler?: {
    invert_schedule: boolean;
    serialized_schedule: {
      start_time: number | null;
      end_time: number | null;
      days_of_week: Day[];
    };
  };
}

export interface RawRecipient {
  recipient_type: RecipientType;
  recipient_id: number;
  pattern_type: string;
  display_name: string;
}

export interface AlerterRecipientManager {
  id: number;
  attemptCount: number;
  attemptSpacing: number;
  name: string | null;
  alerters: { id: number; name?: string }[];
  sets: AlerterRecipientSet[];
  recipientsCount?: number;
  patientsCount?: number;
}

export interface RawAlerterRecipient {
  id: number;
  recipient_type?: RecipientType;
  recipient_id: number;
  recipient_display_name: string;
  pattern_type: PatternType;
  alerter_channel_set_id: null | number;
  alerter_recipient_set_id: number;
}

export interface RawAlerterRecipientSet {
  id: number;
  priority: number;
  alerter_recipients: RawAlerterRecipient[];
  alerter_recipient_set_id: number;
}

export interface RawAlerterRecipientManager {
  id: number;
  attempt_count: number;
  attempt_spacing: number;
  alerter_recipient_sets: RawAlerterRecipientSet[];
  patient_profile_recipients_count: number;
  user_recipients_count: number;
  name: string | null;
  alerters: { id: number }[];
  alerter_scheduler: {
    id: number;
  };
}

export interface RawAlerterRecipientManagerResponse {
  result: RawAlerterRecipientManager & {
    id: number;
    alerter_id: number;
    alerter_scheduler_ids: unknown;
  };
  meta: {
    alerters: [number, string][];
    alerter_schedulers: {
      id: number;
      serialized_schedule: RawSerializedSchedule;
    }[];
  };
}

export interface SharedAlerterRecipientManagerResponse {
  results: RawAlerterRecipientManager[];
  meta: {
    alerters: { id: number; title: string }[];
    alerter_schedulers: {
      id: number;
      serialized_schedule: RawSerializedSchedule;
    }[];
  };
}

export interface LinkRecipientManagerToSchedulerArgs {
  recipientManagerId: number;
  alerterId?: number;
  alerterSchedulerId?: number;
  isReminder?: boolean;
}

export interface AlerterRecipientSetArgs {
  recipientManagerId: number;
  priority?: number;
  alerterRecipients?: Partial<AlerterRecipient>[];
}

export interface RemoveRecipientSetArgs {
  managerId: number;
  setId: number;
}

export interface AlerterRecipientSetResponse {
  result: {
    id: number;
    priority: number;
    alerter_recipients: RawAlerterRecipient[];
  };
}
