import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const borderedList: IconifyJSON = {
  prefix: 'borderedList',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M8.05 16.775C8.3 16.775 8.51267 16.6873 8.688 16.512C8.86267 16.3373 8.95 16.125 8.95 15.875C8.95 15.625 8.86267 15.4167 8.688 15.25C8.51267 15.0833 8.3 15 8.05 15C7.8 15 7.59167 15.0833 7.425 15.25C7.25833 15.4167 7.175 15.625 7.175 15.875C7.175 16.125 7.25833 16.3373 7.425 16.512C7.59167 16.6873 7.8 16.775 8.05 16.775ZM8.05 12.875C8.3 12.875 8.51267 12.7917 8.688 12.625C8.86267 12.4583 8.95 12.25 8.95 12C8.95 11.75 8.86267 11.5417 8.688 11.375C8.51267 11.2083 8.3 11.125 8.05 11.125C7.8 11.125 7.59167 11.2083 7.425 11.375C7.25833 11.5417 7.175 11.75 7.175 12C7.175 12.25 7.25833 12.4583 7.425 12.625C7.59167 12.7917 7.8 12.875 8.05 12.875ZM8.05 9C8.3 9 8.51267 8.91667 8.688 8.75C8.86267 8.58333 8.95 8.375 8.95 8.125C8.95 7.875 8.86267 7.66233 8.688 7.487C8.51267 7.31233 8.3 7.225 8.05 7.225C7.8 7.225 7.59167 7.31233 7.425 7.487C7.25833 7.66233 7.175 7.875 7.175 8.125C7.175 8.375 7.25833 8.58333 7.425 8.75C7.59167 8.91667 7.8 9 8.05 9ZM11.1 16.625H16.8V15.125H11.1V16.625ZM11.1 12.75H16.8V11.25H11.1V12.75ZM11.1 8.875H16.8V7.375H11.1V8.875ZM5.3 20.5C4.8 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.2 3.5 18.7V5.3C3.5 4.8 3.675 4.375 4.025 4.025C4.375 3.675 4.8 3.5 5.3 3.5H18.7C19.2 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.8 20.5 5.3V18.7C20.5 19.2 20.325 19.625 19.975 19.975C19.625 20.325 19.2 20.5 18.7 20.5H5.3ZM5.3 19H18.7C18.7667 19 18.8333 18.9667 18.9 18.9C18.9667 18.8333 19 18.7667 19 18.7V5.3C19 5.23333 18.9667 5.16667 18.9 5.1C18.8333 5.03333 18.7667 5 18.7 5H5.3C5.23333 5 5.16667 5.03333 5.1 5.1C5.03333 5.16667 5 5.23333 5 5.3V18.7C5 18.7667 5.03333 18.8333 5.1 18.9C5.16667 18.9667 5.23333 19 5.3 19Z" fill="currentColor"/>',
    },
    filled: {
      ...defaultSizes,
      body: '<path d="M8.05 16.775C8.3 16.775 8.51267 16.6873 8.688 16.512C8.86267 16.3373 8.95 16.125 8.95 15.875C8.95 15.625 8.86267 15.4167 8.688 15.25C8.51267 15.0833 8.3 15 8.05 15C7.8 15 7.59167 15.0833 7.425 15.25C7.25833 15.4167 7.175 15.625 7.175 15.875C7.175 16.125 7.25833 16.3373 7.425 16.512C7.59167 16.6873 7.8 16.775 8.05 16.775ZM8.05 12.875C8.3 12.875 8.51267 12.7917 8.688 12.625C8.86267 12.4583 8.95 12.25 8.95 12C8.95 11.75 8.86267 11.5417 8.688 11.375C8.51267 11.2083 8.3 11.125 8.05 11.125C7.8 11.125 7.59167 11.2083 7.425 11.375C7.25833 11.5417 7.175 11.75 7.175 12C7.175 12.25 7.25833 12.4583 7.425 12.625C7.59167 12.7917 7.8 12.875 8.05 12.875ZM8.05 9C8.3 9 8.51267 8.91667 8.688 8.75C8.86267 8.58333 8.95 8.375 8.95 8.125C8.95 7.875 8.86267 7.66233 8.688 7.487C8.51267 7.31233 8.3 7.225 8.05 7.225C7.8 7.225 7.59167 7.31233 7.425 7.487C7.25833 7.66233 7.175 7.875 7.175 8.125C7.175 8.375 7.25833 8.58333 7.425 8.75C7.59167 8.91667 7.8 9 8.05 9ZM11.1 16.625H16.8V15.125H11.1V16.625ZM11.1 12.75H16.8V11.25H11.1V12.75ZM11.1 8.875H16.8V7.375H11.1V8.875ZM5.3 20.5C4.8 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.2 3.5 18.7V5.3C3.5 4.8 3.675 4.375 4.025 4.025C4.375 3.675 4.8 3.5 5.3 3.5H18.7C19.2 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.8 20.5 5.3V18.7C20.5 19.2 20.325 19.625 19.975 19.975C19.625 20.325 19.2 20.5 18.7 20.5H5.3Z" fill="currentColor"/>',
    },
  },
};
