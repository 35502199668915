import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export interface UrlPaginationOptions {
  defaultPage?: number;
  defaultPageSize?: number;
  availablePageSizes?: number[];
}

export const useUrlPagination = (
  options: UrlPaginationOptions = {
    availablePageSizes: [25, 50, 100],
    defaultPage: 1,
    defaultPageSize: 25,
  },
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const setPage = (page: number) => {
    searchParams.set('page', page.toString());
    setSearchParams(searchParams);
  };
  const getPage = (): number => {
    return parseInt(searchParams.get('page') || '1');
  };

  const setPageSize = (pageSize: number) => {
    searchParams.set('pageSize', pageSize.toString());
    setSearchParams(searchParams);
  };

  const getPageSize = (): number => {
    return parseInt(searchParams.get('pageSize') || '25');
  };

  useEffect(() => {
    if (!searchParams.get('page')) {
      setPage(options.defaultPage ?? 1);
    }
  }, []);

  useEffect(() => {
    if (!searchParams.get('pageSize')) {
      setPageSize(
        options.availablePageSizes?.[0] ?? options.defaultPageSize ?? 25,
      );
    }
  }, []);
  return {
    setPage,
    page: getPage(),
    pageSize: getPageSize(),
    setPageSize,
    availablePageSizes: options.availablePageSizes,
  };
};
