import {
  alpha,
  Backdrop,
  BackdropProps,
  Box,
  Button,
  Fade,
  Modal,
  Paper,
  styled,
  Typography,
} from '@mui/material';
import IconButton, { IconButtonProps } from 'components/IconButton/IconButton';
import { Mfa } from 'components/Mfa';
import { CompletionMessage } from 'components/CompletionMessage';
import { useState } from 'react';
import ChangePasswordForm from './ChangePasswordForm';
import { useAppDispatch } from 'store';
import { resetChosenMethod } from 'store/reducers/mfa/mfa.slice';

const ModalCloseBtn = styled((props: Omit<IconButtonProps, 'icon'>) => (
  <IconButton size="small" icon="material-symbols:close" {...props} />
))(({ theme }) => ({
  color: theme.palette.lightBlue.main,
  padding: theme.spacing(0.75),
  minWidth: theme.spacing(2.5),
  borderRadius: theme.spacing(0.625),
  transition: theme.transitions.create(['color', 'background-color']),
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: alpha(theme.palette.primary.main, 0.08),
  },
}));

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isMfaPassed, setMfaPassed] = useState(false);
  const [isPasswordChangeSuccess, setPasswordChangeSuccess] = useState(false);

  const handleModalClose = () => {
    setModalOpen(false);
    setPasswordChangeSuccess(false);
  };

  const handleMfaPass = () => {
    setMfaPassed(true);
  };

  const handlePasswordChangeSuccess = () => {
    dispatch(resetChosenMethod());
    setPasswordChangeSuccess(true);
  };

  const isLoading = false;

  return (
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Typography py={1} color="primary">
        Change account password
      </Typography>
      <Button onClick={() => setModalOpen(true)} size="small" sx={{ mr: 1 }}>
        Change
      </Button>
      <Modal open={isModalOpen} onClose={handleModalClose}>
        <Box
          position="absolute"
          top={0}
          left={0}
          width="100vw"
          height="100vh"
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Paper
            sx={{
              m: 2,
              p: 4,
              width: ({ spacing }) => spacing(62),
              position: 'relative',
            }}
          >
            {!isMfaPassed ? (
              <Mfa onCompletion={handleMfaPass} includeCredentialId />
            ) : isPasswordChangeSuccess ? (
              <CompletionMessage
                status="success"
                onButtonClick={handleModalClose}
                title="Password changed succesfully!"
                subtitle="Your old password is no longer available. Please use your new password on the next log in."
                buttonLabel="Got It"
                p={0}
              />
            ) : (
              <Box>
                <ChangePasswordForm onSuccess={handlePasswordChangeSuccess} />
              </Box>
            )}
            <Fade in={!isLoading}>
              <Box
                position="absolute"
                top={({ spacing }) => spacing(1)}
                right={({ spacing }) => spacing(1)}
              >
                <ModalCloseBtn onClick={handleModalClose} />
              </Box>
            </Fade>
          </Paper>
        </Box>
      </Modal>
    </Box>
  );
};

export default ChangePassword;
