import { styled, typographyClasses } from '@mui/material';
import {
  dateRangePickerDayClasses,
  dayCalendarClasses,
  pickersDayClasses,
  pickersCalendarHeaderClasses,
  StaticDatePicker,
  dateRangeCalendarClasses,
} from '@mui/x-date-pickers-pro';

export const StyledDatePicker = styled(StaticDatePicker)(({ theme }) => ({
  [`.${typographyClasses.subtitle1}`]: {
    color: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: theme.spacing(2),
    lineHeight: theme.spacing(2.5),
  },

  [`.${dayCalendarClasses.header}`]: {
    color: theme.palette.primary.main,
  },

  [`.${pickersCalendarHeaderClasses.label}`]: {
    color: theme.palette.primary.main,
    pointerEvents: 'none',
  },

  [`.${pickersCalendarHeaderClasses.labelContainer}`]: {
    pointerEvents: 'none',
  },

  [`.${pickersCalendarHeaderClasses.switchViewIcon}`]: {
    display: 'none',
  },

  [`.${dayCalendarClasses.weekDayLabel}`]: {
    color: theme.palette.blue.main,
    fontSize: 14,
  },

  [`.${pickersDayClasses.hiddenDaySpacingFiller}`]: {
    backgroundColor: 'transparent',
  },

  [`.${pickersDayClasses.root}`]: {
    color: theme.palette.primary.main,
    transform: 'scale(1)',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: theme.palette.blue.light,
      borderColor: theme.palette.blue.dark,
      [`.${pickersDayClasses.dayOutsideMonth}`]: {
        borderColor: 'transparent',
        backgroundColor: 'transparent',
      },
    },
    '.Mui-selected': {
      color: theme.palette.common.white,
    },
  },

  [`.${dateRangeCalendarClasses.monthContainer}:not(:last-of-type)`]: {
    borderRight: 'none',
  },

  [`.${dateRangePickerDayClasses.rangeIntervalDayHighlight}`]: {
    backgroundColor: theme.palette.blue.light,
    [`&.${dateRangePickerDayClasses.outsideCurrentMonth}`]: {
      backgroundColor: 'transparent',
    },
    button: {
      color: theme.palette.primary.main,
    },
  },

  [`.${dateRangePickerDayClasses.rangeIntervalDayHighlightStart},
    .${dateRangePickerDayClasses.rangeIntervalDayHighlightEnd},
    .${dateRangePickerDayClasses.rangeIntervalDayPreviewStart},
    .${dateRangePickerDayClasses.rangeIntervalDayPreviewEnd}`]: {
    '.Mui-selected': {
      color: theme.palette.common.white,
    },
  },

  [`.${dateRangePickerDayClasses.rangeIntervalDayPreviewEnd}:hover,
    .${dateRangePickerDayClasses.rangeIntervalDayHighlightEnd}:hover:last-of-type`]:
    {
      '& > div': {
        border: `2px solid ${theme.palette.primary.main}`,
        backgroundColor: theme.palette.blue.light,
        borderRadius: '50%',
      },
    },

  [`.${dateRangePickerDayClasses.rangeIntervalDayHighlightStart},
    .${dateRangePickerDayClasses.rangeIntervalDayHighlightEnd}:last-of-type`]: {
    border: 'none',
  },

  [`.${dateRangePickerDayClasses.rangeIntervalDayPreview}`]: {
    boxSizing: 'border-box',
  },

  [`.${pickersDayClasses.today}`]: {
    border: `2px solid ${theme.palette.secondary.main} !important`,
  },
}));
