import { Organization } from 'types/organizations';
import { FormProvider } from 'react-hook-form';
import { useOrganizationSettings } from '../hooks/use-organization-settings';
import { useAdminStatusQuery } from 'store/api/auth.api';
import { useAppSelector } from 'store';
import { OrganizationSettingsForm } from 'components/_extra/OrganizationSettingsForm';

interface Props {
  defaultValues: Organization;
}

const SettingsForm = ({ defaultValues }: Props) => {
  const { accountId } = useAppSelector((state) => state.user);
  const { data: isAdmin } = useAdminStatusQuery({
    userId: accountId as number,
  });

  const { methods, handleSubmit, isLoading, isDirty, resetOrganizationForm } =
    useOrganizationSettings(defaultValues);

  const shouldDisableControls =
    isLoading || !(defaultValues.role === 'admin' || isAdmin) || !isDirty;

  return (
    <FormProvider {...methods}>
      <OrganizationSettingsForm
        handleSubmit={handleSubmit}
        isLoading={isLoading}
        resetOrganizationForm={resetOrganizationForm}
        shouldDisableControls={shouldDisableControls}
      />
    </FormProvider>
  );
};

export default SettingsForm;
