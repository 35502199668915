import {
  Organization,
  OrganizationAddress,
  OrganizationsResponseMeta,
  RawOrganization,
  RawOrganizationAddress,
} from 'types/organizations';

export const transformOrganizationAddressResponse = (
  address: RawOrganizationAddress | null,
): OrganizationAddress | null => {
  if (!address) return null;

  return {
    id: address.id,
    companyName: address.company_name,
    city: address.city,
    country: address.country,
    stateOrProvince: address.state_or_province,
    postalCode: address.zip_or_postal_code,
    addressLine1: address.address_line_1,
    addressLine2: address.address_line_2,
    personFirstName: address.person_first_name,
    personLastName: address.person_last_name,
    addressableId: address.addressable_id,
    addressableType: address.addressable_type,
    createdAt: address.created_at,
    updatedAt: address.updated_at,
  };
};

export const transformOrganizationResponse = (
  organization: RawOrganization,
  meta?: OrganizationsResponseMeta,
): Organization => {
  const role = meta?.organization_memberships.find(
    ({ organization_id }) => organization_id === organization.id,
  )?.role;

  return {
    id: organization.id,
    name: organization.name,
    patientProfileMemberIds: organization.patient_profile_member_ids,
    userMemberIds: organization.user_member_ids,
    canConfigureDevices: organization.can_configure_devices,
    requiresMfa: organization.requires_mfa,
    specialAlerterChannels: {
      nossXml: organization.special_alerter_channels.noss_xml,
    },
    address: transformOrganizationAddressResponse(organization.address),
    role,
  };
};

export const transformAddressArgs = (
  address: Partial<OrganizationAddress>,
): Partial<RawOrganizationAddress> => {
  return {
    id: address.id ?? undefined,
    company_name: address.companyName ?? undefined,
    city: address.city ?? undefined,
    country: address.country ?? undefined,
    state_or_province: address.stateOrProvince ?? undefined,
    zip_or_postal_code: address.postalCode ?? undefined,
    address_line_1: address.addressLine1 ?? undefined,
    address_line_2: address.addressLine2 ?? undefined,
    person_first_name: address.personFirstName ?? undefined,
    person_last_name: address.personLastName ?? undefined,
    addressable_id: address.addressableId ?? undefined,
    addressable_type: address.addressableType ?? undefined,
    created_at: address.createdAt ?? undefined,
    updated_at: address.updatedAt ?? undefined,
  };
};
