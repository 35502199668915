import { Box, Typography } from '@mui/material';

const Summary = ({
  title,
  details,
  fullWidth,
  noWrap,
  minWidth,
  shrink,
}: {
  title: string;
  details: React.ReactNode;
  fullWidth?: boolean;
  noWrap?: boolean;
  shrink?: boolean;
  minWidth?: number;
}) => {
  return (
    <Box
      sx={{
        ...(fullWidth
          ? { flexGrow: 1, flexBasis: shrink ? 0 : undefined }
          : {
              maxWidth: 'inherit',
            }),
        minWidth: minWidth ? ({ spacing }) => spacing(minWidth) : undefined,
      }}
    >
      <Typography variant="body2" gutterBottom>
        {title}
      </Typography>
      <Typography
        variant="body2"
        noWrap={noWrap}
        sx={{ color: ({ palette }) => palette.blue.main }}
      >
        <>{details}</>
      </Typography>
    </Box>
  );
};

export default Summary;
