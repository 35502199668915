import { useSignals } from '@preact/signals-react/runtime';
import { caregiverRole } from 'services/caregiver-role';
import { useAppSelector } from 'store';
import isScheduleRuleDifferentSelector from 'store/selectors/is-schedule-rule-different.selector';
import isScheduleRuleEditedSelector from 'store/selectors/is-schedule-rule-edited-selector';

export const useScheduleRuleCard = (id: number) => {
  useSignals();
  const isEdited = useAppSelector(isScheduleRuleEditedSelector(id));
  const isRuleDifferent = useAppSelector(isScheduleRuleDifferentSelector);

  return {
    isRuleDifferent,
    isEdited,
    readOnly: caregiverRole.value === 'viewer',
  };
};
