import { Icon } from '@iconify/react';
import { Box, Button, Modal, Paper, Typography } from '@mui/material';
import { maxHeight } from '@mui/system';
import { OrganizationSettingsForm } from 'components/_extra/OrganizationSettingsForm';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useCreateOrganizationMutation } from 'store/api/admin.api';
import { Organization } from 'types/organizations';

export const NewOrganizationButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [createOrganization, { isLoading, isSuccess, isError }] =
    useCreateOrganizationMutation();

  const methods = useForm<Organization>({
    defaultValues: {
      name: '',
      canConfigureDevices: false,
      requiresMfa: false,
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
      },
    },
  });

  const onSubmit = (data: Organization) => {
    createOrganization({
      name: data.name,
      can_configure_devices: data.canConfigureDevices,
      requires_mfa: data.requiresMfa,
      address: {
        address_line_1: data.address?.addressLine1 ?? null,
        address_line_2: data.address?.addressLine2 ?? null,
        city: data.address?.city ?? null,
        zip_or_postal_code: data.address?.postalCode ?? null,
        country: data.address?.country ?? null,
        state_or_province: data.address?.stateOrProvince ?? null,
      },
      special_alerter_channels: {
        noss_xml: {
          active: data.specialAlerterChannels?.nossXml.active ?? false,
        },
      },
    });
  };

  const resetOrganizationForm = () => {
    methods.reset();
  };

  useEffect(() => {
    if (isSuccess) {
      setIsModalOpen(false);
      resetOrganizationForm();
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      console.error('Something went wrong');
    }
  }, [isError]);

  return (
    <>
      <Box sx={{ width: '100%', display: 'flex', alignItems: 'end' }}>
        <Button
          onClick={() => setIsModalOpen(true)}
          sx={{ ml: 'auto' }}
          startIcon={<Icon icon="material-symbols:add" />}
        >
          New Organization
        </Button>
      </Box>
      <Modal
        component="div"
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      >
        <Paper
          sx={({ spacing }) => ({
            maxHeight: '90vh',
            overflow: 'auto',
            maxWidth: spacing(80),
            width: '100%',
          })}
        >
          <Typography
            sx={{
              pt: 4,
              px: 4,
            }}
            variant="h6"
          >
            Configure a new Organization:
          </Typography>
          <FormProvider {...methods}>
            <OrganizationSettingsForm
              handleSubmit={methods.handleSubmit(onSubmit)}
              isLoading={isLoading}
              resetOrganizationForm={resetOrganizationForm}
              shouldDisableControls={false}
              isAdmin={true}
            />
          </FormProvider>
        </Paper>
      </Modal>
    </>
  );
};
