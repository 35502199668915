/* eslint-disable @typescript-eslint/no-empty-function */
import {
  Box,
  IconButtonProps,
  IconButton as MuiIconButton,
  Tab as MuiTab,
  TabProps,
  Tabs,
  buttonClasses,
  styled,
  tabClasses,
  tabsClasses,
} from '@mui/material';
import { createContext } from 'react';
import { RecipientsSets, Triggers } from './components';
import { Settings } from './components/Settings';
import { Icon } from '@iconify/react';
import { arrowLeft } from 'assets/iconify';
import { Link, LinkProps, useParams } from 'react-router-dom';
import { routes } from 'nav';
import { useAlerterDetailsTabs } from './hooks/use-alerter-details-tabs';
import { PageContent } from 'components/_extra/generic';
import AddTriggerButton from './components/Triggers/AddTriggerButton';
import { useAppSelector } from 'store';
import { triggerCountSelector } from 'store/selectors';
import { History } from './components/History';
import AlertHistoryTable from 'pages/Alerters/components/AlertHistory/AlertHistoryTable';
import CreateRecipientSetButton from 'pages/Alerters/components/RecipientSets/CreateRecipientSetButton';
import { useRecipientSets } from 'hooks/use-recipient-sets';

export const AlerterDetailsTabIndex = {
  TRIGGERS: 0,
  RECIPIENTS_SETS: 1,
  HISTORY: 2,
  SETTINGS: 3,
} as const;

export type AlerterDetailsTabIndex =
  (typeof AlerterDetailsTabIndex)[keyof typeof AlerterDetailsTabIndex];

const getTabPanelContent = (index: AlerterDetailsTabIndex) => {
  switch (index) {
    case AlerterDetailsTabIndex.TRIGGERS:
      return <Triggers />;
    case AlerterDetailsTabIndex.RECIPIENTS_SETS:
      return <RecipientsSets />;
    case AlerterDetailsTabIndex.HISTORY:
      return <History />;
    case AlerterDetailsTabIndex.SETTINGS:
      return <Settings />;
    default:
      <></>;
  }
};

const Wrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  backgroundColor: theme.palette.primary.main,
  [`.${tabClasses.root}:not(.${tabClasses.selected})`]: {
    color: theme.palette.gray.light,
    opacity: 0.7,
  },
  [`.${tabsClasses.root}`]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  [theme.breakpoints.down('newLg')]: {
    [`.${tabsClasses.root}`]: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
    },
  },

  [`.${tabsClasses.root}:has(> .${tabsClasses.scrollButtons})`]: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  [`.${tabsClasses.scrollButtons}`]: {
    color: theme.palette.common.white,
    [`&.${buttonClasses.disabled}`]: {
      opacity: 0.4,
    },
    svg: {
      padding: theme.spacing(0.5),
      width: theme.spacing(3.5),
      height: theme.spacing(3.5),
      borderRadius: '50%',
      '&:hover': {
        backgroundColor: theme.palette.hover.dark,
      },
    },
  },
}));

const BackButtonWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 1),
  borderRight: `1px solid ${theme.palette.hover.dark}`,
  'a:hover': {
    backgroundColor: theme.palette.hover.dark,
  },
}));

const IconButton = (props: IconButtonProps & { to?: LinkProps['to'] }) => (
  <MuiIconButton {...props} />
);

const Tab = styled(
  (props: TabProps<'div', { isModified?: boolean }>) => <MuiTab {...props} />,
  { shouldForwardProp: (prop) => prop !== 'isModified' },
)(({ theme, isModified }) => ({
  position: 'relative',
  padding: theme.spacing(2),
  ...(isModified && {
    '&::before': {
      position: 'absolute',
      left: 0,
      content: '""',
      width: theme.spacing(1),
      height: theme.spacing(1),
      borderRadius: theme.spacing(0.5),
      backgroundColor: theme.palette.error.main,
    },
  }),
}));

export const SettingsContext = createContext({
  setSubmit: (arg: () => void) => {
    arg();
  },
});

const AlerterDetails = () => {
  const { profileId, alertId } = useParams();
  const { sets } = useRecipientSets();
  const isListEmpty = useAppSelector(triggerCountSelector('alerter')) === 0;
  const {
    currentTab,
    handleTabChange,
    handleSubmitChange,
    areSettingsModified,
    isViewCollapsed,
    toggleView,
  } = useAlerterDetailsTabs();

  return (
    <Box
      height={({ spacing }) => ({
        xxs: `calc(100dvh - ${spacing(12)})`,
        newMd: `calc(100dvh - ${spacing(14)})`,
      })}
      display="flex"
      flexDirection="column"
    >
      <PageContent>
        <Wrapper>
          <BackButtonWrapper>
            <IconButton
              LinkComponent={Link}
              to={routes.private.alerter.href(profileId ?? 0)}
              sx={({ palette }) => ({ color: palette.common.white })}
            >
              <Icon icon={arrowLeft.icons.normal} />
            </IconButton>
          </BackButtonWrapper>
          <Tabs
            value={currentTab}
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleTabChange}
          >
            <Tab value={AlerterDetailsTabIndex.TRIGGERS} label="Conditions" />
            <Tab
              value={AlerterDetailsTabIndex.RECIPIENTS_SETS}
              label="Recipient Sets"
            />
            <Tab value={AlerterDetailsTabIndex.HISTORY} label="History" />
            <Tab
              value={AlerterDetailsTabIndex.SETTINGS}
              label="Settings"
              isModified={areSettingsModified}
            />
          </Tabs>
        </Wrapper>
        <SettingsContext.Provider value={{ setSubmit: handleSubmitChange }}>
          <Box
            sx={{
              padding: ({ spacing }) => {
                if (isViewCollapsed) {
                  return 0;
                }

                const withoutPadding =
                  (currentTab === AlerterDetailsTabIndex.TRIGGERS &&
                    !isListEmpty) ||
                  (currentTab === AlerterDetailsTabIndex.RECIPIENTS_SETS &&
                    sets.length !== 0);
                return {
                  xxs: spacing(2, 1.5, withoutPadding ? 0 : 2),
                  xs: spacing(2, 2, withoutPadding ? 0 : 2),
                  sm: spacing(3, 3, withoutPadding ? 0 : 3),
                  newMd: spacing(4, 4, withoutPadding ? 0 : 4),
                };
              },
            }}
            overflow={isViewCollapsed ? 'hidden' : 'auto'}
            height={isViewCollapsed ? 0 : '100%'}
          >
            {getTabPanelContent(currentTab)}
          </Box>
          {currentTab === AlerterDetailsTabIndex.TRIGGERS && (
            <Box
              display="flex"
              justifyContent="flex-end"
              padding={({ spacing }) => ({
                xxs: isListEmpty ? 0 : spacing(2, 1.5),
                xs: isListEmpty ? 0 : spacing(2),
                sm: isListEmpty ? 0 : spacing(2, 3, 3),
                newMd: isListEmpty ? 0 : spacing(2, 4, 4),
              })}
            >
              {!isListEmpty && <AddTriggerButton />}
            </Box>
          )}
          {currentTab === AlerterDetailsTabIndex.RECIPIENTS_SETS && (
            <Box
              display="flex"
              justifyContent="flex-end"
              padding={({ spacing }) => ({
                xxs: isListEmpty ? 0 : spacing(2, 1.5),
                xs: isListEmpty ? 0 : spacing(2),
                sm: isListEmpty ? 0 : spacing(2, 3, 3),
                newMd: isListEmpty ? 0 : spacing(2, 4, 4),
              })}
            >
              {sets.length !== 0 && <CreateRecipientSetButton />}
            </Box>
          )}
        </SettingsContext.Provider>
      </PageContent>
      {currentTab === AlerterDetailsTabIndex.HISTORY && (
        <AlertHistoryTable
          isViewCollapsed={isViewCollapsed}
          toggleCollapse={toggleView}
          alertId={alertId}
        />
      )}
    </Box>
  );
};

export default AlerterDetails;
