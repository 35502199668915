import {
  Organization,
  OrganizationAddress,
  OrganizationMembership,
  OrganizationSpecialAlerterChannels,
  OrganizationsResponse,
  RawOrganization,
} from 'types/organizations';
import { bcApi } from './_api';
import {
  transformAddressArgs,
  transformOrganizationResponse,
} from 'utils/data-transformation/organizations';
import { GetProfileReportsArgs } from 'types/patient';
import { GetGroupReportsResponse } from 'models/profiles.model';

export const organizationApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrganizations: builder.query<Organization[], void>({
      query: () => ({
        url: '/organizations',
        method: 'GET',
      }),
      transformResponse: ({ results, meta }: OrganizationsResponse) =>
        results.map((org) => transformOrganizationResponse(org, meta)),
      providesTags: ['OrganizationDetails'],
    }),
    getOrganizationById: builder.query<Organization, number>({
      query: (id) => ({
        url: `/organizations/${id}`,
        method: 'GET',
      }),
      transformResponse: ({ result }: { result: RawOrganization }) =>
        transformOrganizationResponse(result),
    }),
    updateOrganizationAddress: builder.mutation<
      Organization,
      { id: number } & Partial<OrganizationAddress>
    >({
      query: ({ id, ...address }) => ({
        url: `/organizations/${id}/update_organization_address`,
        method: 'PUT',
        data: transformAddressArgs(address),
      }),
      transformResponse: ({ result }: { result: RawOrganization }) =>
        transformOrganizationResponse(result),
      invalidatesTags: ['OrganizationDetails'],
    }),
    updateOrganizationsSettings: builder.mutation<
      Organization,
      Pick<Organization, 'id' | 'requiresMfa' | 'name'> & {
        nossActive?: boolean;
      }
    >({
      query: ({ id, requiresMfa, nossActive, name }) => ({
        url: `/organizations/${id}`,
        method: 'PUT',
        data: {
          name,
          requires_mfa: requiresMfa,
          special_alerter_channels: {
            noss_xml: {
              active: nossActive,
            },
          },
        },
        transformResponse: ({ result }: { result: RawOrganization }) =>
          transformOrganizationResponse(result),
        invalidatesTags: ['OrganizationDetails'],
      }),
    }),
    getOwnOrganizationMemberships: builder.query<
      OrganizationMembership[],
      void
    >({
      query: () => ({
        url: 'organizations/own_memberships',
        method: 'GET',
      }),
      transformResponse: ({ results }) => results,
    }),
    getOrganizationsMembership: builder.query<
      {
        results: OrganizationMembership[];
        totalDisplayedRows: number;
        totalRows: number;
      },
      {
        id: number;
        limit?: number;
        offset?: number;
        type?: string;
      }
    >({
      query: ({ id, limit, offset, type }) => ({
        url: `/organizations/${id}/memberships`,
        method: 'GET',
        params: {
          limit,
          offset,
          member_type: type,
        },
      }),
      transformResponse: (data: {
        results: OrganizationMembership[];
        meta: {
          total_displayed_rows: number;
          total_rows: number;
        };
      }) => {
        return {
          results: data.results,
          totalDisplayedRows: data.meta.total_displayed_rows,
          totalRows: data.meta.total_rows,
        };
      },
      providesTags: ['OrganizationMemberships'],
    }),
    getMembershipRoles: builder.query<
      {
        results: string[];
      },
      { organizationId: number }
    >({
      query: ({ organizationId }) => ({
        url: `organizations/${organizationId}/available_membership_roles`,
        method: 'GET',
      }),
    }),
    createMembership: builder.mutation<
      unknown,
      {
        organizationId: number;
        accountId?: number;
        email?: string;
        unique_public_identifier?: string;
        role: string;
      }
    >({
      query: ({
        organizationId,
        email,
        unique_public_identifier,
        accountId,
        role,
      }) => ({
        url: `organizations/${organizationId}/add_membership`,
        method: 'POST',
        data: {
          account_id: accountId,
          role,
          email_identifier: email,
          unique_public_identifier,
        },
      }),
      invalidatesTags: ['OrganizationMemberships'],
    }),
    updateMebership: builder.mutation<
      OrganizationMembership[],
      { organizationId: number; membershipId: number; role: string }
    >({
      query: ({ organizationId, membershipId, role }) => ({
        url: `organizations/${organizationId}/update_membership/${membershipId}`,
        method: 'PUT',
        data: {
          role,
        },
      }),
      invalidatesTags: ['OrganizationMemberships'],
    }),
    deleteMembership: builder.mutation<
      OrganizationMembership[],
      { organizationId: number; membershipId: number }
    >({
      query: ({ organizationId, membershipId }) => ({
        url: `organizations/${organizationId}/remove_member/${membershipId}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['OrganizationMemberships'],
    }),
    getSpecialOrganizationChannels: builder.query<
      OrganizationSpecialAlerterChannels[],
      { organizationId: number }
    >({
      query: ({ organizationId }) => ({
        url: `organizations/${organizationId}/available_special_channels`,
        method: 'GET',
      }),
    }),
    getOrganizationReport: builder.query<
      GetGroupReportsResponse,
      Omit<GetProfileReportsArgs, 'profileId'> & {
        organizationId: number;
        limit: number;
      }
    >({
      query: ({ organizationId, limit, statsList, from, to }) => ({
        url: `patient_profiles/group_statistics`,
        method: 'POST',
        data: {
          from,
          to,
          limit,
          stats_list: statsList,
          organization_id: organizationId,
        },
      }),
    }),
  }),
});

export const {
  useGetOrganizationsQuery,
  useUpdateOrganizationAddressMutation,
  useUpdateOrganizationsSettingsMutation,
  useGetMembershipRolesQuery,
  useGetSpecialOrganizationChannelsQuery,
  useGetOrganizationByIdQuery,
  useUpdateMebershipMutation,
  useGetOrganizationReportQuery,
  useGetOwnOrganizationMembershipsQuery,
  useGetOrganizationsMembershipQuery,
  useDeleteMembershipMutation,
  useCreateMembershipMutation,
} = organizationApi;
