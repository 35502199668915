import { Box, CircularProgress, Paper } from '@mui/material';
import { Table } from 'components/Table';
import { gridClasses } from '@mui/x-data-grid-pro';
import { useGetOrganizationsAdminQuery } from 'store/api/admin.api';
import { ADMIN_ORGANIZATIONS_COLUMNS as columns } from 'utils/admin-organizations-columns';
import { useNavigate } from 'react-router-dom';
import { routes } from 'nav';
import { NewOrganizationButton } from './NewOrganizationButton';
import { useUrlPagination } from 'hooks/use-url-pagination';

export const Organizations = () => {
  const navigate = useNavigate();

  const { availablePageSizes, page, pageSize, setPage, setPageSize } =
    useUrlPagination();

  const { data, isLoading } = useGetOrganizationsAdminQuery({
    limit: pageSize,
    offset: (page - 1) * pageSize,
  });

  if (isLoading) {
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          mt: 8,
        }}
      >
        <CircularProgress size={72} />
      </Box>
    );
  }

  if (!data) return null;

  return (
    <>
      <Paper sx={{ mb: 2 }}>
        <Table
          sx={{
            [`.${gridClasses.cell}`]: {
              py: 0,
            },
            [`.${gridClasses.row}`]: {
              cursor: 'pointer',
            },
          }}
          rowHeight={52}
          rows={data.results}
          columns={columns}
          pagination
          onRowClick={(row) =>
            navigate(routes.admin.organizationDetails.href(row.id as string))
          }
          paginationMode="server"
          paginationModel={{
            page: page - 1,
            pageSize,
          }}
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page + 1);
            setPageSize(newModel.pageSize);
          }}
          pageSizeOptions={availablePageSizes}
          rowCount={data.totalRows}
        />
      </Paper>
      <NewOrganizationButton />
    </>
  );
};
