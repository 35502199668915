import { Box, Divider, Switch, Typography, alpha } from '@mui/material';
import { CopyButton, EditButton, RemoveButton } from 'components/_extra';
import React from 'react';
import { Wrapper } from './styled';
import SmallScreenControls from './SmallScreenControls';
import { caregiverRole } from 'services/caregiver-role';
import ShowButton from 'components/_extra/Buttons/ShowButton';
import { useSignals } from '@preact/signals-react/runtime';

interface Props {
  title: string;
  isActive?: boolean;
  children?: React.ReactNode;
  onEdit?: () => void;
  onCopy?: () => void;
  onDelete?: () => void;
  onSwitchToggle?: (checked: boolean) => void;
  withoutSwitch?: boolean;
  hideCopy?: boolean;
  hideDelete?: boolean;
}

const Card = ({
  title,
  isActive,
  onEdit,
  onCopy,
  onDelete,
  onSwitchToggle,
  children,
  withoutSwitch,
  hideCopy,
  hideDelete,
}: Props) => {
  useSignals();
  const readOnly = caregiverRole.value === 'viewer';

  return (
    <Wrapper isActive={false} isClickable={false}>
      <Box flexGrow={1}>
        <Box display="flex" alignItems="start" gap={2}>
          <Box flexGrow={1} maxWidth="100%">
            <Box
              display="grid"
              gridTemplateColumns="auto 1fr"
              alignItems="center"
              gap={1}
              height="32px"
              overflow="hidden"
            >
              {!withoutSwitch && (
                <Switch
                  size="small"
                  color="secondary"
                  checked={isActive}
                  disabled={readOnly}
                  onChange={(e, checked) => onSwitchToggle?.(checked)}
                />
              )}
              <Typography variant="subtitle2" noWrap>
                {title}
              </Typography>
            </Box>
            <Divider
              sx={{
                mt: 1,
                mb: 2,
                borderColor: ({ palette }) => alpha(palette.primary.main, 0.23),
              }}
            />
          </Box>
          <Box gap={1} display={{ xxs: 'none', sm: 'flex' }}>
            {readOnly ? (
              <ShowButton onClick={onEdit} toSee="configuration" />
            ) : (
              <EditButton disabled={!onEdit} onClick={onEdit} />
            )}
            {!hideCopy && (
              <CopyButton disabled={!onCopy || readOnly} onClick={onCopy} />
            )}
            {!hideDelete && (
              <RemoveButton
                disabled={!onDelete || readOnly}
                onClick={onDelete}
                tooltipText="Delete"
              />
            )}
          </Box>
          <SmallScreenControls
            onCopy={onCopy}
            onEdit={onEdit}
            onRemove={onDelete}
            removeLabel="Delete"
          />
        </Box>
        <Box
          display="flex"
          gap={{ xxs: 2, sm: 4 }}
          flexWrap={{ xxs: 'wrap', sm: 'nowrap' }}
        >
          <>{children}</>
        </Box>
      </Box>
    </Wrapper>
  );
};
export default Card;
