import {
  Button,
  CircularProgress,
  Grid2,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import {
  useFetchSubscriptionInfoQuery,
  useUpdateSubsctiptionInfoMutation,
} from 'store/api/admin.api';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { DateInput } from 'components/DateInput';

const isoDateToString = (date?: string) =>
  dayjs(date).format('MMMM DD YYYY, hh:mm:ss').toString();

interface Props {
  profileId: number;
}

const PaymentSubscription = ({ profileId }: Props) => {
  const theme = useTheme();

  const [expiryDate, setExpiryDate] = useState<string | null>(null);

  const { data, isFetching } = useFetchSubscriptionInfoQuery({
    profileId,
  });
  const [updateSubscriptionInfo, { isLoading: isUpdateLoading }] =
    useUpdateSubsctiptionInfoMutation();

  useEffect(() => {
    if (data) {
      setExpiryDate(data.result.expires_date);
    }
  }, [data]);

  const handleUpdate = () => {
    if (data) {
      updateSubscriptionInfo({
        subscriptionId: data.result.id,
        expires_date: expiryDate || undefined,
      }).then((result) => {
        if ((result as Record<string, unknown>).error) {
          toast('There was an error. Please try again.', { type: 'error' });
          return;
        }

        toast('Subscription data saved succesfully!', { type: 'success' });
      });
    }
  };

  return !isFetching ? (
    data ? (
      <Grid2 container rowGap={1} alignItems="center">
        <Grid2 size={12}>
          <Typography variant="h6" my={1}>
            Payment Subscription
          </Typography>
        </Grid2>
        <Grid2 size={6}>Payer Account ID</Grid2>
        <Grid2 size={6}>{data.result.payer_account_id}</Grid2>
        <Grid2 size={6}>Billing Source</Grid2>
        <Grid2 size={6}>{data.result.billing_source}</Grid2>
        <Grid2 size={6}>Original Purchase Date</Grid2>
        <Grid2 size={6}>
          {isoDateToString(data.result.original_purchase_date)}
        </Grid2>
        <Grid2 size={6}>Expiry Date</Grid2>
        <Grid2 size={6}>
          <DateInput
            defaultValue={dayjs(data.result.expires_date)}
            onChange={(date) => setExpiryDate(date?.toISOString() || null)}
          />
        </Grid2>
        <Grid2 size={12}>
          <Button onClick={handleUpdate} disabled={isUpdateLoading}>
            Update
          </Button>
        </Grid2>
      </Grid2>
    ) : (
      <>
        <Typography variant="h6" my={1}>
          Payment Subscription
        </Typography>
        <Typography>No data for this profile.</Typography>
      </>
    )
  ) : (
    <CircularProgress size={theme.spacing(8)} sx={{ m: 4 }} />
  );
};

export default PaymentSubscription;
