import { GridActionsCellItem, GridColDef } from '@mui/x-data-grid-pro';
import { commonColumnProperties } from './records-columns/columns';
import { OrganizationMembership } from 'types/organizations';
import { Box, Button, MenuItem, Select } from '@mui/material';
import { Icon } from '@iconify/react';

const defaultRoles = ['admin', 'member'];

export const getAdminOrganizationMembersColumns = (
  onRoleChange: (role: string, id: number) => void,
  onProfileClick: (id: number) => void,
  onDeleteClick: (id: number, name: string) => void,
  roles: string[] = defaultRoles,
): GridColDef<OrganizationMembership>[] => [
  {
    ...commonColumnProperties,
    field: 'member_display_name',
    headerName: 'Name',
  },
  {
    ...commonColumnProperties,
    field: 'account_type',
    headerName: 'Type',
    renderCell: ({ row }) => (row.account_type === 'User' ? 'User' : 'Patient'),
  },
  {
    ...commonColumnProperties,
    field: 'role',
    headerName: 'Role',
    renderCell: ({ row }) => (
      <Select
        value={row.role}
        onChange={(e) => onRoleChange(e.target.value, row.id)}
        sx={{ minWidth: 120 }}
      >
        {roles.map((role) => (
          <MenuItem
            key={role}
            value={role}
            sx={{ textTransform: 'capitalize' }}
          >
            {role}
          </MenuItem>
        ))}
      </Select>
    ),
  },
  {
    ...commonColumnProperties,
    field: 'actions',
    type: 'actions',
    maxWidth: 120,
    getActions: ({ row }) => [
      <GridActionsCellItem
        size="large"
        sx={{
          color: 'primary.main',
          p: 0,
          svg: { height: '20px', width: '20px' },
          mr: 1,
        }}
        icon={<Icon icon="material-symbols:open-in-new" />}
        label="View Profile"
        onClick={() => onProfileClick(row.account_id)}
      />,
      <GridActionsCellItem
        size="large"
        sx={{
          color: 'error.main',
          p: 0,
          svg: { height: '20px', width: '20px' },
        }}
        icon={<Icon icon="material-symbols:delete-outline" />}
        label="Delete"
        onClick={() => onDeleteClick(row.id, row.member_display_name)}
      />,
    ],
  },
];
