import ReactDOM from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { Provider } from 'react-redux';
import { persistor, store } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import { CookiesProvider } from 'react-cookie';
import theme from './styles/theme';

import App from './App';

import './index.scss';
import { LicenseInfo } from '@mui/x-license';
import { StyledToastContainer } from 'styles/ToastContainer';
import { ConfirmationServiceProvider } from 'components/_extra/ConfirmationDialog/ConfirmationService';
import isAppleDevice from 'utils/is-apple-device';
import { register } from 'utils/service-worker';
import { StrictMode } from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';

declare global {
  interface Document {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    BCFcm: Record<string, any> | undefined;
  }
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

const isNotAnAppleDevice = !isAppleDevice();
if (isNotAnAppleDevice) {
  document.body.classList.add('custom-scroll');
}

LicenseInfo.setLicenseKey(import.meta.env.REACT_APP_MUI_KEY);

root.render(
  <StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ThemeProvider theme={theme}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="en">
            <ConfirmationServiceProvider>
              <CookiesProvider>
                <App />
                <StyledToastContainer closeOnClick position="bottom-right" />
              </CookiesProvider>
            </ConfirmationServiceProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </PersistGate>
    </Provider>
  </StrictMode>,
);

register();
