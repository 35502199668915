import { Box, CircularProgress, MenuItem, Paper, Select } from '@mui/material';
import { Table } from 'components/Table';
import { gridClasses } from '@mui/x-data-grid-pro';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { routes } from 'nav';
import {
  useDeleteMembershipMutation,
  useGetMembershipRolesQuery,
  useGetOrganizationsMembershipQuery,
  useGetOrganizationsQuery,
  useUpdateMebershipMutation,
} from 'store/api/organizations.api';
import { getOrganizationMembersColumns } from 'utils/organization-members-columns';
import { useAppSelector } from 'store';
import { useGetProfileQuery } from 'store/api/profiles.api';
import { useConfirmation } from 'hooks/use-confirmation';
import { toast } from 'react-toastify';
import { useUrlPagination } from 'hooks/use-url-pagination';
import { AddNewMembership } from './AddNewMembership';

export const OrganizationMemberships = () => {
  const navigate = useNavigate();
  const confirm = useConfirmation();

  const accountId = useAppSelector((state) => state.user.accountId);
  const { data: profileData } = useGetProfileQuery(
    {
      id: accountId as number,
    },
    {
      skip: !accountId,
    },
  );

  const { availablePageSizes, page, pageSize, setPage, setPageSize } =
    useUrlPagination();

  const { data: organizations, isLoading: areOrganizationsLoading } =
    useGetOrganizationsQuery();

  const organizationId = useMemo(
    () => organizations?.[0].id ?? 0,
    [organizations],
  );

  const [profileType, setProfileType] = useState<
    'user' | 'patient_profile' | 'all'
  >('all');

  const { data, isLoading } = useGetOrganizationsMembershipQuery(
    {
      id: organizationId,
      type: profileType === 'all' ? undefined : profileType,
      limit: pageSize,
      offset: (page - 1) * pageSize,
    },
    {
      skip: !organizationId,
    },
  );

  const { isLoading: areRolesLoading, data: roles } =
    useGetMembershipRolesQuery({
      organizationId,
    });

  const [update, { isLoading: isUpdating }] = useUpdateMebershipMutation();
  const [deleteMembership, { isLoading: isDeleting, isError }] =
    useDeleteMembershipMutation();

  const onRoleChange = (role: string, membershipId: number) => {
    update({ organizationId, membershipId, role });
  };

  const onProfileClick = (id: number) => {
    navigate(routes.private.dashboard.href(id));
  };

  const onDeleteClick = (membershipId: number, name: string) => {
    confirm(
      {
        variant: 'danger',
        title: 'Delete Membership',
        message: `Are you sure you want to delete ${name}'s membership?`,
        confirmButtonLabel: 'Delete',
        rejectButtonLabel: 'Cancel',
      },
      () => {
        deleteMembership({ organizationId, membershipId });
      },
    );
  };

  const columns = useMemo(() => {
    return getOrganizationMembersColumns(
      onRoleChange,
      onProfileClick,
      onDeleteClick,
      roles?.results,
      profileData?.result.can_configure_organization_devices,
    );
  }, [roles]);

  useEffect(() => {
    if (isError) {
      toast.error('Failed to delete membership');
    }
  }, [isError]);

  if (isLoading) {
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        py: 4,
      }}
    >
      <CircularProgress size={64} />
    </Box>;
  }

  return (
    <Paper>
      <Box pt={2} px={2} display="flex" flexDirection="column" gap={2}>
        <Box display="flex" justifyContent="space-between" alignItems="end">
          <Box
            display="flex"
            gap={1}
            flexDirection="column"
            maxWidth={({ spacing }) => spacing(30)}
          >
            <label>Profile Type</label>
            <Select
              value={profileType}
              onChange={(e) =>
                setProfileType(
                  e.target.value as 'user' | 'patient_profile' | 'all',
                )
              }
            >
              <MenuItem value="all">All</MenuItem>
              <MenuItem value="user">User</MenuItem>
              <MenuItem value="patient_profile">Patient Profile</MenuItem>
            </Select>
          </Box>
          {profileData?.result.can_configure_organization_devices && (
            <AddNewMembership organizationId={organizationId} />
          )}
        </Box>
        <Table
          sx={{
            [`.${gridClasses.cell}`]: {
              py: 0,
            },
          }}
          rowHeight={52}
          pagination
          paginationMode="server"
          paginationModel={{
            page: page - 1,
            pageSize,
          }}
          onPaginationModelChange={(newModel) => {
            setPage(newModel.page + 1);
            setPageSize(newModel.pageSize);
          }}
          rowCount={data?.totalRows ?? 0}
          columns={columns}
          rows={data?.results ?? []}
          pageSizeOptions={availablePageSizes}
          loading={
            areRolesLoading ||
            isUpdating ||
            isDeleting ||
            areOrganizationsLoading
          }
        />
      </Box>
    </Paper>
  );
};
