import { AxiosRequestConfig } from 'axios';
import { IAccountRequest, IAccountResponse } from 'models/account.model';
import {
  ConfigureManagedProfilePayload,
  IFindProfilesByEmail,
  IFindProfilesByEmailResponse,
  IFindProfilesById,
  IFindProfilesByIdResponse,
  IFindProfilesByPhone,
  IFindProfilesByPhoneResponse,
  ILookupAccountInfo,
  ILookupAccountInfoSave,
  IPaymentSubscriptionPutReq,
  IPaymentSubscriptionRes,
  ISearchByUpiRes,
  SearchDevicesParams,
} from 'models/admin.model';
import { IDashboardResponse, IDashboardResult } from 'models/dashboard.model';
import { bcApi } from './_api';
import withNotification from './_with-notification';
import { IGetCredentialsOptionsResponse } from 'models/auth.model';
import { Device } from 'types/device';
import { transformOrganizationResponse } from 'utils/data-transformation/organizations';
import {
  Organization,
  OrganizationMembership,
  OrganizationsResponse,
  RawOrganization,
  RawOrganizationAddress,
} from 'types/organizations';

export type TFetchRecordInfo =
  | {
      type: 'current';
      accountId: number;
    }
  | {
      type: 'forDate';
      accountId: number;
      date: string;
    }
  | {
      type: 'previous';
      currentId: number;
    }
  | {
      type: 'next';
      currentId: number;
    };

const generateRecordFetchParams = (
  data: TFetchRecordInfo,
): AxiosRequestConfig => {
  switch (data.type) {
    case 'current': {
      return {
        url: '/records/synthesized',
        method: 'GET',
        params: { account_id: data.accountId },
      };
    }
    case 'forDate': {
      return {
        url: '/records/synthesized/closest_to_date',
        method: 'GET',
        params: {
          account_id: data.accountId,
          closest_to_date: data.date,
        },
      };
    }
    case 'previous': {
      return {
        url: `records/synthesized/${data.currentId}/earlier`,
      };
    }
    case 'next': {
      return {
        url: `records/synthesized/${data.currentId}/later`,
      };
    }
  }
};

export const adminApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    findProfilesById: builder.mutation<
      IFindProfilesByIdResponse,
      IFindProfilesById
    >({
      query: (data) => ({
        url: `/accounts/${data.id}`,
        method: 'GET',
      }),
    }),
    findProfilesByEmail: builder.mutation<
      IFindProfilesByEmailResponse,
      IFindProfilesByEmail
    >({
      query: (data) => ({
        url: '/credentials/find',
        method: 'POST',
        data: {
          schema: 'email',
          identifier: data.email,
        },
      }),
    }),
    findProfilesByPhone: builder.mutation<
      IFindProfilesByPhoneResponse,
      IFindProfilesByPhone
    >({
      query: (data) => ({
        url: '/credentials/find',
        method: 'POST',
        data: {
          schema: 'email',
          identifier: data.phone,
        },
      }),
    }),
    fetchAccountInfo: builder.query<ILookupAccountInfo, { id: number }>({
      keepUnusedDataFor: 0,
      query: (data) => ({
        url: `/accounts/${data.id}`,
        method: 'GET',
      }),
      transformResponse: (response: IAccountResponse) => ({
        firstName: response.result.first_name,
        lastName: response.result.last_name,
        emergencyTracking: response.result.emergency_tracking,
        enhancedAlerts: response.result.enhanced_alerts,
        powerSavingMode: response.result.power_saving_mode,
        lastPollAt: response.result.last_poll_at,
        collectedHealthData: response.result.collects_health_data,
        lastIrregularHealthAck: response.result.last_irregular_heart_ack,
        lastHighHealthAck: response.result.last_high_heart_ack,
        lastLowHealthAck: response.result.last_low_heart_ack,
        lastFallAck: response.result.last_fall_ack,
        localReminderNotifications:
          response.result.local_reminder_notifications,
        watchChargingReminder: response.result.watch_charging_reminder,
        performanceMode: response.result.performance_mode,
        isLovedOne: response.result.is_loved_one,
        debugLogging: response.result.debug_logging,
        uniquePublicIdentifier: response.result.unique_public_identifier,
        thirdPartyPublicIdentifier:
          response.result.third_party_public_identifier,
        didCompleteManagedOnboarding:
          response.result.did_complete_managed_onboarding,
      }),
    }),
    saveAccountInfo: builder.mutation<unknown, ILookupAccountInfoSave>({
      query: (data) => ({
        url: `/accounts/${data.accountId}`,
        method: 'PUT',
        data: {
          first_name: data.firstName,
          last_name: data.lastName,
          enhanced_alerts: data.enhancedAlerts,
          power_saving_mode: data.powerSavingMode,
          collects_health_data: data.collectedHealthData,
          last_irregular_heart_ack: data.lastIrregularHealthAck,
          last_high_heart_ack: data.lastHighHealthAck,
          last_low_heart_ack: data.lastLowHealthAck,
          last_fall_ack: data.lastFallAck,
          local_reminder_notifications: data.localReminderNotifications,
          performance_mode: data.performanceMode,
          watch_charging_reminder: data.watchChargingReminder,
          debug_logging: data.debugLogging,
          did_complete_managed_onboarding: data.didCompleteManagedOnboarding,
          third_party_public_identifier: data.thirdPartyPublicIdentifier,
        },
      }),
    }),
    fetchRecordInfo: builder.query<IDashboardResult | null, TFetchRecordInfo>({
      keepUnusedDataFor: 0,
      query: (data) => generateRecordFetchParams(data),
      transformResponse: (response: IDashboardResponse) => response.result,
      async onQueryStarted(_arg, { dispatch, queryFulfilled }) {
        await withNotification({
          dispatch,
          queryFulfilled,
          errorMessage: 'Could not fetch the record.',
        });
      },
    }),
    fetchSubscriptionInfo: builder.query<
      IPaymentSubscriptionRes,
      { profileId: number }
    >({
      query: (data) => ({
        url: '/payment_subscriptions/find',
        params: { patient_profile_id: data.profileId },
      }),
    }),
    updateSubsctiptionInfo: builder.mutation<
      unknown,
      IPaymentSubscriptionPutReq
    >({
      query: ({ subscriptionId, expires_date }) => ({
        url: `/payment_subscriptions/${subscriptionId}`,
        method: 'PUT',
        data: { expires_date },
      }),
    }),
    pollAllDevices: builder.mutation<unknown, { accountId: number }>({
      query: ({ accountId }) => ({
        url: `/accounts/${accountId}/poll_all_devices`,
        method: 'PUT',
      }),
    }),
    getPasswordResetEmail: builder.query<string | null, { accountId: number }>({
      query: ({ accountId }) => ({
        url: '/credentials',
        params: { account_id: accountId },
      }),
      transformResponse: (response: IGetCredentialsOptionsResponse) =>
        response.results.find((item) => item.schema === 'email')?.identifier ||
        null,
    }),
    getCredentialsForAccount: builder.query<
      IGetCredentialsOptionsResponse,
      { accountId: number }
    >({
      query: ({ accountId }) => ({
        url: '/credentials',
        params: { account_id: accountId },
      }),
    }),
    updateCredential: builder.mutation<
      unknown,
      { credentialId: number; identifier: string }
    >({
      query: ({ credentialId, identifier }) => ({
        url: `/credentials/${credentialId}`,
        method: 'PUT',
        body: { identifier },
      }),
    }),
    addConfirmedCredential: builder.mutation<
      { result: { success: boolean } },
      { accountId: number; schema: string; identifier: string }
    >({
      query: ({ accountId, schema, identifier }) => ({
        url: '/credentials/add_confirmed_credential',
        method: 'POST',
        data: { account_id: accountId, schema, identifier, is_confirmed: true },
      }),
    }),
    searchByPublicIdentifier: builder.mutation<
      ISearchByUpiRes,
      { identifier: string }
    >({
      query: ({ identifier }) => ({
        url: `/accounts/search`,
        method: 'POST',
        data: { unique_public_identifier: identifier },
      }),
    }),
    createManagedAccount: builder.mutation<
      { result: { unique_public_identifier: string } },
      { payload: Record<string, unknown> }
    >({
      query: ({ payload }) => ({
        url: '/admin/create_managed_account',
        method: 'POST',
        data: payload,
      }),
    }),
    sendEquipmentDispatchEmail: builder.mutation<
      unknown,
      { payload: Record<string, unknown> }
    >({
      query: ({ payload }) => ({
        url: '/admin/send_equipment_dispatch_email',
        method: 'POST',
        data: payload,
      }),
    }),
    searchDevices: builder.mutation<{ results: Device[] }, SearchDevicesParams>(
      {
        query: (data) => ({
          url: '/devices/search',
          method: 'POST',
          data,
        }),
      },
    ),
    createManagedProfile: builder.mutation<
      { result: { unique_public_identifier: string } },
      {
        first_name?: string;
        last_name?: string;
        third_party_public_identifier?: string;
      }
    >({
      query: (payload) => ({
        url: '/patient_profiles/managed_configuration/create_managed_profile',
        method: 'POST',
        data: payload,
      }),
    }),
    getProfileToConfigure: builder.query<
      { result: Partial<IAccountRequest> },
      { identifier: string }
    >({
      query: ({ identifier }) => ({
        url: `/patient_profiles/managed_configuration/managed_profile_to_configure?unique_public_identifier=${identifier}`,
        method: 'GET',
      }),
    }),
    configureManagedProfile: builder.mutation<
      { result: { success: boolean } },
      ConfigureManagedProfilePayload
    >({
      query: (payload) => ({
        url: '/patient_profiles/managed_configuration/configure_managed_profile',
        method: 'POST',
        data: payload,
      }),
    }),
    endCurrentSessions: builder.mutation<
      { success: boolean },
      { accountId: number }
    >({
      query: ({ accountId }) => ({
        url: `accounts/${accountId}/expire_sessions`,
        method: 'POST',
      }),
    }),
    createOrganization: builder.mutation<
      { result: { id: number } },
      Omit<
        RawOrganization,
        'id' | 'patient_profile_member_ids' | 'user_member_ids' | 'address'
      > & {
        address: Partial<RawOrganizationAddress>;
      }
    >({
      query: ({
        address,
        name,
        requires_mfa,
        can_configure_devices,
        special_alerter_channels,
      }) => ({
        url: '/organizations',
        method: 'POST',
        data: {
          name,
          requires_mfa,
          can_configure_devices,
          address,
          special_alerter_channels,
        },
      }),
      invalidatesTags: ['AdminOrganizations'],
    }),
    getOrganizationsAdmin: builder.query<
      {
        results: Organization[];
        totalDisplayedRows: number;
        totalRows: number;
      },
      {
        limit?: number;
        offset?: number;
      }
    >({
      query: ({ limit, offset }) => ({
        url: `/organizations/list`,
        method: 'GET',
        params: {
          limit,
          offset,
        },
      }),
      providesTags: ['AdminOrganizations'],
      transformResponse: ({ results, meta }: OrganizationsResponse) => ({
        results: results.map((org) => transformOrganizationResponse(org)),
        totalDisplayedRows: meta.total_displayed_rows,
        totalRows: meta.total_rows,
      }),
    }),
    getOrganizationByIdAdmin: builder.query<Organization, { id: number }>({
      query: ({ id }) => ({
        url: `/organizations/${id}`,
        method: 'GET',
      }),
      transformResponse: ({ result }: { result: RawOrganization }) =>
        transformOrganizationResponse(result),
      providesTags: ['OrganizationDetails'],
    }),
    getOrganizationsMembershipsAdmin: builder.query<
      {
        results: OrganizationMembership[];
        totalDisplayedRows: number;
        totalRows: number;
      },
      {
        id: number;
        limit?: number;
        offset?: number;
        type?: string;
      }
    >({
      query: ({ id, limit, offset, type }) => ({
        url: `/organizations/${id}/memberships`,
        method: 'GET',
        params: {
          limit,
          offset,
          member_type: type,
        },
      }),
      transformResponse: (data: {
        results: OrganizationMembership[];
        meta: {
          total_displayed_rows: number;
          total_rows: number;
        };
      }) => {
        return {
          results: data.results,
          totalDisplayedRows: data.meta.total_displayed_rows,
          totalRows: data.meta.total_rows,
        };
      },
      providesTags: ['OrganizationMemberships'],
    }),
  }),
});

export const {
  useAddConfirmedCredentialMutation,
  useCreateManagedAccountMutation,
  useFetchAccountInfoQuery,
  useFetchRecordInfoQuery,
  useFetchSubscriptionInfoQuery,
  useFindProfilesByEmailMutation,
  useFindProfilesByIdMutation,
  useFindProfilesByPhoneMutation,
  useGetCredentialsForAccountQuery,
  useGetPasswordResetEmailQuery,
  useLazyGetCredentialsForAccountQuery,
  usePollAllDevicesMutation,
  useSaveAccountInfoMutation,
  useSearchByPublicIdentifierMutation,
  useSendEquipmentDispatchEmailMutation,
  useUpdateCredentialMutation,
  useUpdateSubsctiptionInfoMutation,
  useSearchDevicesMutation,
  useGetProfileToConfigureQuery,
  useLazyGetProfileToConfigureQuery,
  useCreateManagedProfileMutation,
  useConfigureManagedProfileMutation,
  useEndCurrentSessionsMutation,
  useGetOrganizationsAdminQuery,
  useGetOrganizationByIdAdminQuery,
  useGetOrganizationsMembershipsAdminQuery,
  useCreateOrganizationMutation,
} = adminApi;
