import { routes } from 'nav';
import { useCallback, useMemo } from 'react';
import { useAppSelector } from 'store';
import { useGetOrganizationReportQuery } from 'store/api/organizations.api';
import {
  useGetAvailableReportsQuery,
  useGetReportsQuery,
} from 'store/api/pop-ov.api';
import { useGetLinkedProfilesQuery } from 'store/api/profiles.api';
import { AlerterEventsWidgets } from 'types/alerters';
import { DateRange } from 'types/date-time';
import { ProfileReportsIdentifier } from 'types/patient';
import { getFrom, getTo } from 'utils/datetime-tools';
import { getGroupReportsWidgets } from 'utils/get-group-reports-widgets';

const alerterEvents: AlerterEventsWidgets[] = [
  'safe_zone',
  'help_requested',
  'fall',
  'motion_disorder',
];

const getCustomFieldValue = (field: string) => {
  if (field === 'limit') {
    return 5;
  }

  if (field === 'widget_targets') return alerterEvents;
};

export const useOrganizationReports = (
  organizationId: number,
  dateRange: DateRange,
) => {
  const { data, isFetching: isLoading } = useGetAvailableReportsQuery({
    from: getFrom(dateRange[0]),
    to: getTo(dateRange[1]),
  });

  const statsList = useMemo(() => {
    if (!data) return [];

    return data.results.reduce((acc, item) => {
      if (typeof item === 'string') {
        return [...acc, item];
      }

      let newElement: Record<string, unknown> = {};

      Object.entries(item).map(([key, value]) => {
        if (Array.isArray(value)) {
          newElement = {
            ...newElement,
            [key]: {
              [value[0]]: getCustomFieldValue(value[0]),
            },
          };
        }
      });

      return [...acc, newElement];
    }, [] as ProfileReportsIdentifier[]);
  }, [data]);

  const { data: reports, isFetching } = useGetOrganizationReportQuery(
    {
      from: getFrom(dateRange[0]),
      to: getTo(dateRange[1]),
      statsList,
      limit: 5,
      organizationId,
    },
    {
      skip: statsList.length === 0 || !organizationId,
      refetchOnMountOrArgChange: false,
    },
  );

  const getReportsUrl = useCallback((id: number) => {
    return routes.private.profileReports.href(id);
  }, []);

  return {
    data: reports,
    widgets: getGroupReportsWidgets(reports, getReportsUrl),
    isLoading: isLoading || isFetching,
  };
};
