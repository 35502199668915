import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import {
  useUpdateOrganizationAddressMutation,
  useUpdateOrganizationsSettingsMutation,
} from 'store/api/organizations.api';
import { Organization } from 'types/organizations';

export const useOrganizationSettings = (organization: Organization) => {
  const [
    updateAddress,
    { isLoading, data: updatedData, isSuccess: isAddressUpdated },
  ] = useUpdateOrganizationAddressMutation();
  const [updateSettings, { data: updatedSettings, isSuccess }] =
    useUpdateOrganizationsSettingsMutation();

  const methods = useForm<Organization>({
    defaultValues: organization,
  });

  const onSubmit = (data: Organization) => {
    if (
      data.address &&
      Object.values(data.address).filter(
        (value) => value !== null || value?.length !== 0,
      ).length > 0
    ) {
      updateAddress({ ...data.address, id: organization.id });
    }
    updateSettings({
      id: organization.id,
      name: data.name,
      requiresMfa: data.requiresMfa,
      nossActive: data.specialAlerterChannels.nossXml.active,
    });
  };

  const resetOrganizationForm = () => {
    methods.reset();
  };

  useEffect(() => {
    if (isSuccess) {
      methods.reset(updatedSettings);
      return;
    }

    if (isAddressUpdated) {
      methods.reset({
        ...methods.getValues(),
        address: updatedData.address,
      });
    }
  }, [isAddressUpdated, isSuccess]);

  return {
    methods,
    handleSubmit: methods.handleSubmit(onSubmit),
    isLoading,
    isDirty: methods.formState.isDirty,
    resetOrganizationForm,
  };
};
