import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SettingsForm } from 'pages/AlerterDetails/hooks/use-settings';
import { toast } from 'react-toastify';
import { alertersApi } from 'store/api/alerters.api';
import {
  AlerterEventsWidgets,
  AlerterRecipientManager,
  ChannelSet,
  CooldownType,
  MetricRule,
  SerializedSchedule,
} from 'types/alerters';
import {
  getRulesFromRawAlerterTrigger,
  transformChannelSet,
  transformRuleFromRaw,
} from 'utils/data-transformation';

export type MetricData = Pick<
  MetricRule,
  'comparator' | 'metricType' | 'value'
> & {
  baseline?: number;
};

export type CombinedRecipientManager = AlerterRecipientManager &
  SerializedSchedule & {
    isOutsideRange: boolean;
    schedulerId: number;
    recipientsCount?: number;
    patientsCount?: number;
  };

interface AlerterState {
  trigger: {
    id: number;
    rules: (MetricRule & { title?: string })[];
    editedRule?: {
      id: number;
      newRule: MetricData;
      originalRule: MetricData;
    };
    emptySafeZones?: boolean;
  };
  activeRecipientSets?: number[];
  editedAlerter: {
    editedRecipientSetId: number;
    isModifiedSettings: boolean;
  };
  isModifiedRecipientSet: boolean;
  settings: {
    id?: number;
    title: string | null;
    // source: SourceStreamType;
    channels: ChannelSet[];
    channelSetId?: number;
    hasCustomMessage: boolean;
    generatedMessage: string;
    customMessage?: string | null;
    hasCustomTargetWidget?: boolean;
    targetWidget?: AlerterEventsWidgets;
    defaultTargetWidget?: AlerterEventsWidgets;
    cooldownType?: CooldownType;
    cooldownTime?: number | null;
    messageImageUrl: string | null;
    requiresAcknowledgement: boolean;
    forcedRequiresAcknowledgement: boolean;
  };
}

const initialState: AlerterState = {
  trigger: {
    id: 0,
    editedRule: undefined,
    rules: [],
  },
  isModifiedRecipientSet: false,
  editedAlerter: {
    editedRecipientSetId: 0,
    isModifiedSettings: false,
  },
  settings: {
    title: null,
    // source: 'any_device',
    channels: [],
    hasCustomMessage: false,
    generatedMessage: '',
    messageImageUrl: null,
    requiresAcknowledgement: false,
    forcedRequiresAcknowledgement: false,
  },
};

export const AlerterSlice = createSlice({
  name: 'alerter',
  initialState,
  reducers: {
    editRule(state, action: PayloadAction<number>) {
      const ruleToEdit = state.trigger.rules.find(
        (rule) => rule.id === action.payload,
      );

      if (typeof ruleToEdit === 'undefined') {
        toast('An error occured. Please refresh the page and try again.', {
          type: 'error',
        });
        return;
      }

      state.trigger.editedRule = {
        ...ruleToEdit,
        id: action.payload,
        originalRule: {
          comparator: ruleToEdit.comparator,
          metricType: ruleToEdit.metricType,
          value: ruleToEdit.value,
        },
        newRule: {
          comparator: ruleToEdit.comparator,
          metricType: ruleToEdit.metricType,
          value: ruleToEdit.value,
        },
      };
    },
    setEmptyZonesFlag(state) {
      state.trigger.emptySafeZones = true;
    },
    updateEditedRule(state, action: PayloadAction<MetricData>) {
      if (state.trigger.editedRule) {
        state.trigger.editedRule.newRule = action.payload;
        state.trigger.emptySafeZones = false;
      }
    },
    cancelEditing(state) {
      state.trigger.editedRule = undefined;
      state.trigger.emptySafeZones = false;
    },
    discardTriggerId(state) {
      state.trigger.id = 0;
    },
    enableRule(state, action: PayloadAction<number>) {
      const index = state.trigger.rules.findIndex(
        (rule) => rule.id === action.payload,
      );
      state.trigger.rules[index] = {
        ...state.trigger.rules[index],
        enabled: true,
      };
    },
    resetEditedRule(state, action: PayloadAction<number>) {
      const editedRule = state.trigger.editedRule;
      state.trigger.emptySafeZones = false;
      const index = state.trigger.rules.findIndex(
        (rule) => rule.id === action.payload,
      );
      if (index !== -1 && editedRule) {
        state.trigger.rules[index] = {
          ...state.trigger.rules[index],
          ...editedRule.originalRule,
        };
        state.trigger.editedRule = undefined;
        return;
      }
      toast.error('An error occured.');
    },
    disableRule(state, action: PayloadAction<number>) {
      const index = state.trigger.rules.findIndex(
        (rule) => rule.id === action.payload,
      );
      state.trigger.rules[index] = {
        ...state.trigger.rules[index],
        enabled: false,
      };
    },
    removeRule(state, action: PayloadAction<number>) {
      state.trigger.rules = state.trigger.rules.filter(
        (rule) => rule.id !== action.payload,
      );
    },
    setMessageType(state, action: PayloadAction<'custom' | 'generated'>) {
      state.settings.hasCustomMessage = action.payload === 'custom';
    },
    addChannel(state, action: PayloadAction<ChannelSet>) {
      state.settings.channels.push(action.payload);
    },
    removeChannel(state, action: PayloadAction<ChannelSet>) {
      state.settings.channels = state.settings.channels.filter(
        (channel) => channel !== action.payload,
      );
    },
    setRecipientSetAsModified(state) {
      state.isModifiedRecipientSet = true;
    },
    setRecipientSetAsUntouched(state) {
      state.isModifiedRecipientSet = false;
    },
    setSettingsAsModified(state) {
      state.editedAlerter.isModifiedSettings = true;
    },
    setSettingsAsUntouched(state) {
      state.editedAlerter.isModifiedSettings = false;
    },
    updateSettingsState(state, action: PayloadAction<SettingsForm>) {
      state.settings = {
        ...state.settings,
        ...action.payload,
        targetWidget:
          action.payload.targetWidget || state.settings.targetWidget,
        hasCustomTargetWidget: action.payload.targetWidgetType === 'custom',
      };
    },
    updateActiveRecipientSets(state, action: PayloadAction<number[]>) {
      state.activeRecipientSets = action.payload;
    },
    resetState(state) {
      Object.assign(state, initialState);
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(
        alertersApi.endpoints.getAlerterInfo.matchFulfilled,
        (state, action) => {
          const result = action.payload.result;
          state.trigger.id = result.alerter_trigger.id;
          state.settings.id = result.id;
          state.settings.title = result.title;
          state.settings.hasCustomMessage = result.use_custom_message_template;
          state.settings.customMessage = result.custom_message_template;
          state.settings.channels = transformChannelSet(
            result.alerter_channel_set.channels,
          );
          state.settings.channelSetId = result.alerter_channel_set.id;
          state.settings.generatedMessage = result.generated_message_template;
          state.activeRecipientSets = result.alerter_recipient_managers.map(
            ({ id }) => id,
          );
          state.settings.defaultTargetWidget =
            result.generated_widget_targets?.[0];
          state.settings.targetWidget =
            result.custom_widget_targets?.[0] ??
            result.generated_widget_targets?.[0];
          state.settings.hasCustomTargetWidget = !!result.custom_widget_targets;
          state.settings.cooldownType = result.alerter_trigger.cooldown_type;
          state.settings.cooldownTime = result.alerter_trigger.cooldown_time;
          state.settings.messageImageUrl = result.message_image_url;
          state.settings.requiresAcknowledgement =
            result.requires_acknowledgement;
          state.settings.forcedRequiresAcknowledgement =
            result.forced_requires_acknowledgement;
        },
      )
      .addMatcher(
        alertersApi.endpoints.getAlerterTrigger.matchFulfilled,
        (state, action) => {
          state.trigger.rules = getRulesFromRawAlerterTrigger(
            action.payload.result,
          );
        },
      )
      .addMatcher(
        alertersApi.endpoints.updateRuleInAlerterTrigger.matchFulfilled,
        (state, action) => {
          const index = state.trigger.rules.findIndex(
            (rule) => rule.id === action.payload.result.id,
          );
          state.trigger.rules[index] = transformRuleFromRaw(
            action.payload.result,
          );
        },
      )
      .addMatcher(
        alertersApi.endpoints.addRuleToAlerterTrigger.matchFulfilled,
        (state, action) => {
          state.trigger.emptySafeZones = false;
          state.trigger.rules = action.payload.result.metric_rules.map((rule) =>
            transformRuleFromRaw(rule),
          );
        },
      );
  },
});

export default AlerterSlice.reducer;
export const {
  editRule,
  cancelEditing,
  updateEditedRule,
  removeRule,
  enableRule,
  disableRule,
  setMessageType,
  addChannel,
  removeChannel,
  setRecipientSetAsModified,
  setRecipientSetAsUntouched,
  setSettingsAsModified,
  setSettingsAsUntouched,
  resetEditedRule,
  updateSettingsState,
  setEmptyZonesFlag,
  discardTriggerId,
  resetState,
  updateActiveRecipientSets,
} = AlerterSlice.actions;
