import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Modal,
  ModalProps,
  Paper,
  Link as MuiLink,
  useTheme,
  Typography,
} from '@mui/material';
import { CompletionMessage } from 'components/CompletionMessage';
import VInput from 'components/VInput';
import { publicUrls } from 'nav';
import { useAddProfile } from 'pages/PatientsOverview/hooks/use-add-profile';
import { Link } from 'react-router-dom';
import ModalLayout from './ModalLayout';

interface Props extends Omit<ModalProps, 'children'> {
  onClose: () => void;
  isLoading?: boolean;
}
const AddProfileModal = ({ isLoading, ...modalProps }: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    isSuccess,
    hasName,
    onCompletion,
    isLoading: isAddProfileLoading,
  } = useAddProfile(modalProps.onClose);
  const { palette, spacing } = useTheme();

  return (
    <Modal {...modalProps}>
      <DialogContent sx={{ display: 'flex', justifyContent: 'center' }}>
        {isSuccess || isLoading || isAddProfileLoading ? (
          <Paper
            sx={{
              maxWidth: spacing(64),
              width: '90%',
              minHeight: spacing(58),
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {isSuccess ? (
              <CompletionMessage
                onButtonClick={onCompletion}
                title="Profile was added successfully!"
                status="success"
                subtitle={
                  <>
                    Please ensure that you handle any sensitive and confidential
                    information in accordance with the applicable{' '}
                    <Link
                      to={publicUrls.privacyPolicy}
                      target="_blank"
                      style={{ color: palette.red.main }}
                    >
                      privacy policy
                    </Link>
                    .
                  </>
                }
                buttonLabel="Got It!"
              />
            ) : (
              <CircularProgress size="56px" color="secondary" />
            )}
          </Paper>
        ) : (
          <ModalLayout onClose={modalProps.onClose}>
            <form onSubmit={handleSubmit}>
              {!hasName && (
                <VInput
                  size="large"
                  placeholder="Enter your first name"
                  label="First Name"
                  error={!!errors.firstName}
                  helperText={errors.firstName?.message}
                  {...register('firstName')}
                />
              )}
              <VInput
                size="large"
                placeholder="Enter your invitation code"
                label="Code"
                error={!!errors.code}
                helperText={errors.code?.message}
                blueHelper={errors.code?.type === 'request'}
                {...register('code')}
              />
              <Box m={1} mt={3}>
                <Button size="large" type="submit" fullWidth>
                  Submit
                </Button>
              </Box>
            </form>
          </ModalLayout>
        )}
      </DialogContent>
    </Modal>
  );
};

export default AddProfileModal;
