import { Box, Typography } from '@mui/material';
import VInput from 'components/VInput';
import { useFormContext } from 'react-hook-form';
import { Organization } from 'types/organizations';

interface Props {
  disabled?: boolean;
  isAdmin?: boolean;
}

const General = ({ disabled, isAdmin }: Props) => {
  const { register } = useFormContext<Organization>();

  return (
    <div>
      <Typography color="primary" fontWeight={600}>
        General
      </Typography>
      <Box
        display="flex"
        flexDirection="column"
        gap={1.5}
        px={{ xxs: 1, newMd: 2 }}
        py={2}
        maxWidth={({ spacing }) => spacing(67)}
      >
        <VInput
          label="Organization name"
          containerSx={{ p: 0 }}
          placeholder="Enter organization name"
          size="medium"
          disabled={disabled}
          {...register('name')}
        />
        <VInput
          label="Email"
          containerSx={{ p: 0 }}
          placeholder="example@mail.com"
          size="medium"
          readOnly={!isAdmin}
        />
        <VInput
          label="Address line 1"
          containerSx={{ p: 0 }}
          placeholder="456 Park Avenue"
          size="medium"
          disabled={disabled}
          {...register('address.addressLine1')}
        />
        <VInput
          label="Address line 2"
          containerSx={{ p: 0 }}
          size="medium"
          disabled={disabled}
          {...register('address.addressLine2')}
        />
        <VInput
          label="City"
          containerSx={{ p: 0 }}
          placeholder="New York"
          size="medium"
          disabled={disabled}
          {...register('address.city')}
        />
        <Box display="flex" columnGap={2.5} rowGap={1.5} flexWrap="wrap">
          <VInput
            label="State/Province"
            containerSx={{
              p: 0,
              flexGrow: 1,
              minWidth: ({ spacing }) => spacing(20),
            }}
            placeholder="New York"
            size="medium"
            disabled={disabled}
            {...register('address.stateOrProvince')}
          />
          <VInput
            label="Zip/Postal code"
            containerSx={{ p: 0, flexBasis: 0, flexGrow: 1 }}
            placeholder="10022"
            size="medium"
            disabled={disabled}
            {...register('address.postalCode')}
          />
        </Box>
        <VInput
          label="Country"
          containerSx={{ p: 0 }}
          placeholder="United States"
          size="medium"
          disabled={disabled}
          {...register('address.country')}
        />
      </Box>
    </div>
  );
};

export default General;
