import { Reports } from 'components/_extra/Reports';
import { useState } from 'react';
import { useOrganizationReports } from '../hooks/use-organization-reports';
import { DateRange } from 'types/date-time';
import dayjs from 'dayjs';
import { useParams } from 'react-router-dom';
import { Box } from '@mui/material';

export const OrganizationReports = () => {
  const { id } = useParams<{ id: string }>();

  const [dateRange, setDateRange] = useState<DateRange>([
    dayjs().subtract(7, 'days'),
    dayjs().subtract(1, 'day'),
  ]);

  const { isLoading, widgets } = useOrganizationReports(Number(id), dateRange);

  return (
    <Box p={2}>
      <Reports
        dateRange={dateRange}
        onDateRangeChange={setDateRange}
        widgets={widgets}
        isLoading={isLoading}
      />
    </Box>
  );
};
