import { GetGroupReportsResponse } from 'models/profiles.model';
import {
  reportsColors as colors,
  ReportWidget,
  ReportWidgetBodyItem,
  secondsToString,
} from './get-reports-widgets';

export type GroupReportWidget = Omit<ReportWidget, 'body'> & {
  body: ReportWidgetBodyItem[];
};

const getIntegerPercentage = (
  numerator: number | null,
  denominator: number | null,
) => {
  if (typeof numerator !== 'number' || !denominator) return '—';

  return Math.round((numerator / denominator) * 100) + '%';
};

const orderedColors = [
  colors.purple,
  colors.yellow,
  colors.green,
  colors.red,
  colors.darkBlue,
];

export const getGroupReportsWidgets = (
  response: GetGroupReportsResponse | undefined,
  getLink: (id: number) => string,
): GroupReportWidget[] => {
  if (!response) return [];

  const { results, meta } = response;
  const names = meta.patient_profiles;

  const widgets: GroupReportWidget[] = [];

  if (results.seconds_spent_connected && results.seconds_spent_disconnected) {
    widgets.push({
      title: 'Online Status',
      body: [
        {
          type: 'concurrent',
          items: [
            {
              name: 'Connected',
              value: results.seconds_spent_connected.average_seconds,
              color: colors.green,
            },
            {
              name: 'Disconnected',
              value: results.seconds_spent_disconnected.average_seconds,
              color: colors.red,
            },
          ],
        },
        {
          type: 'list-clickable',
          title: 'Top Disconnected Devices',
          items: results.seconds_spent_disconnected.top_accounts.map(
            (account, index) => {
              return {
                name: names[account.account_id],
                value: getIntegerPercentage(account.sum, account.base_value),
                link: getLink(account.account_id),
                color: orderedColors[index],
              };
            },
          ),
        },
      ],
    });
  }

  if (results.seconds_spent_misconfigured && results.seconds_spent_configured) {
    widgets.push({
      title: 'Misconfigured Devices',
      body: [
        {
          type: 'concurrent',
          items: [
            {
              name: 'Configured',
              value: results.seconds_spent_configured.average_seconds,
              color: colors.green,
            },
            {
              name: 'Misconfigured',
              value: results.seconds_spent_misconfigured.average_seconds,
              color: colors.red,
            },
          ],
        },
        {
          type: 'list-clickable',
          title: 'Top Misconfigured Devices',
          items: results.seconds_spent_misconfigured.top_accounts.map(
            (account, index) => {
              return {
                name: names[account.account_id],
                value: getIntegerPercentage(account.sum, account.base_value),
                link: getLink(account.account_id),
                color: orderedColors[index],
              };
            },
          ),
        },
      ],
    });
  }

  if (
    results.records_off_charger_count &&
    results.records_off_charger_with_an_issue_count
  ) {
    widgets.push({
      title: 'Data Integrity',
      body: [
        {
          type: 'concurrent',
          items: [
            {
              name: 'Complete',
              value: results.records_off_charger_count.total_count,
              color: colors.green,
            },
            {
              name: 'Incomplete',
              value:
                results.records_off_charger_with_an_issue_count.total_count,
              color: colors.red,
            },
          ],
        },
        {
          type: 'list-clickable-multiple',
          title: 'Data Integrity Issues',
          groups: [
            {
              name: 'Missing Location Data',
              items:
                results.records_unexpectedly_lacking_location_count.top_accounts.map(
                  (account, index) => {
                    return {
                      name: names[account.account_id],
                      value: getIntegerPercentage(
                        account.count,
                        account.base_value,
                      ),
                      link: getLink(account.account_id),
                      color: orderedColors[index],
                    };
                  },
                ),
            },
            {
              name: 'Missing Health Data',
              items:
                results.records_unexpectedly_lacking_health_count.top_accounts.map(
                  (account, index) => {
                    return {
                      name: names[account.account_id],
                      value: getIntegerPercentage(
                        account.count,
                        account.base_value,
                      ),
                      link: getLink(account.account_id),
                      color: orderedColors[index],
                    };
                  },
                ),
            },
            {
              name: 'Posting Delays',
              items:
                results.records_with_delayed_posting_count.top_accounts.map(
                  (account, index) => {
                    return {
                      name: names[account.account_id],
                      value: getIntegerPercentage(
                        account.count,
                        account.base_value,
                      ),
                      link: getLink(account.account_id),
                      color: orderedColors[index],
                    };
                  },
                ),
            },
            {
              name: 'Location Delays',
              items:
                results.records_with_delayed_location_data_count.top_accounts.map(
                  (account, index) => {
                    return {
                      name: names[account.account_id],
                      value: getIntegerPercentage(
                        account.count,
                        account.base_value,
                      ),
                      link: getLink(account.account_id),
                      color: orderedColors[index],
                    };
                  },
                ),
            },
            {
              name: 'Heart Rate Delays',
              items:
                results.records_with_delayed_heart_rate_data_count.top_accounts.map(
                  (account, index) => {
                    return {
                      name: names[account.account_id],
                      value: getIntegerPercentage(
                        account.count,
                        account.base_value,
                      ),
                      link: getLink(account.account_id),
                      color: orderedColors[index],
                    };
                  },
                ),
            },
          ],
        },
      ],
    });
  }

  if (results.seconds_spent_used && results.seconds_spent_unused) {
    widgets.push({
      title: 'Watch Usage',
      body: [
        {
          type: 'concurrent',
          items: [
            {
              name: 'Used',
              value: results.seconds_spent_used.average_seconds,
              color: colors.green,
            },
            {
              name: 'Not Used',
              value: results.seconds_spent_unused.average_seconds,
              color: colors.red,
            },
          ],
        },
        {
          type: 'list-clickable-multiple',
          title: 'Time Spent Unused',
          groups: [
            {
              name: 'Disconnected/Other',
              items:
                results.seconds_spent_not_reporting_excluding_discharge.top_accounts.map(
                  (account, index) => {
                    return {
                      name: names[account.account_id],
                      value: getIntegerPercentage(
                        account.sum,
                        account.base_value,
                      ),
                      link: getLink(account.account_id),
                      color: orderedColors[index],
                    };
                  },
                ),
            },
            {
              name: 'Lost Power',
              items:
                results.seconds_spent_disconnected_after_discharge.top_accounts.map(
                  (account, index) => {
                    return {
                      name: names[account.account_id],
                      value: getIntegerPercentage(
                        account.sum,
                        account.base_value,
                      ),
                      link: getLink(account.account_id),
                      color: orderedColors[index],
                    };
                  },
                ),
            },
            {
              name: 'On Charger',
              items: results.seconds_spent_on_charger.top_accounts.map(
                (account, index) => {
                  return {
                    name: names[account.account_id],
                    value: getIntegerPercentage(
                      account.sum,
                      account.base_value,
                    ),
                    link: getLink(account.account_id),
                    color: orderedColors[index],
                  };
                },
              ),
            },
          ],
        },
      ],
    });
  }

  if (results.average_projected_battery_life_in_seconds) {
    widgets.push({
      title: 'Battery Power Usage',
      body: [
        {
          type: 'simple',
          title: 'Average Battery Life',
          value: secondsToString(
            results.average_projected_battery_life_in_seconds.average_seconds,
          ),
        },
        {
          type: 'list-clickable',
          title: 'Average Battery Life by Device',
          items:
            results.average_projected_battery_life_in_seconds.top_accounts.map(
              (account, index) => {
                return {
                  name: names[account.account_id],
                  value: secondsToString(account.avg, '—'),
                  link: getLink(account.account_id),
                  color: orderedColors[index],
                };
              },
            ),
        },
      ],
    });
  }

  if (
    results.alerter_events_pending_required_acknowledgement_count &&
    results.acknowledged_alerter_events_count.total_count
  ) {
    widgets.push({
      title: 'Alerter Events Acknowledgement',
      body: [
        {
          type: 'concurrent',
          items: [
            {
              color: colors.green,
              name: 'Acknowledged',
              value: results.acknowledged_alerter_events_count.total_count,
            },
            {
              color: colors.red,
              name: 'Pending Acknowledgement',
              value:
                results.alerter_events_pending_required_acknowledgement_count
                  .total_count,
            },
          ],
        },
        {
          type: 'list-clickable',
          title: 'Patients with Pending Alerts',
          items:
            results.alerter_events_pending_required_acknowledgement_count.top_accounts.map(
              (account, index) => {
                return {
                  name: names[account.account_id],
                  value: getIntegerPercentage(
                    account.count,
                    account.base_value,
                  ),
                  link: getLink(account.account_id),
                  color: orderedColors[index],
                };
              },
            ),
        },
      ],
    });
  }

  if (results.average_seconds_to_acknowledge_alerter_event) {
    widgets.push({
      title: 'Time to Acknowledge',
      body: [
        {
          title: 'Average Time to Acknowledge',
          type: 'simple',
          value: secondsToString(
            results.average_seconds_to_acknowledge_alerter_event
              .average_seconds,
          ),
        },
        {
          title: 'Average Time to Acknowledge',
          type: 'list-clickable',
          items:
            results.average_seconds_to_acknowledge_alerter_event.top_accounts.map(
              (account, index) => {
                return {
                  name: names[account.account_id],
                  value: secondsToString(account.avg, '—'),
                  link: getLink(account.account_id),
                  color: orderedColors[index],
                };
              },
            ),
        },
      ],
    });
  }

  if (results.alerter_events_count) {
    widgets.push({
      title: 'Detection Analysis',
      body: [
        {
          type: 'simple',
          title: 'High-Priority Events Detected',
          value: results.alerter_events_count.total_count.toString(),
        },
        {
          type: 'list-clickable',
          title: 'High-Priority Events Detected by Device',
          items: results.alerter_events_count.top_accounts.map(
            (account, index) => {
              return {
                name: names[account.account_id],
                value:
                  typeof account.count === 'number'
                    ? account.count.toString()
                    : '—',
                link: getLink(account.account_id),
                color: orderedColors[index],
              };
            },
          ),
        },
      ],
    });
  }

  return widgets;
};
