import { Day } from './weekdays';

const MIN = 0;
const MAX = 86400;
const OFFSET = new Date().getTimezoneOffset() * 60;

export const getUTCValue = (value: number) => {
  return value + OFFSET;
};

export const getLocalValue = (value: number) => {
  const localValue = value - OFFSET;

  if (localValue >= MAX) {
    return localValue - MAX;
  }

  if (localValue < MIN) {
    return MAX + localValue;
  }

  return localValue;
};

export const getUTCDays = (time: number, days: Day[]) => {
  return {
    start_time: getUTCValue(time),
    days_of_week: days,
  };
};

export const getLocalDays = (
  time: number,
  days: Day[],
): { startTime: number; days: Day[] } => {
  return {
    startTime: getLocalValue(time),
    days,
  };
};

export const getUTCInterval = (
  startTime: number,
  endTime: number,
  days: Day[],
) => {
  return {
    start_time: getUTCValue(startTime),
    end_time: getUTCValue(endTime),
    days_of_week: days,
  };
};

export const getLocalInterval = (
  startTime: number | null,
  endTime: number | null,
  days: Day[],
): { startTime: number; endTime: number; days: Day[] } => {
  return {
    startTime: startTime === null ? 0 : getLocalValue(startTime),
    endTime: endTime === null ? 86400 : getLocalValue(endTime),
    days,
  };
};
