import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  CircularProgress,
  Modal,
  ModalProps,
  Paper,
  SxProps,
  Theme,
  Tooltip,
  useTheme,
} from '@mui/material';
import { MouseEventHandler, ReactNode } from 'react';

interface Props extends Omit<ModalProps, 'children'> {
  maxWidth?: string;
  minHeight?: string;
  isLoading?: boolean;
  p?: string | number;
  hideCloseButton?: boolean;
  children: ReactNode;
  innerSx?: SxProps<Theme>;
}

const BasicModal = ({
  maxWidth = '512px',
  minHeight,
  isLoading,
  p = 4,
  hideCloseButton,
  innerSx,
  ...modalProps
}: Props) => {
  const { palette } = useTheme();

  return (
    <Modal tabIndex={-1} {...modalProps}>
      <Paper
        id="basic-modal"
        sx={{
          p,
          maxWidth,
          minHeight,
          width: '90%',
          position: 'relative',
          maxHeight: '95dvh',
          overflowY: 'auto',
          borderRadius: '8px',
          ...innerSx,
        }}
      >
        {!hideCloseButton && (
          <Tooltip title="Close">
            <Button
              onClick={modalProps.onClose as MouseEventHandler}
              variant="icon-text"
              size="small"
              sx={{
                position: 'absolute',
                color: palette.blue.light,
                right: 10,
                top: 10,
                ':hover': {
                  color: palette.blue.dark,
                },
              }}
            >
              <Icon icon="material-symbols:close" />
            </Button>
          </Tooltip>
        )}
        {isLoading ? (
          <Box
            display="flex"
            justifyContent="center"
            height={1}
            alignItems="center"
          >
            <CircularProgress size="56px" thickness={4} color="secondary" />
          </Box>
        ) : (
          <>{modalProps.children}</>
        )}
      </Paper>
    </Modal>
  );
};

export default BasicModal;
