import { Box, SxProps, Typography, useTheme } from '@mui/material';
import { formatTimeFrom } from 'utils/time-format';
import { ContentWrapper, Paper, Skeleton } from './WidgetBase.styled';

export interface WidgetBaseProps {
  title?: string;
  fullWidth?: boolean;
  children?: React.ReactNode;
  lastUpdate?: string | number;
  headerContent?: React.ReactNode;
  padding?: string;
  isLoading?: boolean;
  sx?: SxProps;
  skeletonSx?: SxProps;
  unlockedHeight?: boolean;
}

const WidgetBase = ({
  fullWidth,
  title,
  children,
  lastUpdate,
  headerContent,
  padding,
  isLoading,
  unlockedHeight,
  sx = {},
  skeletonSx = {},
}: WidgetBaseProps) => {
  const { spacing, palette, transitions } = useTheme();

  const rootSx = {
    width: fullWidth ? '100%' : 'auto',
    transition: `box-shadow ${transitions.duration.short}`,
  };

  if (isLoading) {
    return <Skeleton variant="rectangular" sx={skeletonSx} />;
  }
  return (
    <Paper
      elevation={2}
      sx={{
        ...rootSx,
        ...sx,
      }}
      unlockedHeight={unlockedHeight}
    >
      {title && (
        <Box display="flex">
          <Typography flexGrow={1} variant="subtitle2">
            {title}
          </Typography>
          <>{headerContent}</>
        </Box>
      )}
      <ContentWrapper
        padding={padding || spacing(0)}
        unlockedHeight={unlockedHeight}
      >
        <>{children}</>
      </ContentWrapper>
      <Typography
        display="block"
        variant="caption"
        textAlign="right"
        color={palette.blue.main}
      >
        {lastUpdate && <>Last update: {formatTimeFrom(lastUpdate)}</>}
      </Typography>
    </Paper>
  );
};

export default WidgetBase;
