import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Paper,
  TextFieldProps,
  Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react';
import { DateRange } from 'types/date-time';
import RangeInput from './RangeInput';

const RangeShortcut = {
  today: 'TODAY',
  last3Days: 'LAST_3_DAYS',
  last7Days: 'LAST_7_DAYS',
  last30Days: 'LAST_30_DAYS',
};

export type RangeInputPreset = {
  id: string;
  label: string;
  value: DateRange;
};

const defaultPresets = [
  {
    id: RangeShortcut.today,
    label: 'Today',
    value: [dayjs().startOf('day'), dayjs().endOf('day')],
  },
  {
    id: RangeShortcut.last3Days,
    label: 'Last 3 days',
    value: [dayjs().startOf('day').subtract(2, 'day'), dayjs().endOf('day')],
  },
  {
    id: RangeShortcut.last7Days,
    label: 'Last 7 days',
    value: [dayjs().startOf('day').subtract(6, 'day'), dayjs().endOf('day')],
  },
  {
    id: RangeShortcut.last30Days,
    label: 'Last 30 days',
    value: [dayjs().startOf('day').subtract(29, 'day'), dayjs().endOf('day')],
  },
] as RangeInputPreset[];

const RangeShortcutsPanel = ({
  setValue,
  onOkClick,
  onResetClick,
  children,
  rangeInputProps,
  presets = defaultPresets,
}: {
  setValue?: React.Dispatch<React.SetStateAction<DateRange>>;
  onOkClick?: () => void;
  onResetClick?: () => void;
  children: React.ReactNode;
  presets?: RangeInputPreset[];
  rangeInputProps: { startProps: TextFieldProps; endProps: TextFieldProps };
}) => {
  const [selectedRange, setSelectedRange] = useState<string | null>(null);

  const handleRangeClick = React.useCallback(
    (range: DateRange, id: string) => {
      setSelectedRange(id);
      setValue?.(range);
    },
    [setValue],
  );

  return (
    <Paper sx={{ display: 'flex', flexDirection: 'row' }}>
      <Box display="flex">
        <List sx={{ py: 2, display: { xxs: 'none', sm: 'block' } }}>
          {presets.map(({ value, label, id }) => (
            <ListItem key={id} disablePadding>
              <ListItemButton
                onClick={() => handleRangeClick(value as DateRange, id)}
              >
                <ListItemText
                  primary={
                    <Typography
                      fontWeight={selectedRange === id ? 600 : 400}
                      variant="body2"
                      lineHeight="24px"
                    >
                      {label}
                    </Typography>
                  }
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider orientation="vertical" />
      </Box>
      <Box>
        <Box sx={{ p: 1 }}>
          <RangeInput {...rangeInputProps} />
        </Box>
        <>{children}</>
        <Divider orientation="horizontal" />
        <Box px={3} py={2} display="flex" justifyContent="end" gap={2}>
          <Button
            onClick={onResetClick}
            size="small"
            variant="outlined"
            color="primary"
          >
            Reset
          </Button>
          <Button
            onClick={onOkClick}
            size="small"
            variant="contained"
            color="primary"
          >
            Set
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default RangeShortcutsPanel;
