import { Icon } from '@iconify/react';
import {
  Box,
  BoxProps,
  Button,
  ButtonProps,
  Typography,
  useTheme,
} from '@mui/material';
import { success, error } from 'assets/iconify';
import { ReactNode } from 'react';

const Status = {
  SUCCESS: 'success',
  ERROR: 'error',
} as const;

type Status = (typeof Status)[keyof typeof Status];

interface Props extends BoxProps {
  onButtonClick: ButtonProps['onClick'];
  title?: string;
  subtitle?: ReactNode;
  buttonLabel?: string;
  status: Status;
}

const CONTENT = {
  [Status.SUCCESS]: {
    color: 'secondary',
    icon: success.icons.normal,
    defaultMessage: 'Success!',
  },
  [Status.ERROR]: {
    color: 'red',
    icon: error.icons.normal,
    defaultMessage: 'Error!',
  },
} as const;

const ChangePasswordSuccess = ({
  onButtonClick,
  title,
  subtitle,
  status,
  buttonLabel,
  ...rest
}: Props) => {
  const theme = useTheme();

  return (
    <Box
      width="100%"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={4}
      {...rest}
    >
      <Box p={4}>
        <Icon
          icon={CONTENT[status].icon}
          color={theme.palette[CONTENT[status].color].main}
          width={theme.spacing(16)}
          height={theme.spacing(16)}
        />
      </Box>
      <Typography variant="h6" textAlign="center" mb={1}>
        {title ?? CONTENT[status].defaultMessage}
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        color={theme.palette.blue.main}
        px={3}
        mb={4}
      >
        <>{subtitle}</>
      </Typography>
      <Box width="100%" p={1}>
        <Button
          size="large"
          fullWidth
          color={CONTENT[status].color}
          onClick={onButtonClick}
        >
          {buttonLabel ?? 'Continue'}
        </Button>
      </Box>
    </Box>
  );
};

export default ChangePasswordSuccess;
