import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  CircularProgress,
  Input,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import VInput from 'components/VInput';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import {
  useCreateMembershipMutation,
  useGetMembershipRolesQuery,
  useGetSpecialOrganizationChannelsQuery,
} from 'store/api/organizations.api';

type AddMembershipForm = {
  accountId: string;
  role: string;
};

type Props = {
  organizationId: number;
};

export const AddMembershipAdmin = ({ organizationId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);

  const { data, isLoading } = useGetMembershipRolesQuery({ organizationId });
  const { register, handleSubmit, reset } = useForm<AddMembershipForm>();

  const [
    createMembership,
    { isLoading: isCreatingMembership, isSuccess, isError },
  ] = useCreateMembershipMutation();

  const handleAddMembership = (data: AddMembershipForm) => {
    if (!data.accountId || !data.role) {
      toast.error('Please fill in all fields');
      return;
    }

    createMembership({
      organizationId,
      accountId: Number(data.accountId),
      role: data.role,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Membership added successfully');
      reset();
      setIsOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error('Failed to add membership');
    }
  }, [isError]);

  if (isLoading) {
    return (
      <Box p={6}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(true)}
        startIcon={<Icon icon="material-symbols:add" />}
      >
        Add Membership
      </Button>
      <Modal open={isOpen} onClose={() => setIsOpen(false)}>
        <Paper sx={{ p: 4, width: '80%', maxWidth: 400 }}>
          <form onSubmit={handleSubmit(handleAddMembership)}>
            <Typography variant="h6" gutterBottom>
              Add a new Membership
            </Typography>
            <InputLabel>Account ID</InputLabel>
            <VInput disableWhitespace size="small" {...register('accountId')} />
            <InputLabel sx={{ mt: 2 }}>Role</InputLabel>
            <Select
              size="small"
              sx={{ textTransform: 'capitalize' }}
              {...register('role')}
              fullWidth
            >
              {data?.results.map((role) => (
                <MenuItem
                  sx={{ textTransform: 'capitalize' }}
                  key={role}
                  value={role}
                >
                  {role}
                </MenuItem>
              ))}
            </Select>
            <Button
              type="submit"
              disabled={isCreatingMembership}
              sx={{ mt: 3, width: '100%' }}
            >
              Save
            </Button>
          </form>
        </Paper>
      </Modal>
    </>
  );
};
