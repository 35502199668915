import { Box, Grid2, Typography } from '@mui/material';
import { useSignals } from '@preact/signals-react/runtime';
import { Dayjs } from 'dayjs';
import { getSideBarState } from 'services/sidebar';
import { ReportWidget } from 'utils/get-reports-widgets';
import { DateRangePicker } from './components/ReportsPicker';
import { DateRange } from 'types/date-time';
import { CardBase, ReportsLoader, WidgetBody } from './components';

type Props = {
  dateRange: DateRange;
  isLoading?: boolean;
  widgets: ReportWidget[];
  onDateRangeChange: (dateRange: DateRange) => void;
};

export const Reports = ({
  widgets,
  dateRange,
  onDateRangeChange,
  isLoading,
}: Props) => {
  useSignals();
  const isSidebarOpen = getSideBarState();
  const breakpoints = isSidebarOpen
    ? { xxs: 12, newMd: 6, newXl: 6, new2Xl: 4, new4Xl: 3 }
    : { xxs: 12, newMd: 6, newXl: 4, new2Xl: 3 };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <DateRangePicker defaultRange={dateRange} onChange={onDateRangeChange} />
      <Grid2 container spacing={2}>
        {isLoading ? (
          <Grid2
            size={12}
            sx={{ display: 'flex', justifyContent: 'center', py: 4 }}
          >
            <ReportsLoader />
          </Grid2>
        ) : !isLoading && widgets.length === 0 ? (
          <Grid2
            size={12}
            sx={{ display: 'flex', justifyContent: 'center', py: 4 }}
          >
            <Typography variant="h6">
              No reports available for the selected range
            </Typography>
          </Grid2>
        ) : (
          widgets.map((widget, index) => (
            <Grid2 size={breakpoints} key={widget.title + index}>
              <CardBase title={widget.title}>
                <WidgetBody body={widget.body} />
              </CardBase>
            </Grid2>
          ))
        )}
      </Grid2>
    </Box>
  );
};
