import { alpha, Box, styled, Typography } from '@mui/material';
import { ReactNode, useMemo } from 'react';

type Item = {
  name: string;
  color: string;
  value: number;
};

type Props = {
  items: Item[];
  title?: string;
  hideTotal?: boolean;
  customTotal?: number;
  transformValue?: (value: number) => string | number;
  transformTotal?: (title: number) => ReactNode;
};

const Strong = styled('span')(() => ({
  fontWeight: 500,
  color: '#000',
}));

function calculatePercentages(numbers: number[], total?: number): number[] {
  if (numbers.length === 0) return [];

  const sum = total ?? numbers.reduce((acc, curr) => acc + curr, 0);
  const percentages = numbers.map((num) => Math.floor((num / sum) * 100));

  if (!total) {
    const remainingPoints =
      100 - percentages.reduce((acc, curr) => acc + curr, 0);

    const decimalParts = numbers.map((num, index) => ({
      index,
      decimal: ((num / sum) * 100) % 1,
    }));

    decimalParts.sort((a, b) => b.decimal - a.decimal);

    for (let i = 0; i < remainingPoints; i++) {
      percentages[decimalParts[i % numbers.length].index]++;
    }
  }

  percentages.forEach((percentage, index) => {
    if (percentage === 0 && numbers[index] > 0) {
      const maxIndex = percentages.indexOf(Math.max(...percentages));
      percentages[maxIndex]--;
      percentages[index]++;
    }
  });

  return percentages;
}

export const ListView = ({
  items,
  title,
  transformValue = (value) => value,
  transformTotal,
  customTotal,
  hideTotal,
}: Props) => {
  const sortedItems = useMemo(() => {
    return items.sort((a, b) => b.value - a.value);
  }, [items]);

  const { percentages, displayPercentages, total } = useMemo(() => {
    const total = items.reduce((acc, item) => acc + item.value, 0);

    if (total === 0)
      return {
        percentages: items.map(() => {
          return 0;
        }),
        displayPercentages: items.map(() => {
          return 0;
        }),
        total: 0,
      };

    return {
      percentages: calculatePercentages(items.map((item) => item.value)),
      displayPercentages: calculatePercentages(
        items.map((item) => item.value),
        customTotal,
      ),
      total,
    };
  }, [sortedItems, customTotal]);

  if (Number.isNaN(total))
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Typography
          sx={{ color: alpha('#000', 0.6), fontSize: 14, lineHeight: '20px' }}
        >
          {title}
        </Typography>
        <Typography sx={{ fontSize: 24, lineHeight: '32px' }}>N/A</Typography>
      </Box>
    );

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography
          sx={{ color: alpha('#000', 0.6), fontSize: 14, lineHeight: '20px' }}
        >
          {title}
        </Typography>
        {!hideTotal && (
          <Typography
            sx={{
              fontSize: 24,
              lineHeight: '32px',
              '&>strong': { fontWeight: 500 },
              '&>span': { color: alpha('#000', 0.8), fontSize: 20 },
            }}
          >
            <>
              {transformTotal ? transformTotal(total) : transformValue(total)}
            </>
          </Typography>
        )}
      </Box>
      <Box sx={{ display: 'flex', gap: 0.5, mb: 0.5 }}>
        {total === 0 ? (
          <Box
            sx={({ spacing, palette }) => ({
              height: spacing(1),
              bgcolor: alpha(palette.primary.main, 0.2),
              borderRadius: spacing(1),
              width: '100%',
            })}
          ></Box>
        ) : (
          sortedItems.map((item, index) =>
            percentages[index] === 0 ? null : (
              <Box
                key={item.value + item.color + index}
                sx={({ spacing }) => ({
                  height: spacing(1),
                  bgcolor: item.color,
                  borderRadius: spacing(1),
                  minWidth: spacing(1),
                  width: `${percentages[index]}%`,
                })}
              ></Box>
            ),
          )
        )}
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        {sortedItems.map((item, index) => (
          <Box
            key={item + item.color + index}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              py: 0.25,
              px: 0.5,
            }}
          >
            <Box display="flex" gap={1} alignItems="center">
              <Box
                sx={({ spacing }) => ({
                  height: spacing(1),
                  width: spacing(1),
                  borderRadius: 8,
                  bgcolor: item.color,
                })}
              ></Box>
              <Typography
                sx={{
                  fontSize: 14,
                  color: alpha('#000', 0.6),
                  textTransform: 'capitalize',
                }}
              >
                {item.name}
              </Typography>
            </Box>
            <Box display="flex" gap={0.5}>
              <Typography
                sx={{
                  fontSize: 14,
                  color: alpha('#000', 0.6),
                }}
              >
                <Strong>{displayPercentages[index].toFixed(0)}%</Strong> (
                {transformValue(item.value)})
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
