import { Box, Button, Divider, Paper, TextFieldProps } from '@mui/material';
import CalendarTrigger from './CalendarTrigger';

const CalendarControls = ({
  onOkClick,
  onResetClick,
  children,
  inputProps,
}: {
  onOkClick?: () => void;
  onResetClick?: () => void;
  children: React.ReactNode;
  inputProps?: TextFieldProps;
}) => {
  return (
    <Paper sx={{ display: 'flex', flexDirection: 'column' }}>
      <Box sx={{ p: 1 }}>
        <CalendarTrigger inputProps={inputProps} />
      </Box>
      <>{children}</>
      <Divider orientation="horizontal" />
      <Box px={3} py={2} display="flex" justifyContent="end" gap={2}>
        <Button
          onClick={onResetClick}
          size="small"
          variant="outlined"
          color="primary"
        >
          Reset
        </Button>
        <Button
          onClick={onOkClick}
          size="small"
          variant="contained"
          color="primary"
        >
          Set
        </Button>
      </Box>
    </Paper>
  );
};

export default CalendarControls;
