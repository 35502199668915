import { Box, ListItemIcon } from '@mui/material';
import { getSidebarNav } from 'nav';
import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useAppSelector } from 'store';
import { useAdminStatusQuery } from 'store/api/auth.api';
import TopBarLogo from './TopBarLogo';
import {
  SidebarAccordion,
  SidebarAccordionArrow,
  SidebarAccordionDetails,
  SidebarMenuDivider,
  SidebarMenuItem,
  SidebarMenuItemIcon,
  SidebarMenuItemText,
  SidebarMenuTitle,
  SidebarWrapper,
  StyledLink,
} from './Sidebar.styled';
import { useGetProfileQuery } from 'store/api/profiles.api';

const Sidebar = () => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [openAccordions, setOpenAccordions] = useState<string[]>([]);

  const accountId = useAppSelector((state) => state.user.accountId);
  const { profileId, patientName } = useAppSelector((state) => state.dashboard);
  const hasSingleProfile = useAppSelector(
    (state) => state.user.hasSingleProfile,
  );

  const { data: profileData } = useGetProfileQuery(
    {
      id: accountId as number,
    },
    {
      skip: !accountId,
    },
  );

  const firstVisit = useRef<boolean>(true);

  const sidebarNav = useMemo(
    () =>
      getSidebarNav(
        profileId,
        patientName,
        hasSingleProfile,
        profileData?.result.belongs_to_organization,
        profileData?.result.can_configure_organization_devices,
      ),
    [
      profileId,
      hasSingleProfile,
      patientName,
      profileData?.result.belongs_to_organization,
    ],
  );

  const { data: isAdmin = false } = useAdminStatusQuery(
    { userId: accountId as number },
    {
      skip: !accountId,
    },
  );

  const currentPageHref = useMemo(() => {
    return '/' + pathname.split('/')[1];
  }, [pathname]);

  const handleAccordionSwitch = (
    e: React.MouseEvent | undefined,
    accordionId: string,
  ) => {
    if (e) {
      e.stopPropagation();
    }

    if (openAccordions.includes(accordionId)) {
      return setOpenAccordions((prev) =>
        [...prev].filter((item) => item !== accordionId),
      );
    }

    return setOpenAccordions((prev) => [...prev, accordionId]);
  };

  const handleMenuItemClick = (
    e: React.MouseEvent,
    href: string,
    accordionId: string,
  ) => {
    // if (hasSingleProfile) {
    //   handleAccordionSwitch(e, accordionId);
    //   return;
    // }

    if (href === '#') {
      handleAccordionSwitch(e, accordionId);
      return;
    }

    navigate(href);
  };

  useEffect(() => {
    firstVisit.current = true;
  }, [currentPageHref]);

  return (
    <SidebarWrapper>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="96px"
      >
        <TopBarLogo inSidebar />
      </Box>
      {sidebarNav
        .filter((item) => (!item.adminOnly || isAdmin) && !item.hidden)
        .map((sectionItem, index) => (
          <React.Fragment key={sectionItem.name + index}>
            <SidebarMenuTitle>{sectionItem.name}</SidebarMenuTitle>
            {sectionItem.items
              .filter((navItem) => !navItem.adminOnly || isAdmin)
              .map((navItem, index) => {
                const isActive =
                  navItem.href === '#' && !!navItem.items?.length
                    ? navItem.items.findIndex(({ getIsActive }) =>
                        getIsActive?.(pathname),
                      ) !== -1
                    : !!navItem.getIsActive?.(pathname);

                const hasItems = !!(navItem.items && navItem.items.length);
                const accordionId = `${sectionItem.name}/${navItem.name}`;
                const isAccordionOpen = openAccordions.includes(accordionId);

                return hasItems ? (
                  <React.Fragment key={navItem.href + index}>
                    <SidebarMenuItem
                      onClick={(e) =>
                        handleMenuItemClick(e, navItem.href, accordionId)
                      }
                    >
                      <SidebarMenuItemIcon
                        icon={
                          navItem.icon.icons[isActive ? 'filled' : 'normal']
                        }
                      />
                      <SidebarMenuItemText highlighted={isActive}>
                        {navItem.name}
                      </SidebarMenuItemText>
                      <SidebarAccordionArrow
                        isOpen={isAccordionOpen}
                        onClick={(e) => handleAccordionSwitch(e, accordionId)}
                      />
                    </SidebarMenuItem>
                    <SidebarAccordion expanded={isAccordionOpen}>
                      <div />
                      <SidebarAccordionDetails>
                        {navItem.items
                          ?.filter((item) => !item.adminOnly || isAdmin)
                          .map((subItem, index) => {
                            const isActive = subItem.getIsActive?.(pathname);

                            if (
                              isActive &&
                              !openAccordions.includes(accordionId) &&
                              firstVisit.current
                            ) {
                              firstVisit.current = false;

                              handleAccordionSwitch(undefined, accordionId);
                            }

                            return (
                              <StyledLink
                                sx={{
                                  backgroundColor: ({ palette }) =>
                                    isActive
                                      ? palette.secondary.main
                                      : 'transparent',
                                  borderLeft: ({ spacing, palette }) =>
                                    `${spacing(0.5)} solid ${
                                      isActive
                                        ? palette.common.white
                                        : 'transparent'
                                    }`,
                                  ':hover': {
                                    borderColor: ({ palette }) =>
                                      isActive
                                        ? palette.common.white
                                        : palette.hover.dark,
                                  },
                                }}
                                key={subItem.href + index}
                                to={subItem.href}
                              >
                                <SidebarMenuItem isSubItem>
                                  {/* empty ListItemIcon so it can be aligned like the parent */}
                                  <ListItemIcon />
                                  <SidebarMenuItemText highlighted={isActive}>
                                    {subItem.name}
                                  </SidebarMenuItemText>
                                </SidebarMenuItem>
                              </StyledLink>
                            );
                          })}
                      </SidebarAccordionDetails>
                    </SidebarAccordion>
                  </React.Fragment>
                ) : (
                  <Link to={navItem.href} key={navItem.href + index}>
                    <SidebarMenuItem>
                      <SidebarMenuItemIcon
                        icon={
                          navItem.icon.icons[isActive ? 'filled' : 'normal']
                        }
                      />
                      <SidebarMenuItemText highlighted={isActive}>
                        {navItem.name}
                      </SidebarMenuItemText>
                    </SidebarMenuItem>
                  </Link>
                );
              })}
            <SidebarMenuDivider />
          </React.Fragment>
        ))}
    </SidebarWrapper>
  );
};

export default memo(Sidebar);
