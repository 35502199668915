import { useSignals } from '@preact/signals-react/runtime';
import { Ref, useEffect, useImperativeHandle, useRef, useState } from 'react';
import { caregiverRole } from 'services/caregiver-role';
import { useAppDispatch, useAppSelector } from 'store';
import { updateReminderTrigger } from 'store/reducers/reminder/reminder.slice';
import { ReminderSerializedSchedule } from 'types/alerters';
import { ALL_DAYS, Day } from 'utils/weekdays';

export interface ScheduleRuleValues {
  days: Day[];
  time: number;
}

export const useScheduleRuleForm = (
  id: number | undefined,
  functionsRef: Ref<{ getTriggerValues: () => ScheduleRuleValues }>,
  defaultValues?: ScheduleRuleValues,
) => {
  const dispatch = useAppDispatch();
  useSignals();

  const [time, setTime] = useState<number>(defaultValues?.time ?? 0);
  const [days, setDays] = useState<Day[]>(defaultValues?.days ?? ALL_DAYS);

  const editedRule = useAppSelector((state) => state.reminder.editedSchedule);
  const wasRuleSaved = useAppSelector(
    (state) => state.reminder.wasScheduleSaved,
  );
  const originalState = useRef<null | ReminderSerializedSchedule>(null);

  const handleTimeChange = (seconds: number) => {
    setTime(seconds);
    dispatch(
      updateReminderTrigger({
        startTime: seconds,
      }),
    );
  };

  const handleDaysChange = (days: Day[]) => {
    setDays(days);
    dispatch(
      updateReminderTrigger({
        days,
      }),
    );
  };

  useImperativeHandle(functionsRef, () => ({
    getTriggerValues() {
      return {
        days,
        time,
      };
    },
  }));

  useEffect(() => {
    // TODO: finish the logic
    if (typeof id === 'undefined') {
      return;
    }

    if (editedRule?.id === id) {
      originalState.current = editedRule.originalRule ?? null;
      return;
    }

    if (
      originalState.current !== null &&
      typeof editedRule === 'undefined' &&
      !wasRuleSaved
    ) {
      setTime(originalState.current?.startTime ?? 0);
      setDays(originalState.current?.days ?? []);
      originalState.current = null;
    }
  }, [editedRule?.id === id]);

  return {
    time,
    handleTimeChange,
    days,
    handleDaysChange,
    readOnly: caregiverRole.value === 'viewer',
  };
};
