import { Box, IconButton, Paper, Tab, Tabs } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Settings from './tabs/Settings';
import { Members } from './tabs/Members';
import { OrganizationReports } from './tabs/Reports';
import { routes } from 'nav';
import { Icon } from '@iconify/react';
import { arrowLeft } from 'assets/iconify';

const OrganizationTabIndex = {
  SETTINGS: 'settings',
  MEMBERS: 'members',
  REPORTS: 'reports',
} as const;

type OrganizationTabIndex =
  (typeof OrganizationTabIndex)[keyof typeof OrganizationTabIndex];

const getTabPanelContent = (index: OrganizationTabIndex) => {
  switch (index) {
    case OrganizationTabIndex.SETTINGS:
      return <Settings />;
    case OrganizationTabIndex.MEMBERS:
      return <Members />;
    case OrganizationTabIndex.REPORTS:
      return <OrganizationReports />;
    default:
      <></>;
  }
};

export const OrganizationDetails = () => {
  const [params, setParams] = useSearchParams();
  const navigate = useNavigate();

  const [currentTab, setCurrentTab] = useState<OrganizationTabIndex>(
    OrganizationTabIndex.SETTINGS,
  );

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    setCurrentTab(newValue as OrganizationTabIndex);
    setParams({ tab: newValue.toString() }, { replace: true });
  };

  const handleBack = () => {
    navigate(routes.admin.organizations.href);
  };

  useEffect(() => {
    const tab = params.get('tab');
    if (
      tab &&
      Object.values(OrganizationTabIndex).includes(tab as OrganizationTabIndex)
    ) {
      setCurrentTab(tab as OrganizationTabIndex);
      return;
    }
    setParams({ tab: OrganizationTabIndex.SETTINGS }, { replace: true });
  }, []);

  return (
    <Paper>
      <Box
        sx={{
          display: 'flex',
          backgroundColor: 'primary.main',
          alignItems: 'center',
        }}
      >
        <IconButton
          onClick={handleBack}
          sx={({ palette }) => ({ color: palette.common.white })}
        >
          <Icon icon={arrowLeft.icons.normal} />
        </IconButton>
        <Tabs sx={{}} value={currentTab} onChange={handleTabChange}>
          <Tab label="Settings" value={OrganizationTabIndex.SETTINGS} />
          <Tab label="Members" value={OrganizationTabIndex.MEMBERS} />
          <Tab label="Reports" value={OrganizationTabIndex.REPORTS} />
        </Tabs>
      </Box>
      <div>{getTabPanelContent(currentTab)}</div>
    </Paper>
  );
};
