import dayjs from 'dayjs';
import { useContext, useEffect, useRef } from 'react';
import { useAppSelector } from 'store';
import { useLazyGetDashboardDataQuery } from 'store/api/dashboard.api';
import { useLazyGetByIdQuery } from 'store/api/devices.api';
import { NewDeviceContext } from '../components/Devices/ConnectDeviceModal/ConnectDeviceModal';

export const useConfigCheck = (handleNextStep: () => void, sentAt: string) => {
  const { profileId } = useAppSelector((state) => state.dashboard);

  const { setIsError, deviceId } = useContext(NewDeviceContext);

  const [fetchPatient, { data: patient, isError: isPatientError }] =
    useLazyGetDashboardDataQuery();
  const [fetchDevice, { data: device, isError: isDeviceError }] =
    useLazyGetByIdQuery();
  const intervalRef = useRef<NodeJS.Timer>(undefined);

  const refetch = () => {
    fetchPatient({ account_id: profileId }, false);
    fetchDevice(deviceId);
  };

  useEffect(() => {
    if (deviceId === 0) {
      setIsError(true);
    }
    intervalRef.current = setInterval(refetch, 2000);
    return () => clearInterval(intervalRef.current);
  }, []);

  useEffect(() => {
    setIsError(isDeviceError || isPatientError);
  }, [isPatientError, isDeviceError]);

  useEffect(() => {
    if (device && patient && patient.accountId !== device.account_id) {
      setIsError(true);
    }
  }, [patient?.accountId, device?.account_id]);

  useEffect(() => {
    if (
      patient?.device?.isConfigOk &&
      dayjs(patient.generatedAt).isAfter(sentAt)
    ) {
      clearInterval(intervalRef.current);
      handleNextStep();
    }
  }, [patient?.device?.isConfigOk, patient?.generatedAt]);
};
