import * as React from 'react';
import ConfirmationDialog, { ConfirmationOptions } from './ConfirmationDialog';

export const ConfirmationServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject);

export const ConfirmationServiceProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [confirmationState, setConfirmationState] =
    React.useState<ConfirmationOptions | null>(null);
  const [isOpen, setIsOpen] = React.useState(false);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>({
    resolve: () => {},
    reject: () => {},
  });

  const openConfirmation = React.useCallback((options: ConfirmationOptions) => {
    setConfirmationState(options);
    setIsOpen(true);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  }, []);

  const handleClose = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }
    setIsOpen(false);
    setTimeout(() => {
      setConfirmationState(null);
    }, 500);
  };

  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }
    setIsOpen(false);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmationDialog
        isOpen={isOpen}
        onConfirm={handleSubmit}
        onReject={handleClose}
        {...confirmationState}
      />
    </>
  );
};
