import { useState } from 'react';
import { DateRange } from 'types/date-time';
import dayjs from 'dayjs';
import { useProfileReports } from './hooks/use-profile-reports';
import { Reports } from 'components/_extra/Reports';

export const ProfileReports = () => {
  const [dateRange, setDateRange] = useState<DateRange>([
    dayjs().subtract(7, 'days'),
    dayjs().subtract(1, 'days'),
  ]);

  const { widgets, isLoading } = useProfileReports(dateRange);

  return (
    <Reports
      widgets={widgets ?? []}
      isLoading={isLoading}
      dateRange={dateRange}
      onDateRangeChange={setDateRange}
    />
  );
};
