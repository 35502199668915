import { PropsWithChildren } from 'react';

import background from 'assets/boundary-care.svg';

import * as Styled from './AuthLayout.styles';
import { Box, Typography } from '@mui/material';

const AuthLayoutView = ({ children }: PropsWithChildren) => {
  return (
    <Styled.Wrapper>
      <Styled.LeftContent>
        <Styled.Background style={{ backgroundImage: `url(${background})` }}>
          <Styled.Filter>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              height="100%"
            >
              <Box
                mx={5}
                maxWidth={({ spacing }) => ({
                  xxs: spacing(60),
                  '3xl': spacing(65),
                })}
              >
                <Typography
                  fontSize={{ xxs: 32, '3xl': 40 }}
                  lineHeight={{ xxs: '40px', '3xl': '48px' }}
                  fontWeight={700}
                  pb={2}
                >
                  Welcome to Boundary<Styled.Span>Care</Styled.Span>
                </Typography>
                <Typography>
                  BoundaryCare is the leading health and location monitoring
                  solution powered by the Apple Watch.
                </Typography>
              </Box>
            </Box>
          </Styled.Filter>
        </Styled.Background>
      </Styled.LeftContent>
      <Styled.RightContent>
        <Box>
          <>{children}</>
        </Box>
      </Styled.RightContent>
    </Styled.Wrapper>
  );
};

AuthLayoutView.displayName = 'AuthLayoutView';

export default AuthLayoutView;
