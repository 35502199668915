import { Button } from '@mui/material';
import { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useEndCurrentSessionsMutation } from 'store/api/admin.api';

export const LogOffButton = ({ accountId }: { accountId: number }) => {
  const [handleLogOff, { isLoading, error, isSuccess, data }] =
    useEndCurrentSessionsMutation();

  useEffect(() => {
    if (isSuccess && !isLoading && data?.success) {
      toast.success('Successfully logged off');
    }
  }, [isSuccess, isLoading, data]);

  useEffect(() => {
    if (!isLoading && (error || (isSuccess && !data?.success))) {
      toast.error('Failed to log off');
    }
  }, [error, isLoading, data]);

  const handleLogOffClick = () => {
    handleLogOff({ accountId });
  };

  return <Button onClick={handleLogOffClick}>End Current Sessions</Button>;
};
