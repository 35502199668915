import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime, {
  thresholds: [
    { l: 's', r: 1 },
    { l: 'm', r: 1 },
    { l: 'mm', r: 59, d: 'minute' },
    { l: 'h', r: 1 },
    { l: 'hh', r: 23, d: 'hour' },
    { l: 'd', r: 1 },
    { l: 'dd', r: 29, d: 'day' },
    { l: 'M', r: 1 },
    { l: 'MM', r: 11, d: 'month' },
    { l: 'y' },
    { l: 'yy' },
  ],
});

dayjs.locale(
  'en',
  {
    relativeTime: {
      future: 'in %s',
      past: '%s ago',
      s: '1m',
      m: '1m',
      mm: '%dm',
      h: '1h',
      hh: '%dh',
      d: '1d',
      dd: '%dd',
      M: '1mo',
      MM: '%dmo',
      y: '1y',
      yy: '%dy',
    },
    monthsShort: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    months: [
      'January',
      'February',
      'March',
      'April',
      'May',
      'June',
      'July',
      'August',
      'September',
      'October',
      'November',
      'December',
    ],
    weekdaysShort: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    weekdaysMin: ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
    weekdays: [
      'Sunday',
      'Monday',
      'Tuesday',
      'Wednesday',
      'Thursday',
      'Friday',
      'Saturday',
    ],
    formats: {
      LT: 'h:mm A',
      LTS: 'h:mm:ss A',
      L: 'MM/DD/YYYY',
      LL: 'MMMM D, YYYY',
      LLL: 'MMMM D, YYYY h:mm A',
      LLLL: 'dddd, MMMM D, YYYY h:mm A',
    },
  },
  false,
);

export const formatTimeFrom = (timestamp: number | string) =>
  dayjs(timestamp).fromNow();

export const parseStringToSeconds = (timeString: string | null) => {
  if (timeString === null) {
    return null;
  }

  const isPm =
    `${timeString[timeString.length - 2]}${
      timeString[timeString.length - 1]
    }` !== 'am';

  const h = timeString.match(/(\d{1,2}):\d+/)?.[1];
  let hInt = parseInt(h ?? '0');

  const m = timeString.match(/\d{1,2}:(\d+)/)?.[1];
  const mInt = parseInt(m ?? '0');

  if (hInt === 12) {
    hInt = 0;
  }

  return (hInt * 60 + mInt) * 60 + (isPm ? 43200 : 0);
};

export const parseSecondsToString = (seconds: number | null | undefined) => {
  if (typeof seconds === 'undefined' || seconds === null) {
    return seconds;
  }

  const isPm = seconds > 43200;
  const value = seconds - (isPm ? 43200 : 0);
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}${isPm ? 'pm' : 'am'}`;
};
