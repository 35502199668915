import { Box, Modal, useMediaQuery, useTheme } from '@mui/material';
import { Dayjs } from 'dayjs';
import { StyledDateRangePicker } from '../Calendar.styled';
import { useModalInputs } from '../hooks/';
import { CalendarControls } from './';
import { RangeInputPreset } from './CalendarControls';
import { DateRange } from 'types/date-time';

export const FocusedInputIndex = {
  START: 0,
  END: 1,
} as const;
export type FocusedInputIndex =
  (typeof FocusedInputIndex)[keyof typeof FocusedInputIndex];

interface Props {
  value: DateRange;
  onChange: (newRange: DateRange) => void;
  onResetClick?: () => void;
  onOkClick?: () => void;
  onClose?: () => void;
  minDate?: Dayjs | null;
  maxDate?: Dayjs | null;
  open: boolean;
  focusedInput: FocusedInputIndex;
  setFocusedInput: React.Dispatch<React.SetStateAction<FocusedInputIndex>>;
  disableFuture?: boolean;
  setValue: React.Dispatch<React.SetStateAction<DateRange>>;
  presets?: RangeInputPreset[];
}

const CalendarModal = ({
  open,
  onChange,
  maxDate,
  minDate,
  onResetClick,
  onOkClick,
  onClose,
  value,
  disableFuture,
  setValue,
  focusedInput,
  presets,
}: Props) => {
  const { breakpoints } = useTheme();
  const isLargeScreen = useMediaQuery(breakpoints.up('lg'));

  const { endProps, startProps } = useModalInputs(value, focusedInput);

  return (
    <Modal
      open={open}
      onClose={onClose}
      sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <Box>
        <CalendarControls
          setValue={setValue}
          onOkClick={onOkClick}
          onResetClick={onResetClick}
          presets={presets}
          rangeInputProps={{ startProps, endProps }}
        >
          <StyledDateRangePicker
            value={value}
            autoFocus
            displayStaticWrapperAs="desktop"
            disableFuture={disableFuture}
            calendars={isLargeScreen ? 2 : 1}
            onChange={onChange}
            minDate={minDate ?? undefined}
            maxDate={maxDate ?? undefined}
          />
        </CalendarControls>
      </Box>
    </Modal>
  );
};

export default CalendarModal;
