import { ReportWidgetBodyItem } from 'utils/get-reports-widgets';
import { ConcurrentView } from './ConcurrentView';
import { ListView } from './ListView';
import { SimpleView } from './SimpleView';
import React from 'react';
import { Divider } from '@mui/material';
import { ClickableList } from './ClickableList';
import { MultipleClickableList } from './MultipleClickableList';

type Props = {
  body: ReportWidgetBodyItem[];
};

const BodyItem = ({ item }: { item: ReportWidgetBodyItem }) => {
  switch (item.type) {
    case 'concurrent':
      return <ConcurrentView items={[item.items[0], item.items[1]]} />;
    case 'list':
      return (
        <ListView
          title={item.subTitle}
          items={item.items}
          transformValue={item.transformValue}
          hideTotal={item.hideTotal}
          customTotal={item.customTotal}
          transformTotal={item.transformTotal}
        />
      );
    case 'simple':
      return <SimpleView title={item.title} value={item.value} />;
    case 'list-clickable':
      return <ClickableList {...item} />;
    case 'list-clickable-multiple':
      return <MultipleClickableList {...item} />;
  }
};

export const WidgetBody = ({ body }: Props) => {
  return (
    <React.Fragment>
      {body.map((item, index) => (
        <React.Fragment key={item.type + index}>
          {index > 0 && <Divider />}
          <BodyItem key={item.type + index} item={item} />
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};
