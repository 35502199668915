import { Reports } from 'components/_extra/Reports';
import dayjs from 'dayjs';
import { useOrganizationReports } from 'pages/admin/OrganizationDetails/hooks/use-organization-reports';
import { useState } from 'react';
import { useGetOrganizationsQuery } from 'store/api/organizations.api';
import { DateRange } from 'types/date-time';

export const OrganizationReports = () => {
  const [dateRange, setDateRange] = useState<DateRange>([
    dayjs().subtract(7, 'days'),
    dayjs().subtract(1, 'day'),
  ]);

  const { data: organizations, isLoading: areOrganizationsLoading } =
    useGetOrganizationsQuery();
  const { widgets, isLoading } = useOrganizationReports(
    organizations?.[0].id ?? 0,
    dateRange,
  );

  return (
    <Reports
      dateRange={dateRange}
      widgets={widgets}
      isLoading={isLoading || areOrganizationsLoading}
      onDateRangeChange={setDateRange}
    />
  );
};
