import { useSignals } from '@preact/signals-react/runtime';
import { useConfirmation } from 'hooks/use-confirmation';
import { routes } from 'nav';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { setAppbarTitle } from 'services/custom-appbar-title';
import { useAppDispatch } from 'store';
import {
  remindersApi,
  useDeleteReminderMutation,
  useUpdateReminderMutation,
} from 'store/api/reminders.api';
import { TriggerGroup } from 'utils/triggers';

export const useCardControls = (id: number, group: TriggerGroup) => {
  useSignals();
  const dispatch = useAppDispatch();
  const { profileId } = useParams();
  const navigate = useNavigate();
  const confirm = useConfirmation();

  const handleEditAlert = (name: string) => {
    if (profileId) {
      setAppbarTitle(name ?? '');
      navigate(routes.private.reminderDetails.href(parseInt(profileId), id));
    }
  };

  const [update] = useUpdateReminderMutation();
  const [
    remove,
    { isSuccess: wasRemoved, isError: hasRemoveError, isLoading: isRemoving },
  ] = useDeleteReminderMutation();

  const handleSwitch = (checked: boolean) => {
    update({ id, enabled: checked })
      .unwrap()
      .then(({ result }) => {
        dispatch(
          remindersApi.util.updateQueryData(
            'getReminders',
            {
              patientProfileId: Number(profileId),
            },
            (response) => {
              const index = response[group].findIndex(
                (reminder) => reminder.id === id,
              );
              if (typeof index !== 'undefined' && result) {
                response[group][index].enabled = result.enabled;
              }
            },
          ),
        );
      })
      .catch(() => {
        toast.error('An error occured.');
      });
  };

  const handleRemove = (id: number, name: string) => {
    confirm(
      {
        title: `Are you sure you want to delete “${
          name ?? 'Untitled'
        }” from the reminder list?`,
        message:
          'This reminder will be deleted immediately. You can’t undo this action.',
        variant: 'danger',
        rejectButtonLabel: 'Cancel',
      },
      () => remove(id),
    );
  };

  useEffect(() => {
    if (!isRemoving && wasRemoved) {
      toast.success('The reminder was successfully removed!');
      dispatch(
        remindersApi.util.updateQueryData(
          'getReminders',
          {
            patientProfileId: Number(profileId),
          },
          (response) => {
            response[group ?? 'uncategorized'] = response[
              group ?? 'uncategorized'
            ]?.filter((reminder) => reminder.id !== id);
          },
        ),
      );
    }
  }, [wasRemoved, hasRemoveError]);

  return { handleEditAlert, handleSwitch, handleRemove };
};
