import { Icon } from '@iconify/react';
import {
  Box,
  Button,
  ButtonGroup,
  MenuItem,
  Modal,
  Paper,
  Select,
  Typography,
} from '@mui/material';
import VInput from 'components/VInput';
import { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  useCreateMembershipMutation,
  useGetMembershipRolesQuery,
} from 'store/api/organizations.api';

type Props = {
  organizationId: number;
};

export const AddNewMembership = ({ organizationId }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [identifier, setIdentifier] = useState<'email' | 'unique_identifier'>(
    'email',
  );
  const [role, setRole] = useState('');
  const [isDisabled, setIsDisabled] = useState(true);

  const { data, isLoading: areRolesLoading } = useGetMembershipRolesQuery({
    organizationId,
  });

  const [addMembership, { isLoading, isSuccess, isError }] =
    useCreateMembershipMutation();

  const onSave = () => {
    if (!inputRef.current?.value || !role) {
      toast.error('Please fill in all fields');
      return;
    }

    addMembership({
      organizationId,
      email: identifier === 'email' ? inputRef.current.value : undefined,
      unique_public_identifier:
        identifier === 'unique_identifier' ? inputRef.current.value : undefined,
      role,
    });
  };

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.value) {
      setIsDisabled(true);
      return;
    }
    setIsDisabled(false);
  };

  useEffect(() => {
    if (isSuccess) {
      toast.success('Membership added successfully');
      setIsOpen(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      toast.error('Something went wrong.');
    }
  }, [isError]);

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setIsOpen(true)}
        startIcon={<Icon icon="material-symbols:add" />}
      >
        Add Membership
      </Button>
      {isOpen && (
        <Modal
          open={isOpen}
          onClose={() => {
            setIsOpen(false);
            if (inputRef.current) inputRef.current.value = '';
            setRole('');
          }}
        >
          <Paper sx={{ p: 3, width: '80%', maxWidth: 400 }}>
            <Typography variant="h6" gutterBottom>
              Add a new Membership
            </Typography>
            <ButtonGroup fullWidth sx={{ mb: 2 }} disabled={areRolesLoading}>
              {['email', 'unique_identifier'].map((item) => (
                <Button
                  key={item}
                  onClick={() =>
                    setIdentifier(item as 'email' | 'unique_identifier')
                  }
                  sx={{ textTransform: 'capitalize' }}
                  variant={identifier === item ? 'contained' : 'outlined'}
                >
                  {item.replace('_', ' ')}
                </Button>
              ))}
            </ButtonGroup>
            <VInput
              disableWhitespace
              size="small"
              inputRef={inputRef}
              onChange={onInputChange}
              placeholder={
                identifier === 'email'
                  ? 'Email address'
                  : 'Unique BC Identifier'
              }
              sx={{ mb: 2 }}
            />
            <Select
              displayEmpty
              value={role}
              onChange={(e) => {
                setRole(e.target.value as string);
              }}
              size="small"
              sx={{ textTransform: 'capitalize' }}
              fullWidth
            >
              <MenuItem value="" disabled>
                Select a role
              </MenuItem>
              {data?.results.map((role) => (
                <MenuItem
                  sx={{ textTransform: 'capitalize' }}
                  key={role}
                  value={role}
                >
                  {role}
                </MenuItem>
              ))}
            </Select>
            <Box display="flex" justifyContent="flex-end" gap={2} mt={2}>
              <Button
                size="small"
                onClick={() => setIsOpen(false)}
                variant="outlined"
                color="primary"
                disabled={isLoading}
              >
                Cancel
              </Button>
              <Button
                size="small"
                color="primary"
                onClick={onSave}
                disabled={isDisabled || isLoading || !role}
              >
                Add Member
              </Button>
            </Box>
          </Paper>
        </Modal>
      )}
    </>
  );
};
