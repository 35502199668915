import { HTMLProps, useEffect, useRef, useState } from 'react';
import 'react-phone-number-input/style.css';
import './styles/custom.css';
import PhoneInput, {
  Country,
  getCountryCallingCode,
  parsePhoneNumber,
} from 'react-phone-number-input';
import {
  Box,
  BoxProps,
  FormHelperText,
  InputLabel,
  styled,
  useTheme,
} from '@mui/material';
import { palette } from 'styles/palette';
import { Icon } from '@iconify/react';
import { FieldError } from 'react-hook-form';

const Container = styled('div')`
  position: relative;
  border-radius: 4px;
  border: 1px solid ${palette.borderGray.main};
  padding: 8px 16px;
  &.error {
    border-color: ${palette.grapeFruit.main};
  }
`;

const Input = styled('input')`
  padding: 8px;
  padding-left: 16px;
  font-size: 16px;
  height: 30px;
  line-height: 16px;
  color: ${palette.primary.main};
  border: none;
  outline: none;
  &::placeholder {
    color: ${palette.disabled.main};
    font-family: 'Euclid Flex', 'euclid';
    opacity: 1;
  }

  &:read-only {
    color: ${({ theme }) => theme.palette.blue.main};
  }
`;

const GlobeIcon = () => (
  <Icon
    icon="mdi:public"
    color={palette.primary.main}
    width={24}
    height={24}
    style={{ marginTop: '-4px' }}
  />
);

interface IProps {
  onChange?: (data: string) => void;
  label?: string;
  value?: string;
  inputProps?: HTMLProps<HTMLInputElement>;
  error?: FieldError;
  containerProps?: BoxProps;
  disabled?: boolean;
  readOnly?: boolean;
  placeholder?: string;
}

const CustomPhoneInput = ({
  inputProps,
  onChange,
  label,
  value,
  error,
  containerProps,
  disabled,
  readOnly,
  placeholder,
}: IProps) => {
  const { palette } = useTheme();
  const [phoneNumber, setPhoneNumber] = useState(value);
  const [country, setCountry] = useState<Country>();

  const phoneNumberRef = useRef<string>('');
  const defaultNumber = useRef(value);

  const handleChange = (data: string) => {
    if (!data) {
      phoneNumberRef.current = '';
      onChange?.('');
      return;
    }

    const nationalNumber = parsePhoneNumber(data)?.nationalNumber;
    if (data.length > 4 || nationalNumber) {
      phoneNumberRef.current = nationalNumber ?? data.substring(1);
    }
    setPhoneNumber(country ? data : data.substring(1));
    onChange?.(parsePhoneNumber(data)?.number ?? data);
  };

  const handleCountryCodeChange = (value: Country) => {
    setCountry(value);
  };

  useEffect(() => {
    if (phoneNumber === defaultNumber.current) return;

    if (country) {
      handleChange(
        '+' + getCountryCallingCode(country) + phoneNumberRef.current,
      );
    }
  }, [country]);

  return (
    <Box {...containerProps}>
      {label && (
        <InputLabel
          sx={{
            color: palette.primary.main,
            pb: 1,
            fontSize: 14,
            lineHeight: '16px',
          }}
        >
          {label}
        </InputLabel>
      )}
      <PhoneInput
        disabled={disabled}
        containerComponent={Container}
        placeholder={placeholder ?? '+1 2345678910'}
        international
        internationalIcon={GlobeIcon}
        inputComponent={Input}
        readOnly={readOnly}
        value={phoneNumber}
        countrySelectProps={{
          style: { width: '24px', height: '24px' },
          disabled: readOnly || disabled,
        }}
        onChange={(v) => handleChange(v as string)}
        className={error ? 'error' : undefined}
        onCountryChange={handleCountryCodeChange}
        focusInputOnCountrySelection
        numberInputProps={{
          error: !!error,
          ...inputProps,
        }}
      />
      {error && (
        <FormHelperText
          sx={{
            my: 1,
            color:
              error.type === 'validate'
                ? palette.primary.main
                : palette.grapeFruit.main,
          }}
        >
          {error.message}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CustomPhoneInput;
