import {
  Box,
  FormHelperText,
  FormHelperTextProps,
  InputBaseProps,
  InputLabel,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import React, { ReactNode } from 'react';
import { StyledVInput } from './VInput.styled';

export type VInputProps = InputBaseProps & {
  text?: string;
  label?: ReactNode;
  icon?: JSX.Element | null;
  borderRadius?: number;
  backgroundColor?: string;
  helperText?: React.ReactNode;
  width?: string | number;
  formHelperTextProps?: FormHelperTextProps;
  inputProps?: React.HTMLProps<HTMLInputElement>;
  containerSx?: SxProps<Theme>;
  disableWhitespace?: boolean;
  rootRef?: React.Ref<unknown>;
  blueHelper?: boolean;
  leftIcon?: JSX.Element | null;
};

const VInput = ({
  width,
  text,
  backgroundColor,
  borderRadius,
  disabled,
  disableWhitespace,
  label,
  helperText = undefined,
  name,
  inputProps,
  icon,
  onKeyDown,
  onChange,
  formHelperTextProps = {},
  containerSx = {},
  rootRef,
  sx,
  size = 'medium',
  blueHelper,
  leftIcon,
  ...rest
}: VInputProps) => {
  const { palette } = useTheme();
  const defaultContainerSx = disableWhitespace ? { m: 0 } : { mx: 1, mt: 1.5 };

  return (
    <Box
      width={width}
      ref={rootRef}
      sx={[
        defaultContainerSx,
        ...(Array.isArray(containerSx) ? containerSx : [containerSx]),
      ]}
      data-testid="VInput"
    >
      <InputLabel
        htmlFor={name}
        sx={{
          color: palette.primary.main,
          fontSize: 14,
          pb: label || text ? 0.5 : 0,
          lineHeight: '20px',
        }}
      >
        <>{label || text}</>
      </InputLabel>
      <StyledVInput
        sx={{
          backgroundColor: backgroundColor ?? palette.common.white,
          borderRadius,
          mb: disableWhitespace ? 0 : 1,
          ...sx,
        }}
        size={size}
        onChange={onChange}
        name={name}
        id={name}
        disabled={disabled}
        onKeyDown={onKeyDown}
        endAdornment={icon}
        startAdornment={leftIcon}
        inputProps={inputProps}
        {...rest}
      />
      {!!helperText && (
        <FormHelperText
          {...formHelperTextProps}
          sx={{
            pl: 1,
            color:
              rest.error && !blueHelper
                ? palette.grapeFruit.main
                : palette.primary.main,
            ...formHelperTextProps.sx,
          }}
          data-testid="helperText"
        >
          <>{helperText}</>
        </FormHelperText>
      )}
    </Box>
  );
};

VInput.displayName = 'VInput';

export default React.forwardRef((props: VInputProps, ref) => (
  <VInput {...props} rootRef={ref} />
));
