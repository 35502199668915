import { ConfigureManagedProfilePayload } from 'models/admin.model';
import { ProfileConfiguration } from 'pages/admin/ProfileConfigurator/ProfileConfigurator';

export const transformProfileConfigurationPayload = (
  payload: ProfileConfiguration,
): ConfigureManagedProfilePayload => {
  return {
    patient_profile: {
      id: payload.profileSettings.id ?? 0,
      first_name: payload.profileSettings.firstName,
      last_name: payload.profileSettings.lastName,
      collects_health_data: payload.profileSettings.collectsHealthData,
      collects_location_data: payload.profileSettings.collectsLocationData,
      show_request_help_button: payload.profileSettings.showRequestHelpButton,
      show_call_emergency_contact_button:
        payload.profileSettings.showEmergencyButton,
      third_party_public_identifier: payload.profileSettings.customIdentifier,
      did_complete_managed_onboarding: false,
      preferred_health_refresh_pattern_type:
        payload.profileSettings.healthRefreshPatternType,
      preferred_location_refresh_pattern_type:
        payload.profileSettings.locationRefreshPatternType,
      use_large_request_help_button:
        payload.profileSettings.useLargeRequestHelpButton,
    },
    organization_id: payload.organizationId,
    caregivers: payload.caregivers.map((caregiver) => ({
      first_name: caregiver.firstName,
      last_name: caregiver.lastName,
      email: caregiver.email,
      phone_number: caregiver.phoneNumber,
      requires_mfa: !!caregiver.requiresMfa,
      is_manager: !!caregiver.isManager,
      is_emergency_contact:
        payload.profileSettings.emergencyContactEmail === caregiver.email &&
        payload.profileSettings.showEmergencyButton,
    })),
    regions: payload.regions.map((region) => ({
      name: region.title,
      lat: region.lat,
      lng: region.lng,
      shape_type: region.shapeType,
      radius: region.radius,
      geofence: region.geofence?.map((v) => v.join(',')).join('|'),
    })),
    alerter_templates: payload.alertTemplates.map((alertTemplate) => ({
      template_identifier: alertTemplate.alertTemplateType,
      template_params:
        alertTemplate.templateParams &&
        Object.keys(alertTemplate.templateParams).length > 0
          ? Object.entries(alertTemplate.templateParams).reduce(
              (acc, [key, value]) => {
                if (
                  [
                    'battery_level',
                    'blood_oxygen_level',
                    'tonic_clonic_motion_score',
                    'perseverative_motion_detection',
                  ].includes(key)
                ) {
                  return {
                    ...acc,
                    [key]: (value / 100).toFixed(2),
                  };
                }

                if (key === 'daily_sleep_time') {
                  return {
                    ...acc,
                    [key]: (value / 60).toFixed(0),
                  };
                }

                if (key === 'speed') {
                  return {
                    ...acc,
                    [key]: Math.round(value / 1.60934).toString(),
                  };
                }

                return {
                  ...acc,
                  [key]: value.toString(),
                };
              },
              {},
            )
          : undefined,
    })),
    partner_deployment: payload.partnerDeployment,
    payment_subscription_start_date: payload.activationDate,
  };
};
