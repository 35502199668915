import { Button, styled } from '@mui/material';
import General from './components/General';
import Security from './components/Security';
import AlertIntegrations from './components/AlertIntegrations';

const FormFields = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

const Form = styled('form')(({ theme }) => ({
  maxHeight: '100%',
  overflow: 'auto',
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
  padding: theme.spacing(4),
  [theme.breakpoints.down('newMd')]: {
    padding: theme.spacing(3),
  },
  [theme.breakpoints.down('sm')]: {
    padding: theme.spacing(2),
  },
}));

const ButtonsWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'end',
  gap: theme.spacing(2),
}));

type Props = {
  handleSubmit: () => void;
  isLoading: boolean;
  resetOrganizationForm: () => void;
  shouldDisableControls: boolean;
  isAdmin?: boolean;
};

export const OrganizationSettingsForm = ({
  handleSubmit,
  isLoading,
  resetOrganizationForm,
  shouldDisableControls,
  isAdmin,
}: Props) => {
  return (
    <Form onSubmit={handleSubmit}>
      <FormFields>
        <General disabled={isLoading} isAdmin={isAdmin} />
        <Security disabled={isLoading} />
        <AlertIntegrations disabled={isLoading} />
      </FormFields>
      <ButtonsWrapper>
        <Button
          disabled={shouldDisableControls}
          onClick={resetOrganizationForm}
          color="primary"
          variant="outlined"
        >
          Discard
        </Button>
        <Button disabled={shouldDisableControls} type="submit" color="primary">
          Save
        </Button>
      </ButtonsWrapper>
    </Form>
  );
};
