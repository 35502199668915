import { General, HelperText } from '../styled';
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { memo } from 'react';
import TargetWidget from './TargetWidget';
import Frequency from './Frequency';
import { SettingsForm } from 'pages/AlerterDetails/hooks/use-settings';
import { HelpIcon } from 'components/HelpIcon';

const GeneralFields = ({
  error,
  readOnly,
}: {
  error?: string;
  readOnly?: boolean;
}) => {
  const { register, getValues, watch } = useFormContext<SettingsForm>();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={3}
      py={2}
      px={{ xxs: 0, newLg: 2 }}
    >
      <General>
        <div>
          <Typography color="primary" pb={1.5}>
            Title
          </Typography>
          <OutlinedInput
            size="small"
            sx={{ minWidth: ({ spacing }) => spacing(30) }}
            error={!!error}
            readOnly={readOnly}
            {...register('title')}
          />
          <HelperText error>{error}</HelperText>
        </div>
        <div>
          <Typography color="primary" pb={1.5}>
            Data Source
          </Typography>
          <Select
            readOnly
            size="medium"
            sx={{ minWidth: ({ spacing }) => spacing(30) }}
            defaultValue="apple-watch"
          >
            <MenuItem value="apple-watch">Apple Watch</MenuItem>
          </Select>
        </div>
      </General>
      <TargetWidget readOnly={readOnly} />
      <Frequency readOnly={readOnly} />
      <div>
        <Typography color="primary">Acknowledgements</Typography>
        <Box display="flex" alignItems="center" gap={1}>
          <FormControlLabel
            label="Require Acknowledgement"
            labelPlacement="end"
            sx={{ mr: 0 }}
            disabled={readOnly || getValues('forcedRequiresAcknowledgement')}
            control={
              <Checkbox
                {...register('requiresAcknowledgement')}
                defaultChecked={
                  getValues('requiresAcknowledgement') ||
                  getValues('forcedRequiresAcknowledgement')
                }
              />
            }
          />
          <HelpIcon message="Acknowledgement is always required if the alert's receipient sets request multiple attempts or recipient levels." />
        </Box>
      </div>
    </Box>
  );
};

export default memo(GeneralFields);
