import {
  AlerterResponse,
  AlerterSummary,
  CreateAlerterParams,
  CreateAlerterTriggerParams,
  GetAlertersParams,
  AlerterSchedulerResponse,
  LinkAlerterSchedulerParams,
  AlerterLinkResponse,
  RawAlerterScheduler,
  RawAlerterRecipientManagerResponse,
  AlerterRecipientManager,
  LinkRecipientManagerToSchedulerArgs,
  AlerterRecipientSetArgs,
  RawAlerterTriggerResponse,
  AlerterTriggerUpdateParams,
  RawAlerterRuleResponse,
  AlerterTriggerDeleteParams,
  UpdateAlerterParams,
  RawAlerterChannelSet,
  ChannelSet,
  GetAlerterTemplatesResponse,
  AlerterRecipient,
  RawRecipient,
  AlerterRecipientSetResponse,
  RemoveRecipientSetArgs,
  MetricRule,
  PatternType,
  SharedAlerterRecipientManagerResponse,
  GetAlerterEventsByWidgetParams,
  AlerterEventByWidgetResponse,
  RawAlerterEventByWidgetResponse,
  AlerterEventsWidgets,
  AlerterEvent,
  RawAlerterSearchResponse,
  SearchAlerterEventsParams,
  GetAlertersResponse,
  CreateRecipientManagerReq,
} from 'types/alerters';
import { bcApi } from './_api';
import { RawMetricRule } from 'types/alerters';
import {
  transformAlerterEvent,
  transformAlerterList,
  transformCreateAlerterParams,
  transformMetricDataToRaw,
  transformRecipient,
  transformSharedRecipientManager,
} from 'utils/data-transformation';
import qs from 'qs';
import { TriggerGroup } from 'utils/triggers';
import { RootState } from 'store/store';
import {
  CombinedRecipientManager,
  updateActiveRecipientSets,
} from 'store/reducers/alerter/alerter.slice';
import { toast } from 'react-toastify';
import { updateReminderActiveRecipientSets } from 'store/reducers/reminder/reminder.slice';

export const alertersApi = bcApi.injectEndpoints({
  endpoints: (builder) => ({
    getAlerters: builder.query<
      Record<TriggerGroup | 'uncategorized', AlerterSummary[]>,
      GetAlertersParams
    >({
      query: ({ alerterSourceId, deviceId, patientProfileId, userId }) => ({
        url: '/alerters',
        method: 'GET',
        params: {
          alerter_source_id: alerterSourceId,
          device_id: deviceId,
          patient_profile_id: patientProfileId,
          user_id: userId,
        },
      }),
      transformResponse: (data: GetAlertersResponse) => {
        return transformAlerterList(data);
      },
      providesTags: ['AlertersList'],
    }),
    createAlerter: builder.mutation<AlerterResponse, CreateAlerterParams>({
      query: (data) => ({
        url: '/alerters',
        method: 'POST',
        data: transformCreateAlerterParams(data),
      }),
      invalidatesTags: ['SharedRecipientSets'],
    }),
    getAlerterInfo: builder.query<AlerterResponse, number>({
      query: (id) => ({
        url: `/alerters/${id}`,
        method: 'GET',
      }),
      providesTags: ['AlerterData'],
      keepUnusedDataFor: 0.001,
    }),
    updateAlerter: builder.mutation<AlerterResponse, UpdateAlerterParams>({
      query: ({ id, ...alerter }) => ({
        url: `/alerters/${id}`,
        method: 'PUT',
        data: {
          is_reminder: alerter.isReminder,
          custom_message_template: alerter.customMessage,
          use_custom_message_template: alerter.hasCustomMessage,
          generated_message_template: alerter.generatedMessage,
          title: alerter.title,
          enabled: alerter.enabled,
          alerter_recipient_manager_id: alerter.recipientManagerId,
          custom_widget_targets: alerter.customWidgetTargets,
          message_image: alerter.messageImage,
          message_image_external_url: alerter.messageImageUrl,
          requires_acknowledgement: alerter.requiresAcknowledgement,
          forced_requires_acknowledgement:
            alerter.forcedRequiresAcknowledgement,
          delete_message_image:
            !alerter.messageImageUrl && !alerter.messageImage,
        },
      }),
      invalidatesTags: [
        'SharedRecipientSets',
        'AlertersList',
        'DashboardData',
        'ProfileData',
      ],
    }),
    deleteAlerter: builder.mutation<AlerterResponse, number>({
      query: (id) => ({
        url: `/alerters/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SharedRecipientSets'],
    }),
    getAlerterSchedulerInfo: builder.query<AlerterSchedulerResponse, number>({
      query: (id) => ({
        url: `/alerter_schedulers/${id}`,
        method: 'GET',
      }),
      providesTags: ['AlerterSchedule'],
    }),
    createAlerterScheduler: builder.mutation<
      AlerterSchedulerResponse,
      RawAlerterScheduler
    >({
      query: (data) => ({
        url: '/alerter_schedulers',
        method: 'POST',
        data,
      }),
    }),
    updateAlerterScheduler: builder.mutation<
      AlerterSchedulerResponse,
      Partial<RawAlerterScheduler> & { id: number }
    >({
      query: ({ id, ...data }) => ({
        url: `/alerter_schedulers/${id}`,
        method: 'PUT',
        data,
      }),
      invalidatesTags: ['AlerterSchedule'],
    }),
    deleteAlerterScheduler: builder.mutation<AlerterSchedulerResponse, number>({
      query: (id) => ({
        url: `/alerter_schedulers/${id}`,
        method: 'DELETE',
      }),
    }),
    linkAlerterScheduler: builder.mutation<
      AlerterLinkResponse,
      LinkAlerterSchedulerParams
    >({
      query: ({ id, alerterId, alerterRecipientManagerId }) => ({
        url: `/alerter_schedulers/${id}/link_with`,
        method: 'PUT',
        data: {
          alerter_id: alerterId,
          alerter_recipient_manager_id: alerterRecipientManagerId,
        },
      }),
    }),
    unlinkAlerterScheduler: builder.mutation<
      AlerterLinkResponse,
      LinkAlerterSchedulerParams
    >({
      query: ({ id, alerterId, alerterRecipientManagerId }) => ({
        url: `/alerter_schedulers/${id}/unlink_from`,
        method: 'PUT',
        data: {
          alerter_id: alerterId,
          alerter_recipient_manager_id: alerterRecipientManagerId,
        },
      }),
    }),
    getRecipientManager: builder.query<
      RawAlerterRecipientManagerResponse,
      number
    >({
      query: (id) => ({
        url: `/alerter_recipient_managers/${id}`,
        method: 'GET',
      }),
    }),
    createAlerterRecipientManager: builder.mutation<
      RawAlerterRecipientManagerResponse,
      CreateRecipientManagerReq
    >({
      query: (data) => {
        return {
          url: '/alerter_recipient_managers',
          method: 'POST',
          data,
        };
      },
      invalidatesTags: ['SharedRecipientSets'],
    }),
    updateAlerterRecipientManager: builder.mutation<
      RawAlerterRecipientManagerResponse,
      Partial<AlerterRecipientManager> & { id: number }
    >({
      query: ({ id, ...args }) => {
        const data = {
          attempt_count: args.attemptCount,
          attempt_spacing: args.attemptSpacing,
          name: args.name,
        };

        return {
          url: `/alerter_recipient_managers/${id}`,
          method: 'PUT',
          data,
        };
      },
      invalidatesTags: ['PossibleRecipients', 'SharedRecipientSets'],
    }),
    deleteRecipientManager: builder.mutation<
      RawAlerterRecipientManagerResponse,
      { id: number; isReminder?: boolean }
    >({
      query: ({ id }) => ({
        url: `/alerter_recipient_managers/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: async (
        { id, isReminder },
        { dispatch, getState, queryFulfilled },
      ) => {
        const patchResult = dispatch(
          alertersApi.util.updateQueryData(
            'getAlerterSharedRecipients',
            {
              patientProfileId: (getState() as RootState).dashboard.profileId,
              isReminder,
            },
            (draft) => {
              return draft.filter((result) => result.id !== id);
            },
          ),
        );
        try {
          await queryFulfilled;
          toast.success('Successfully deleted.');
        } catch (error) {
          patchResult.undo();
          toast.error('Failed to delete recipient manager');
        }
      },
      invalidatesTags: ['AlertersList', 'RemindersList'],
    }),
    getPossibleRecipients: builder.query<AlerterRecipient[], number>({
      query: (id) => ({
        url: `alerter_recipient_managers/recipient_sets/${id}/possible_recipients`,
        method: 'PUT',
        transformResponse: (data: string) => {
          return (JSON.parse(data).results as RawRecipient[]).map((result) =>
            transformRecipient(result),
          );
        },
      }),
      providesTags: ['PossibleRecipients'],
    }),
    getAllPossibleRecipients: builder.query<AlerterRecipient[], number>({
      query: (profileId) => ({
        url: `alerter_recipient_managers/recipient_sets/all_possible_recipients?patient_profile_id=${profileId}`,
        method: 'GET',
        transformResponse: (data: string) => {
          return (JSON.parse(data).results as RawRecipient[]).map((result) =>
            transformRecipient(result),
          );
        },
      }),
      providesTags: ['PossibleRecipients'],
    }),
    linkAlerterRecipientManager: builder.mutation<
      AlerterLinkResponse,
      LinkRecipientManagerToSchedulerArgs & { alertName?: string }
    >({
      query: ({ recipientManagerId, alerterId, alerterSchedulerId }) => ({
        url: `/alerter_recipient_managers/${recipientManagerId}/link_with`,
        method: 'PUT',
        data: {
          alerter_id: alerterId,
          alerter_scheduler_id: alerterSchedulerId,
        },
      }),
      onQueryStarted: async (
        { recipientManagerId, isReminder, alerterId, alertName },
        { dispatch, getState, queryFulfilled },
      ) => {
        const activeRecipientSets = (getState() as RootState)[
          isReminder ? 'reminder' : 'alerter'
        ].activeRecipientSets;
        if (!activeRecipientSets) return;
        dispatch(
          (isReminder
            ? updateReminderActiveRecipientSets
            : updateActiveRecipientSets)([
            ...activeRecipientSets,
            recipientManagerId,
          ]),
        );

        const patchUpdate = dispatch(
          alertersApi.util.updateQueryData(
            'getAlerterSharedRecipients',
            {
              patientProfileId: (getState() as RootState).dashboard.profileId,
              isReminder,
            },
            (draft) => {
              if (!alerterId) return;

              const linkedIndex = draft.findIndex(
                ({ id }) => id === recipientManagerId,
              );
              if (linkedIndex !== -1) {
                draft[linkedIndex].alerters.push({
                  id: alerterId,
                  name: alertName,
                });
              }
            },
          ),
        );

        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            (isReminder
              ? updateReminderActiveRecipientSets
              : updateActiveRecipientSets)(activeRecipientSets),
          );
          patchUpdate.undo();
          toast.error('Failed to link recipient manager');
        }
      },
      invalidatesTags: ['AlertersList', 'RemindersList'],
    }),
    unlinkAlerterRecipientManager: builder.mutation<
      AlerterLinkResponse,
      LinkRecipientManagerToSchedulerArgs
    >({
      query: ({ recipientManagerId, alerterId, alerterSchedulerId }) => ({
        url: `/alerter_recipient_managers/${recipientManagerId}/unlink_from`,
        method: 'PUT',
        data: {
          alerter_id: alerterId,
          alerter_scheduler_id: alerterSchedulerId,
        },
      }),
      onQueryStarted: async (
        { recipientManagerId, isReminder, alerterId },
        { dispatch, getState, queryFulfilled },
      ) => {
        const activeRecipientSets = (getState() as RootState)[
          isReminder ? 'reminder' : 'alerter'
        ].activeRecipientSets;
        if (!activeRecipientSets) return;
        dispatch(
          (isReminder
            ? updateReminderActiveRecipientSets
            : updateActiveRecipientSets)(
            activeRecipientSets.filter((id) => id !== recipientManagerId),
          ),
        );
        const patchUpdate = dispatch(
          alertersApi.util.updateQueryData(
            'getAlerterSharedRecipients',
            {
              patientProfileId: (getState() as RootState).dashboard.profileId,
              isReminder,
            },
            (draft) => {
              const unlinkedIndex = draft.findIndex(
                ({ id }) => id === recipientManagerId,
              );
              if (unlinkedIndex !== -1) {
                draft[unlinkedIndex].alerters = draft[
                  unlinkedIndex
                ].alerters.filter(({ id }) => {
                  return id !== alerterId;
                });
              }
            },
          ),
        );
        try {
          await queryFulfilled;
        } catch (error) {
          dispatch(
            (isReminder
              ? updateReminderActiveRecipientSets
              : updateActiveRecipientSets)(activeRecipientSets),
          );
          patchUpdate.undo();
          toast.error('Failed to unlink recipient manager');
        }
      },
      invalidatesTags: ['AlertersList', 'RemindersList'],
    }),
    addRecipientSetToManager: builder.mutation<
      AlerterRecipientSetResponse,
      AlerterRecipientSetArgs
    >({
      query: ({ recipientManagerId, alerterRecipients, priority }) => ({
        url: `/alerter_recipient_managers/${recipientManagerId}/add_recipient_set`,
        method: 'PUT',
        data: {
          priority,
          alerter_recipients: alerterRecipients?.map(
            ({ patternType, recipientId, recipientType, id }) => ({
              pattern_type: patternType,
              recipient_id: recipientId,
              id,
              recipient_type: recipientType,
            }),
          ),
        },
      }),
    }),
    removeRecipientSetFromManager: builder.mutation<
      AlerterRecipientSetResponse,
      RemoveRecipientSetArgs
    >({
      query: ({ managerId, setId }) => ({
        url: `/alerter_recipient_managers/${managerId}/remove_recipient_set`,
        method: 'PUT',
        data: {
          recipient_set_id: setId,
        },
      }),
    }),
    addAlerterRecipientToSet: builder.mutation<
      AlerterRecipientSetResponse,
      {
        setId: number;
        recipientId: number;
        recipientType: PatternType | 'User' | 'PatientProfile';
      }
    >({
      query: ({ setId, recipientId, recipientType }) => {
        const { recipient_type, pattern_type } = {
          recipient_type:
            recipientType === 'PatientProfile' || recipientType === 'User'
              ? recipientType
              : undefined,
          pattern_type:
            recipientType === 'all_patients' || recipientType === 'all_users'
              ? recipientType
              : undefined,
        };

        return {
          url: `/alerter_recipient_managers/recipient_sets/${setId}/add_recipient`,
          method: 'PUT',
          data: {
            recipient_type,
            pattern_type,
            recipient_id: recipientId,
          },
        };
      },
    }),
    removeAlerterRecipientFromSet: builder.mutation<
      AlerterRecipientSetResponse,
      { setId: number; recipientId: number }
    >({
      query: ({ setId, recipientId }) => ({
        url: `/alerter_recipient_managers/recipient_sets/${setId}/remove_recipient`,
        method: 'PUT',
        data: {
          alerter_recipient_id: recipientId,
        },
      }),
    }),
    createAlerterChannelSet: builder.mutation<
      RawAlerterChannelSet,
      ChannelSet[]
    >({
      query: (channels) => {
        const finalChannels = channels.includes('critical')
          ? [
              ...channels.filter((c) => c !== 'critical' && c !== 'push'),
              'push_critical',
            ]
          : channels;

        return {
          url: '/alerter_channel_sets',
          method: 'POST',
          data: {
            channels: finalChannels,
          },
        };
      },
    }),
    getAlerterChannelSet: builder.query<
      { result: RawAlerterChannelSet },
      number
    >({
      query: (id) => ({
        url: `/alerter_channel_sets/${id}`,
        method: 'GET',
      }),
    }),
    updateAlerterChannelSet: builder.mutation<
      { result: RawAlerterChannelSet },
      { channels: ChannelSet[]; id: number }
    >({
      query: ({ channels, id }) => {
        const finalChannels = channels.includes('critical')
          ? [
              ...channels.filter((c) => c !== 'critical' && c !== 'push'),
              'push_critical',
            ]
          : channels;
        return {
          url: `/alerter_channel_sets/${id}`,
          method: 'PUT',
          data: {
            channels: finalChannels,
          },
        };
      },
    }),
    deleteAlerterChannelSet: builder.mutation<RawAlerterChannelSet, number>({
      query: (id) => ({
        url: `/alerter_channel_sets/${id}`,
        method: 'DELETE',
      }),
    }),
    linkChannelSetToAlerter: builder.mutation<
      AlerterLinkResponse,
      { channelSetId: number; alerterId: number }
    >({
      query: ({ channelSetId, alerterId }) => ({
        url: `/alerter_channel_sets/${channelSetId}/link_with`,
        method: 'PUT',
        data: { alerter_id: alerterId },
      }),
    }),
    unlinkChannelSetToAlerter: builder.mutation<
      AlerterLinkResponse,
      { channelSetId: number; alerterId: number }
    >({
      query: ({ channelSetId, alerterId }) => ({
        url: `/alerter_channel_sets/${channelSetId}/unlink_from`,
        method: 'PUT',
        data: { alerter_id: alerterId },
      }),
    }),
    createTrigger: builder.mutation<
      RawAlerterTriggerResponse,
      CreateAlerterTriggerParams
    >({
      query: ({ metricRules, allowMultipleAlerterAssociations }) => ({
        url: '/alerter_triggers',
        method: 'POST',
        data: {
          metric_rules: metricRules.map(({ metricType, ...rest }) => ({
            metric_type: metricType,
            ...rest,
          })),
          allow_multiple_alerter_associations: allowMultipleAlerterAssociations,
        },
      }),
    }),
    getAlerterTrigger: builder.query<RawAlerterTriggerResponse, number>({
      query: (id) => ({
        url: `/alerter_triggers/${id}`,
        method: 'GET',
      }),
    }),
    updateAlerterTrigger: builder.mutation<
      RawAlerterTriggerResponse,
      Partial<CreateAlerterTriggerParams> & { id: number }
    >({
      query: ({
        id,
        metricRules,
        allowMultipleAlerterAssociations,
        cooldownTime,
        cooldownType,
      }) => ({
        url: `/alerter_triggers/${id}`,
        method: 'PUT',
        data: {
          metric_rules: metricRules?.map(({ metricType, ...rest }) => ({
            metric_type: metricType,
            ...rest,
          })),
          cooldown_time: cooldownTime,
          cooldown_type: cooldownType,
          allow_multiple_alerter_associations: allowMultipleAlerterAssociations,
        },
      }),
    }),
    deleteAlerterTrigger: builder.mutation<RawAlerterTriggerResponse, number>({
      query: (id) => ({
        url: `/alerter_triggers/${id}`,
        method: 'DELETE',
      }),
    }),
    linkAlerterTriggerToAlerter: builder.mutation<
      AlerterLinkResponse,
      { triggerId: number; alerterId: number }
    >({
      query: ({ triggerId, alerterId }) => ({
        url: `/alerter_triggers/${triggerId}/link_with`,
        method: 'PUT',
        data: {
          alerter_id: alerterId,
        },
      }),
    }),
    unLinkAlerterTriggerToAlerter: builder.mutation<
      AlerterLinkResponse,
      { triggerId: number; alerterId: number }
    >({
      query: ({ triggerId, alerterId }) => ({
        url: `/alerter_triggers/${triggerId}/unlink_from`,
        method: 'PUT',
        data: {
          alerter_id: alerterId,
        },
      }),
    }),
    addRuleToAlerterTrigger: builder.mutation<
      RawAlerterTriggerResponse,
      {
        id: number;
        is_reminder?: boolean;
        metric_rule: Omit<RawMetricRule, 'id'>;
      }
    >({
      query: ({ id, metric_rule, is_reminder }) => ({
        url: `/alerter_triggers/${id}/add_rule`,
        method: 'PUT',
        data: { metric_rule, is_reminder },
      }),
      invalidatesTags: ['RemindersList', 'AlertersList'],
    }),
    updateRuleInAlerterTrigger: builder.mutation<
      RawAlerterRuleResponse,
      AlerterTriggerUpdateParams
    >({
      query: ({ metricRule, metricRuleId }) => ({
        url: `/alerter_triggers/rules/${metricRuleId}/update`,
        method: 'PUT',
        data: {
          metric_rule: transformMetricDataToRaw(metricRule as MetricRule),
        },
      }),
      invalidatesTags: ['RemindersList', 'AlertersList', 'AlerterData'],
    }),
    removeRuleFromAlerterTrigger: builder.mutation<
      RawAlerterRuleResponse,
      AlerterTriggerDeleteParams
    >({
      query: ({ metricRuleId, triggerId }) => ({
        url: `/alerter_triggers/${triggerId}/delete_rule`,
        method: 'DELETE',
        data: {
          metric_rule_id: metricRuleId,
        },
      }),
      invalidatesTags: ['RemindersList', 'AlertersList'],
    }),
    getAvailableTemplates: builder.query<GetAlerterTemplatesResponse, void>({
      query: () => ({
        url: '/alerters/available_templates',
        method: 'GET',
      }),
    }),
    createAlerterFromTemplate: builder.mutation<
      AlerterResponse,
      CreateAlerterParams & { template?: string }
    >({
      query: ({ template, ...data }) => ({
        url: '/alerters/create_from_template',
        method: 'POST',
        data: {
          ...transformCreateAlerterParams(data),
          alerter_template_identifier: template,
        },
      }),
      invalidatesTags: ['SharedRecipientSets', 'AlertersList'],
    }),
    getAlerterSharedRecipients: builder.query<
      CombinedRecipientManager[],
      GetAlertersParams & { lightweight?: boolean; isReminder?: boolean }
    >({
      query: ({
        alerterSourceId,
        deviceId,
        patientProfileId,
        userId,
        lightweight,
        isReminder,
      }) => ({
        url: '/alerter_recipient_managers',
        method: 'GET',
        params: {
          lightweight,
          is_reminder: isReminder,
          alerter_source_id: alerterSourceId,
          device_id: deviceId,
          patient_profile_id: patientProfileId,
          user_id: userId,
        },
      }),
      transformResponse: (data: SharedAlerterRecipientManagerResponse) => {
        return transformSharedRecipientManager(data);
      },
      providesTags: ['SharedRecipientSets'],
    }),
    duplicateAlerter: builder.mutation<AlerterResponse, { id: number }>({
      query: ({ id }) => ({
        url: `/alerters/${id}/duplicate`,
        method: 'POST',
      }),
    }),
    duplicateRecipientManager: builder.mutation<
      RawAlerterRecipientManagerResponse,
      { id: number; alerterId: number }
    >({
      query: ({ id, alerterId }) => ({
        url: `/alerter_recipient_managers/${id}/duplicate`,
        method: 'POST',
        data: {
          link_to_alerter_id: alerterId,
        },
      }),
      invalidatesTags: ['SharedRecipientSets'],
    }),
    getAlerterEventsByWidgets: builder.query<
      AlerterEventByWidgetResponse,
      GetAlerterEventsByWidgetParams
    >({
      query: ({ profileId, widgets }) => ({
        url: `/alerter_events/current_alerter_events_by_widget?${qs.stringify(
          {
            patient_profile_id: profileId,
            widgets,
          },
          {
            arrayFormat: 'brackets',
          },
        )}`,
        method: 'GET',
      }),
      transformResponse: (response: RawAlerterEventByWidgetResponse) => ({
        results: (
          Object.keys(response.results) as AlerterEventsWidgets[]
        ).reduce(
          (prev, current) => {
            return {
              ...prev,
              [current]: response.results[current].map((event) =>
                transformAlerterEvent(event),
              ),
            };
          },
          {} as AlerterEventByWidgetResponse['results'],
        ),
      }),
      providesTags: ['AlerterEventsByWidget'],
    }),
    searchAlerterEvents: builder.query<
      { events: AlerterEvent[]; count: number },
      SearchAlerterEventsParams
    >({
      query: ({
        profileId,
        widgets,
        alerterIds,
        limit,
        offset,
        onlyCurrent,
        onlyUnacknowledged,
        order,
        ruleTypes,
        beginsAt,
        endsAt,
      }) => ({
        url: `/alerter_events/search?${qs.stringify(
          {
            patient_profile_id: profileId,
            limit,
            offset,
            order,
            widgets,
            alerter_ids: alerterIds,
            rule_types: ruleTypes,
            only_unacknowledged: onlyUnacknowledged,
            only_current: onlyCurrent,
            begins_at: beginsAt,
            ends_at: endsAt,
          },
          {
            arrayFormat: 'brackets',
          },
        )}`,
        method: 'GET',
      }),
      transformResponse: (response: RawAlerterSearchResponse) => {
        return {
          events: response.results.map((item) => transformAlerterEvent(item)),
          count: response.meta.total_alerter_events,
        };
      },
    }),
    acknowledgeAlerterEvent: builder.mutation<AlerterEvent, { ids: number[] }>({
      query: ({ ids }) => ({
        url: '/alerter_events/acknowledge',
        method: 'PUT',
        data: {
          ids,
        },
      }),
      invalidatesTags: ['AlerterEventsByWidget'],
    }),
    previewMessage: builder.mutation<
      { result: string },
      { triggerId: number; rules: number[] }
    >({
      query: ({ triggerId, rules }) => ({
        url: `alerter_triggers/${triggerId}/preview_message`,
        method: 'PUT',
        data: {
          metric_rule_ids: rules,
        },
      }),
    }),
  }),
});

export const {
  useGetAlertersQuery,
  useGetAlerterInfoQuery,
  useDeleteAlerterMutation,
  useGetRecipientManagerQuery,
  useCreateAlerterMutation,
  useCreateAlerterSchedulerMutation,
  useCreateAlerterRecipientManagerMutation,
  useCreateTriggerMutation,
  useGetAlerterTriggerQuery,
  useUpdateAlerterMutation,
  useLinkAlerterTriggerToAlerterMutation,
  useUnLinkAlerterTriggerToAlerterMutation,
  useUnlinkAlerterRecipientManagerMutation,
  useUpdateAlerterTriggerMutation,
  useDeleteAlerterTriggerMutation,
  useAddRuleToAlerterTriggerMutation,
  useUpdateRuleInAlerterTriggerMutation,
  useRemoveRuleFromAlerterTriggerMutation,
  useUpdateAlerterChannelSetMutation,
  useGetAvailableTemplatesQuery,
  useCreateAlerterFromTemplateMutation,
  useGetPossibleRecipientsQuery,
  useGetAllPossibleRecipientsQuery,
  useUpdateAlerterRecipientManagerMutation,
  useUpdateAlerterSchedulerMutation,
  useAddRecipientSetToManagerMutation,
  useRemoveAlerterRecipientFromSetMutation,
  useAddAlerterRecipientToSetMutation,
  useRemoveRecipientSetFromManagerMutation,
  useGetAlerterSharedRecipientsQuery,
  useDuplicateAlerterMutation,
  useDuplicateRecipientManagerMutation,
  useDeleteRecipientManagerMutation,
  useGetAlerterChannelSetQuery,
  useGetAlerterSchedulerInfoQuery,
  useLazyGetAlerterSchedulerInfoQuery,
  useLinkAlerterRecipientManagerMutation,
  useGetAlerterEventsByWidgetsQuery,
  useSearchAlerterEventsQuery,
  useAcknowledgeAlerterEventMutation,
  usePreviewMessageMutation,
} = alertersApi;
