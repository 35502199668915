import { Navigate } from 'react-router-dom';
import Login from 'pages/Login/Login';
import Registration from 'pages/Registration/Registration';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import SetNewPassword from 'pages/SetNewPassword/SetNewPassword';
import ProfileLookup from 'pages/admin/ProfileLookup/ProfileLookup';
import { MfaSetup } from 'pages/MfaSetup';
import { MfaLogin } from 'pages/MfaLogin';
import { Mfa } from 'pages/Mfa';
import ProfileLookupResults from 'pages/admin/ProfileLookupResults/ProfileLookupResults';
import { ConfirmDeregistration } from 'pages/ConfirmDeregistration';
import { PasswordReseted } from 'pages/PasswordReseted';
import { PatientsOverview } from 'pages/PatientsOverview';
import { Dashboard } from 'pages/Dashboard';
import { DashboardData } from 'pages/DashboardData';
import { CaregiverSettings } from 'pages/CaregiverSettings';
import { IconifyJSON } from '@iconify/react';
import * as icons from 'assets/iconify';
import { PatientAccount } from 'pages/PatientAccount';
import { Alerters } from 'pages/Alerters';
import { AlerterDetails } from 'pages/AlerterDetails';
import { Reminders } from 'pages/Reminders';
import { ReminderDetails } from 'pages/ReminderDetails';
import { SendWelcomeEmail } from 'pages/admin/SendWelcomeEmail';
import { PatientCreationWizard } from 'pages/admin/PatientCreationWizard';
import { OrganizationSettings } from 'pages/OrganizationSettings';
import { ManageDevices } from 'pages/admin/ManageDevices';
import { DeviceData } from 'pages/admin/DeviceData';
import { ProfileConfigurator } from 'pages/admin/ProfileConfigurator';
import { ProfileReports } from 'pages/ProfileReports';
import { GroupReports } from 'pages/GroupReports';
import { Organizations } from 'pages/admin/Organizations';
import { OrganizationDetails } from 'pages/admin/OrganizationDetails';
import { OrganizationReports } from 'pages/OrganizationReports';
import { OrganizationMemberships } from 'pages/OrganizationMemberships';

type RouteType = 'public' | 'private' | 'admin' | 'mfa';
type Href = string | ((...param: (string | number)[]) => string);

export interface Route {
  href: Href;
  name?: string;
  routerPath?: string;
  withoutLayout?: boolean;
  Outlet: JSX.Element;
}

type Routes = Record<RouteType, Record<string, Route>>;

interface SidebarNavItem {
  name: string;
  icon: IconifyJSON;
  href: string;
  items?: Omit<SidebarNavItem, 'items' | 'icon'>[];
  adminOnly?: boolean;
  getIsActive?: (path: string) => boolean;
}

interface SidebarNavSection {
  name: string;
  adminOnly?: boolean;
  hidden?: boolean;
  items: SidebarNavItem[];
}

const createRoutes = <M extends Routes>(routes: M) => routes;

export const routes = createRoutes({
  private: {
    dashboard: {
      href: (id) => `/profiles/${id}/dashboard`,
      routerPath: '/profiles/:profileId/dashboard',
      Outlet: <Dashboard />,
    },
    dashboardData: {
      name: 'Data History',
      href: (id, visible?) =>
        `/profiles/${id}/data${visible ? `?visible=${visible}` : ''}`,
      routerPath: '/profiles/:profileId/data',
      Outlet: <DashboardData />,
    },
    patientAccount: {
      name: 'Profile Settings',
      href: (id) => `/profiles/${id}/settings`,
      routerPath: '/profiles/:profileId/settings',
      Outlet: <PatientAccount />,
    },
    profileReports: {
      name: 'Profile Reports',
      href: (id) => `/profiles/${id}/reports`,
      routerPath: '/profiles/:profileId/reports',
      Outlet: <ProfileReports />,
    },
    patients: {
      name: 'Profiles',
      href: '/patients',
      Outlet: <Navigate to={'/profiles/overview'} replace />,
    },
    groupReports: {
      name: 'Profiles Reports',
      href: '/reports',
      Outlet: <GroupReports />,
    },
    profiles: {
      name: 'Profiles',
      href: '/profiles',
      Outlet: <Navigate to={'/profiles/overview'} replace />,
    },
    patientsOverview: {
      name: 'Profiles Overview',
      href: '/profiles/overview',
      Outlet: <PatientsOverview tabState="search" />,
    },
    patientsOverviewAppleWatch: {
      name: 'Profiles Overview',
      href: '/profiles/overview/apple-watch',
      Outlet: <PatientsOverview tabState="apple-watch" />,
    },
    confirmDeviceUnregister: {
      name: 'Confirm Device Deregistration',
      href: '/confirm_device_deregistration',
      Outlet: <ConfirmDeregistration />,
      withoutLayout: true,
    },
    settings: {
      name: 'Settings',
      href: '/settings',
      Outlet: <CaregiverSettings />,
    },
    alerter: {
      name: 'Alerts',
      href: (profileId, widget?) =>
        `/profiles/${profileId}/alerts${widget ? `?historyFor=${widget}` : ''}`,
      routerPath: '/profiles/:profileId/alerts',
      Outlet: <Alerters />,
    },
    alerterDetails: {
      name: 'Alerts',
      href: (profileId, alertId) => `/profiles/${profileId}/alerts/${alertId}`,
      routerPath: '/profiles/:profileId/alerts/:alertId',
      Outlet: <AlerterDetails />,
    },
    reminders: {
      name: 'Reminders',
      href: (profileId) => `/profiles/${profileId}/reminders`,
      routerPath: '/profiles/:profileId/reminders',
      Outlet: <Reminders />,
    },
    reminderDetails: {
      name: 'Reminders',
      href: (profileId, reminderId) =>
        `/profiles/${profileId}/reminders/${reminderId}`,
      routerPath: '/profiles/:profileId/reminders/:reminderId',
      Outlet: <ReminderDetails />,
    },
    organizationSettings: {
      name: 'Organization Settings',
      href: '/organization/settings',
      Outlet: <OrganizationSettings />,
    },
    organizationReports: {
      name: 'Organization Reports',
      href: '/organization/reports',
      Outlet: <OrganizationReports />,
    },
    organizationMemberships: {
      name: 'Organization Memberships',
      href: '/organization/memberships',
      Outlet: <OrganizationMemberships />,
    },
    profileConfigurator: {
      name: 'Profile Configurator',
      href: '/organization/profile-configurator',
      Outlet: <ProfileConfigurator />,
    },
  },
  public: {
    login: {
      href: '/login',
      Outlet: <Login />,
    },
    register: {
      href: '/register',
      Outlet: <Registration />,
    },
    forgot: {
      href: '/forgot',
      Outlet: <ForgotPassword />,
    },
    setNewPassword: {
      href: '/user/password_reset',
      Outlet: <SetNewPassword />,
    },
    passwordResetSuccess: {
      href: '/user/password_reset/after',
      Outlet: <PasswordReseted />,
    },
  },
  admin: {
    admin: {
      name: 'Admin',
      href: '/admin',
      Outlet: <Navigate to="/admin/lookup" />,
    },
    lookup: {
      name: 'Profile Lookup',
      href: '/admin/lookup',
      Outlet: <ProfileLookup />,
    },
    lookupResults: {
      name: 'Profile Lookup Results',
      href: (id) => `/admin/lookup/${id}`,
      routerPath: '/admin/lookup/:profileId',
      Outlet: <ProfileLookupResults />,
    },
    sendWelcomeEmail: {
      name: 'Send Welcome Email',
      href: '/admin/send-welcome-email',
      Outlet: <SendWelcomeEmail />,
    },
    profileCreator: {
      name: 'Profile Creator',
      href: '/admin/profile-creator',
      Outlet: <PatientCreationWizard />,
    },
    profileConfigurator: {
      name: 'Profile Configurator',
      href: '/admin/profile-configurator',
      Outlet: <ProfileConfigurator />,
    },
    manageDevices: {
      name: 'Manage Devices',
      href: '/admin/manage-devices',
      Outlet: <ManageDevices />,
    },
    deviceData: {
      href: (deviceId) => `/admin/manage-devices/${deviceId}`,
      routerPath: '/admin/manage-devices/:deviceId',
      Outlet: <DeviceData />,
    },
    organizations: {
      name: 'Organizations',
      href: '/admin/organizations',
      Outlet: <Organizations />,
    },
    organizationDetails: {
      href: (id) => `/admin/organizations/${id}`,
      routerPath: '/admin/organizations/:id',
      Outlet: <OrganizationDetails />,
    },
  },
  mfa: {
    setup: {
      href: '/mfa/setup',
      Outlet: <MfaSetup />,
    },
    selectCredential: {
      href: '/mfa/select',
      Outlet: <MfaLogin />,
    },
    confirmIdentity: {
      href: '/mfa/confirm',
      Outlet: <Mfa />,
    },
  },
});

export const getSidebarNav = (
  profileId?: number,
  patientName?: string,
  hasSinglePatient?: boolean,
  belongsToOrganization?: boolean,
  isOrganizationAdmin?: boolean,
): SidebarNavSection[] => [
  // {
  //   name: 'My Workspace',
  //   items: [
  //     {
  //       name: 'Dashboard',
  //       icon: 'material-symbols:dashboard',
  //       href: routes.private.dashboard.href(0),
  //     },
  //     // {
  //     //   name: 'My Tasks',
  //     //   icon: 'material-symbols:task',
  //     //   href: '#',
  //     // },
  //     // {
  //     //   name: 'My Calendar',
  //     //   icon: 'material-symbols:event-note',
  //     //   href: '#',
  //     // },
  //     // {
  //     //   name: 'My Logs',
  //     //   icon: 'material-symbols:list-alt',
  //     //   href: '#',
  //     // },
  //     // {
  //     //   name: 'My Notes',
  //     //   icon: 'material-symbols:note-alt',
  //     //   href: '#',
  //     // },
  //   ],
  // },
  {
    name: '',
    items: [
      {
        icon: icons.populationOverview,
        name: 'Profiles Overview',
        href: routes.private.patientsOverview.href,
        getIsActive: (path) => path === routes.private.patientsOverview.href,
      },
      {
        icon: icons.report,
        name: 'Profiles Reports',
        href: routes.private.groupReports.href,
        getIsActive: (path) => path === routes.private.groupReports.href
      },
      // {
      //   name: 'Caregivers',
      //   icon: 'material-symbols:groups',
      //   href: '#',
      // },
      // {
      //   name: 'Surveys',
      //   icon: 'material-symbols:fact-check',
      //   href: '#',
      // },
      // {
      //   name: 'Reminders',
      //   icon: 'material-symbols:edit-notifications',
      //   href: '#',
      // },
      // {
      //   name: 'Goals',
      //   icon: 'material-symbols:flag',
      //   href: '#',
      // },
      // {
      //   name: 'Safe Zones',
      //   icon: 'material-symbols:verified-user',
      //   href: '#',
      // },
      // {
      //   name: 'Sites',
      //   icon: 'material-symbols:home',
      //   href: '#',
      // },
    ],
  },
  {
    name: patientName ?? 'Profile Name',
    items: [generatePatientsMenu(profileId, hasSinglePatient)],
  },
  {
    name: 'Organization',
    hidden: !belongsToOrganization,
    items: [
      {
        name: 'Organization Settings',
        icon: icons.settings,
        href: routes.private.organizationSettings.href,
        getIsActive: (path) =>
          path === routes.private.organizationSettings.href,
      },
      ...(isOrganizationAdmin
        ? [
            {
              name: 'Organization Members',
              icon: icons.borderedList,
              href: routes.private.organizationMemberships.href,
              getIsActive: (path) =>
                path === routes.private.organizationMemberships.href,
            },
            {
              name: 'Organization Reports',
              icon: icons.report,
              href: routes.private.organizationReports.href,
              getIsActive: (path: string) =>
                path === routes.private.organizationReports.href,
            },
            {
              name: 'Profile Configurator',
              icon: icons.personConfigure,
              href: routes.private.profileConfigurator.href,
              getIsActive: (path: string) =>
                path === routes.private.profileConfigurator.href,
            },
          ]
        : []),
    ],
  },
  {
    name: 'Administration',
    adminOnly: true,
    items: [
      {
        name: 'Profile Lookup',
        icon: icons.personSearch,
        href: routes.admin.lookup.href,
        getIsActive: (path) => path === routes.admin.lookup.href,
      },
      {
        name: 'Organizations',
        icon: icons.group,
        href: routes.admin.organizations.href,
        getIsActive: (path) => path === routes.admin.organizations.href,
      },
      {
        name: 'Profile Creator',
        icon: icons.personAdd,
        href: routes.admin.profileCreator.href,
        getIsActive: (path) => path === routes.admin.profileCreator.href,
      },
      {
        name: 'Profile Configurator',
        icon: icons.personConfigure,
        href: routes.admin.profileConfigurator.href,
        getIsActive: (path) => path === routes.admin.profileConfigurator.href,
      },
      {
        name: 'Send Welcome Email',
        icon: icons.mail,
        href: routes.admin.sendWelcomeEmail.href,
        getIsActive: (path) => path === routes.admin.sendWelcomeEmail.href,
      },
      {
        name: 'Manage Devices',
        icon: icons.devices,
        href: routes.admin.manageDevices.href,
        getIsActive: (path) => path === routes.admin.manageDevices.href,
      },
    ],
  },
];

export const publicUrls = {
  privacyPolicy: 'https://www.boundarycare.com/privacy-policy',
};

const generatePatientsMenu = (
  profileId?: number,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  _hasSinglePatient?: boolean,
): SidebarNavItem => {
  // if (!hasSinglePatient) {
  return {
    name: 'Profile Management',
    icon: icons.supervisor,
    href: '#',
    items: getDashboardNavSubitems(profileId),
  };
  // }

  // return {
  //   name: 'Patient',
  //   icon: icons.populationOverview,
  //   href: '#',
  //   items: getDashboardNavSubitems(profileId),
  // };
};

const getDashboardNavSubitems = (
  profileId?: number,
): Omit<SidebarNavItem, 'items' | 'icon'>[] => {
  if (!profileId) {
    return [];
  }

  return [
    {
      name: 'Dashboard',
      href: routes.private.dashboard.href(profileId),
      getIsActive: (path) => path.split('/')[3] === 'dashboard',
    },
    {
      name: 'Alerts',
      href: routes.private.alerter.href(profileId),
      getIsActive: (path) => path.split('/')[3] === 'alerts',
    },
    {
      name: 'Reminders',
      href: routes.private.reminders.href(profileId),
      getIsActive: (path) => path.split('/')[3] === 'reminders',
    },
    {
      name: 'Reports',
      href: routes.private.profileReports.href(profileId),
      getIsActive: (path) => path.split('/')[3] === 'reports'
    },
    {
      name: 'Data History',
      href: routes.private.dashboardData.href(profileId, ''),
      getIsActive: (path) => path.split('/')[3] === 'data',
    },
    {
      name: 'Profile Settings',
      href: routes.private.patientAccount.href(profileId),
      getIsActive: (path) => path.split('/')[3] === 'settings',
    },
  ];
};
