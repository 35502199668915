import { IconifyJSON } from '@iconify/react';

const defaultSizes = { width: 24, height: 24 };

export const report: IconifyJSON = {
  prefix: 'report',
  icons: {
    normal: {
      ...defaultSizes,
      body: '<path d="M4.3 20.5C3.8 20.5 3.375 20.325 3.025 19.975C2.675 19.625 2.5 19.2 2.5 18.7V5.3C2.5 4.8 2.675 4.375 3.025 4.025C3.375 3.675 3.8 3.5 4.3 3.5H19.7C20.2 3.5 20.625 3.675 20.975 4.025C21.325 4.375 21.5 4.8 21.5 5.3V18.7C21.5 19.2 21.325 19.625 20.975 19.975C20.625 20.325 20.2 20.5 19.7 20.5H4.3ZM4.3 19H19.7C19.7667 19 19.8333 18.9667 19.9 18.9C19.9667 18.8333 20 18.7667 20 18.7V5.3C20 5.23333 19.9667 5.16667 19.9 5.1C19.8333 5.03333 19.7667 5 19.7 5H4.3C4.23333 5 4.16667 5.03333 4.1 5.1C4.03333 5.16667 4 5.23333 4 5.3V18.7C4 18.7667 4.03333 18.8333 4.1 18.9C4.16667 18.9667 4.23333 19 4.3 19ZM5.25 16.75H9.75V15.25H5.25V16.75ZM14.55 14.65L19.15 10.05L18.075 8.975L14.55 12.525L13.125 11.1L12.075 12.175L14.55 14.65ZM5.25 12.75H9.75V11.25H5.25V12.75ZM5.25 8.75H9.75V7.25H5.25V8.75Z" fill="currentColor"/>',
    },
    filled: {
      ...defaultSizes,
      body: '<path d="M4.3 20.5C3.8 20.5 3.375 20.325 3.025 19.975C2.675 19.625 2.5 19.2 2.5 18.7V5.3C2.5 4.8 2.675 4.375 3.025 4.025C3.375 3.675 3.8 3.5 4.3 3.5H19.7C20.2 3.5 20.625 3.675 20.975 4.025C21.325 4.375 21.5 4.8 21.5 5.3V18.7C21.5 19.2 21.325 19.625 20.975 19.975C20.625 20.325 20.2 20.5 19.7 20.5H4.3ZM5.25 16.75H9.75V15.25H5.25V16.75ZM14.55 14.65L19.15 10.05L18.075 8.975L14.55 12.525L13.125 11.1L12.075 12.175L14.55 14.65ZM5.25 12.75H9.75V11.25H5.25V12.75ZM5.25 8.75H9.75V7.25H5.25V8.75Z" fill="currentColor"/>',
    },
  },
};
