import { DateRangeInput } from 'components/DateRangeInput';
import { RangeInputPreset } from 'components/DateRangeInput/components/CalendarControls';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import { setDateRange } from 'services/alert-history-filters';
import { DateRange } from 'types/date-time';

export const reportsRangePresets: RangeInputPreset[] = [
  {
    id: 'LAST_3_DAYS',
    label: 'Last 3 days',
    value: [dayjs().subtract(3, 'days'), dayjs().subtract(1, 'days')],
  },
  {
    id: 'LAST_7_DAYS',
    label: 'Last 7 days',
    value: [dayjs().subtract(7, 'days'), dayjs().subtract(1, 'days')],
  },
  {
    id: 'LAST_30_DAYS',
    label: 'Last 30 days',
    value: [dayjs().subtract(30, 'days'), dayjs().subtract(1, 'days')],
  },
];

type Props = {
  defaultRange?: DateRange;
  onChange: (newValue: DateRange) => void;
};

export const DateRangePicker = ({ onChange, defaultRange }: Props) => {
  const handleDateRangeChange = (newValue: DateRange) => {
    onChange(newValue);
  };

  useEffect(() => {
    setDateRange([dayjs().subtract(7, 'days'), dayjs().subtract(1, 'days')]);
  }, []);

  return (
    <DateRangeInput
      size="small"
      labelPlacement="top"
      disableFuture
      maxDate={dayjs().subtract(1, 'days')}
      presets={reportsRangePresets}
      onChange={handleDateRangeChange}
      defaultRange={defaultRange ?? [dayjs().subtract(7, 'days'), dayjs()]}
    />
  );
};
