import dayjs from 'dayjs';
import { useState } from 'react';
import { DateRange } from 'types/date-time';
import { useGroupReports } from './hooks/use-group-reports';
import { Reports } from 'components/_extra/Reports';

export const GroupReports = () => {
  const [dateRange, setDateRange] = useState<DateRange>([
    dayjs().subtract(7, 'days'),
    dayjs().subtract(1, 'day'),
  ]);

  const { isLoading, widgets } = useGroupReports(dateRange);

  return (
    <Reports
      widgets={widgets ?? []}
      isLoading={isLoading}
      dateRange={dateRange}
      onDateRangeChange={setDateRange}
    />
  );
};
