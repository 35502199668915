import { Box, styled } from '@mui/material';
import { PropsWithChildren } from 'react';
import TopBar from './components/TopBar';
import { useNetwork } from 'hooks/use-network';
import NoNetwork from 'layouts/NoNetwork';
import Drawer from './components/Drawer';

export const sidebarWidth = 280;

const Main = styled('div')(({ theme }) => ({
  overflowY: 'auto',
  flexGrow: 1,
  width: '100%',
  marginLeft: `-${sidebarWidth}px`,
  transition: theme.transitions.create(['margin', 'width'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  '&.shrinked': {
    width: `calc(100% - ${sidebarWidth}px)`,
    marginLeft: 0,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  [theme.breakpoints.down('newXl')]: {
    width: '100%',
    marginLeft: 0,
    transition: 'none',
  },
}));

const Layout = ({ children }: PropsWithChildren) => {
  const { isOnline } = useNetwork();

  if (!isOnline) {
    return <NoNetwork />;
  }

  return (
    <Box
      width="100vw"
      height="100dvh"
      bgcolor={({ palette }) => palette.gray.light}
      display="flex"
    >
      <Drawer />
      <Main id="main-content">
        <TopBar />
        <Box
          sx={{
            p: { xxs: 2, newMd: 3 },
            px: { xxs: 1, xs: 1.5, sm: 2, newMd: 3 },
            width: '100%',
            maxWidth: ({ spacing }) => spacing(210),
            mx: 'auto',
          }}
        >
          <>{children}</>
        </Box>
      </Main>
    </Box>
  );
};

export default Layout;
