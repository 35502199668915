import {
  alpha,
  Box,
  MenuItem,
  MenuList,
  Select,
  Typography,
} from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { MultipleClickableListBodyItem } from 'utils/get-reports-widgets';

type Props = MultipleClickableListBodyItem;

export const MultipleClickableList = ({ title, groups }: Props) => {
  const [selectedGroupIndex, setSelectedGroupIndex] = useState(0);

  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}>
        <Typography
          sx={{ color: alpha('#000', 0.6), fontSize: 14, lineHeight: '20px' }}
        >
          {title}
        </Typography>
      </Box>
      <Select
        value={selectedGroupIndex}
        onChange={(e) => setSelectedGroupIndex(Number(e.target.value))}
      >
        {groups.map((group, index) => (
          <MenuItem key={group.name + index} value={index}>
            {group.name}
          </MenuItem>
        ))}
      </Select>
      <MenuList
        disablePadding
        sx={{ display: 'flex', flexDirection: 'column', gap: 0.5 }}
      >
        {groups[selectedGroupIndex].items.map((item, index) => (
          <MenuItem
            sx={({ spacing, palette }) => ({
              minHeight: 'auto',
              padding: spacing(0.5, 1),
              borderRadius: spacing(1),
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              gap: spacing(1),
              ':hover': {
                backgroundColor: alpha(palette.blue.dark, 0.04),
              },
            })}
            onClick={() => navigate(item.link)}
            key={item.name + index}
          >
            <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
              <Box
                sx={({ spacing }) => ({
                  height: spacing(1),
                  width: spacing(1),
                  borderRadius: 8,
                  bgcolor: item.color,
                })}
              />
              <Typography
                sx={{
                  fontSize: 14,
                  lineHeight: '20px',
                  color: alpha('#000', 0.6),
                }}
              >
                {item.name}
              </Typography>
            </Box>
            <Typography
              sx={{
                lineHeight: '20px',
                fontSize: 14,
                fontWeight: 500,
              }}
            >
              {item.value}
            </Typography>
          </MenuItem>
        ))}
      </MenuList>
    </Box>
  );
};
