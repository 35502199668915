import { OrganizationSettingsForm } from 'components/_extra/OrganizationSettingsForm';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGetOrganizationByIdAdminQuery } from 'store/api/admin.api';
import {
  useGetSpecialOrganizationChannelsQuery,
  useUpdateOrganizationAddressMutation,
  useUpdateOrganizationsSettingsMutation,
} from 'store/api/organizations.api';
import { Organization } from 'types/organizations';

const Settings = () => {
  const { id } = useParams<{ id: string }>();
  const { data, isLoading } = useGetOrganizationByIdAdminQuery({
    id: Number(id),
  });

  const [
    updateSettings,
    { isLoading: isUpdating, isSuccess, data: updatedSettings },
  ] = useUpdateOrganizationsSettingsMutation();
  const [
    updateAddress,
    {
      isLoading: isUpdatingAddress,
      isSuccess: isAddressUpdated,
      data: updatedData,
    },
  ] = useUpdateOrganizationAddressMutation();

  useGetSpecialOrganizationChannelsQuery({ organizationId: Number(id) });

  const methods = useForm<Organization>({
    defaultValues: data,
  });

  const onSubmit = (data: Organization) => {
    if (
      data.address &&
      Object.values(data.address).filter(
        (value) => value !== null || value?.length !== 0,
      ).length > 0
    ) {
      updateAddress({ ...data.address, id: Number(id) });
    }
    updateSettings({
      id: Number(id),
      requiresMfa: data.requiresMfa,
      nossActive: data.specialAlerterChannels.nossXml.active,
      name: data.name,
    });
  };

  const resetOrganizationForm = () => {
    methods.reset();
  };

  useEffect(() => {
    if (data) {
      methods.reset(data);
    }
  }, [data, methods]);

  useEffect(() => {
    if (isSuccess) {
      methods.reset(updatedSettings);
      return;
    }

    if (isAddressUpdated) {
      methods.reset({
        ...methods.getValues(),
        address: updatedData.address,
      });
    }
  }, [isAddressUpdated, isSuccess]);

  return (
    <FormProvider {...methods}>
      <OrganizationSettingsForm
        handleSubmit={methods.handleSubmit(onSubmit)}
        isLoading={isLoading || isUpdating || isUpdatingAddress}
        resetOrganizationForm={resetOrganizationForm}
        shouldDisableControls={isLoading || !methods.formState.isDirty}
        isAdmin
      />
    </FormProvider>
  );
};

export default Settings;
